import React, { useState, useEffect } from "react";
import { Button, Slider } from "antd";
import "./FilterSlider.css";

const FilterSlider = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  min,
  max,
  prefix,
  suffix,
  formatNumber,
}) => {
  const [sliderValue, setSliderValue] = useState([min || 0, max || 100]);

  useEffect(() => {
    if (selectedKeys && selectedKeys?.length > 0) {
      setSliderValue(selectedKeys[0]);
    } else {
      setSliderValue([min, max]);
    }
  }, [selectedKeys, min, max]);

  const handleChange = (value) => {
    setSliderValue(value);
  };

  const handleConfirm = () => {
    setSelectedKeys([sliderValue]);
    confirm();
  };

  const handleReset = () => {
    setSliderValue([min, max]);
    clearFilters();
  };

  return (
    <div style={{ padding: 8, width: "200px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}>
        <span>{prefix}{formatNumber !== null ? formatNumber(min) : min}{suffix}</span>
        <span>{prefix}{formatNumber !== null ? formatNumber(max) : max}{suffix}</span>
      </div>
      <div style={{ padding: "0 12px" }}>
        <Slider
          range
          value={sliderValue}
          onChange={handleChange}
          min={min}
          max={max}
          className="custom-slider"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
        <Button
          type="primary"
          onClick={handleConfirm}
          size="small"
          style={{ marginRight: 8 }}
        >
          OK
        </Button>
        <Button onClick={handleReset} size="small">
          Reset
        </Button>
      </div>
    </div>
  );
};

export default FilterSlider;