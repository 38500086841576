import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ErrorBoundary } from "../shared";
import { Image, Row, Col, Skeleton } from 'antd';
import CustomCard from '../../blocks/Card/Card';
import { useGroupedImages } from "./useGroupedImages";
import { isMobileOnly } from "react-device-detect";

function calculateAverages(data) {
    if (!data || data?.length === 0) {
        return {
            averageConditionScore: 0,
            averageQualityScore: 0
        };
    }

    let totalConditionScore = 0;
    let totalQualityScore = 0;

    data.forEach(item => {
        totalConditionScore += item.condition_score;
        totalQualityScore += item.quality_score;
    });

    const averageConditionScore = totalConditionScore / data.length;
    const averageQualityScore = totalQualityScore / data.length;

    return {
        averageConditionScore,
        averageQualityScore
    };
}

const GroupedImages = () => {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(213);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }

    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useGroupedImages(filters, { enabled: params.reportName !== null });

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 34;
                setElementHeight(newHeight);
                window.parent.postMessage({ groupedImagesHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);


    if (isLoading) {
        window.parent.postMessage("Data Loading", "*");
        return (
            <CustomCard title="Grouped Images" className="title-2">
                <Skeleton />
            </CustomCard>
        )
    }

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }
    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    if ((data && data !== null) && data?.has_avv === false) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    if (data && data?.has_avv === false) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }
    return (
        <ErrorBoundary>
            <CustomCard
                title={
                    (
                        <>
                            <span className="mobile-title-font">Grouped Images</span>
                        </>
                    )
                }
                ref={elementRef}
                className="sidebar-small-card title-2"
                style={{ marginRight: isMobileOnly ? "3%" : "0" }}
            >
                <Row gutter={[16, 16]}>
                    {Object.keys(data?.grouped_images).map((group, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={12}
                            md={8}
                            lg={6}
                        >
                            <div style={{
                                borderRadius: '20px',
                                overflow: 'hidden',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                height: "300px",
                                overflowY: "auto",
                                backgroundColor: '#fafafa'
                            }}>
                                <div style={{
                                    backgroundColor: '#547c77',
                                    color: 'white',
                                    padding: '10px',
                                    fontSize: '18px',
                                    textAlign: 'center'
                                }}>
                                    {`${group} (C${calculateAverages(data?.grouped_images[group])?.averageConditionScore.toFixed(2)}, Q${calculateAverages(data?.grouped_images[group])?.averageQualityScore.toFixed(2)})`}
                                </div>
                                <Image.PreviewGroup>
                                    <div style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                        padding: '8px',
                                    }}>
                                        {data?.grouped_images[group].map((img, imgIndex) => (
                                            <Image key={imgIndex} width={100} src={img?.url} style={{
                                                objectFit: 'cover',
                                                width: '100px',
                                                height: '100px',
                                                borderRadius: '10px'
                                            }} />
                                        ))}
                                    </div>
                                </Image.PreviewGroup>
                            </div>
                        </Col>
                    ))}
                </Row>
            </CustomCard>
        </ErrorBoundary>
    );
};

export default GroupedImages;