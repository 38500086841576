import axiosInstance from "../../../config/axios/axios.config";

export async function fetchMyUsageData() {
    try {
        const response = await axiosInstance.get("reports/my-usage/")
      return response.data;
    } catch (error) {
      throw new Error(`Failed fetch data. fetchMyUsageData with error: ${error}`);
    }
  }

