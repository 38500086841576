import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Col, Row, Tabs, Skeleton } from "antd";
import CustomCard from "../../blocks/Card/Card";
import { dynamicMapInfo } from "../../constants/defaultKeys";
import { Map } from "../shared";
import { useDynamicZipcodeMap } from "./useDynamicZipcodeMap";

const { TabPane } = Tabs;
const googleMapsSpec = { center: { lat: 41.2, lng: -73.13 }, zoom: 15 };

const DynamicZipcodeMap = () => {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(0);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useDynamicZipcodeMap(filters, { enabled: params.reportName !== null });

    const [tabChange, setTabChange] = useState(false);
    const onTabChange = (val) => {
        setTabChange(val === "forecast" ? true : false);
    };

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 190;
                setElementHeight(newHeight);
                window.parent.postMessage({ dynamicZipMapHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef, tabChange]);


    if (isLoading) {
        return (
            <CustomCard title="Dynamic Map" className="title-2">
                <Skeleton />
            </CustomCard>
        )
    }

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    let showText = () => {
        if (searchParams?.get("text")) {
            if (["true", "1", "yes", "y"].includes(searchParams?.get("text"))) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    return (
        <CustomCard
            title={
                <>
                    <span>Dynamic Map</span>
                    {showText() && dynamicMapInfo()}
                </>
            }
            className="title-2 mb-2"
        >
            <Row ref={elementRef}>
                <Col span={24}>
                    <Tabs
                        defaultActiveKey="last_year"
                        onChange={onTabChange}
                        style={{ minHeight: "400px", width: "100%", display: "flex" }}
                    >
                        <TabPane
                            tab="Last Year Change"
                            key="last_year"
                            style={{ height: "350px" }}
                        >
                            {!tabChange && <Map
                                defaultKeys={googleMapsSpec}
                                zipCode={params?.code}
                                tabChange={tabChange}
                                address={params?.code}
                                mapIds={data?.map_ids}
                                type={"zipcode"}
                            />
                            }
                        </TabPane>
                        <TabPane
                            tab="1 Year Forecast"
                            key="forecast"
                            style={{ height: "350px" }}
                        >
                            {tabChange && <Map
                                defaultKeys={googleMapsSpec}
                                zipCode={params?.code}
                                tabChange={tabChange}
                                address={params?.code}
                                mapIds={data?.map_ids}
                                type={"zipcode"}
                            />
                            }
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </CustomCard>
    );
};

export default DynamicZipcodeMap;
