import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Skeleton, Button, message, Modal, Carousel } from "antd";
import CountUp from "react-countup";
import CustomCard from "../../blocks/Card/Card";
import "../../components/Report/Report.css";
import "../../components/Report/Property/Property.css";
import { ErrorBoundary } from "../shared";
import { useValproCard } from "./useValproCard"
import { isMobileOnly } from "react-device-detect";
import axiosInstance from "../../config/axios/axios.config";
import "./ValProCard.css";

function ValProCard() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    let searchParams = new URLSearchParams(location.search);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [elementHeight, setElementHeight] = useState(0);
    const elementRef = useRef(null);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };


    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useValproCard(filters, { enabled: params.reportName !== null });

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 52;
                    setElementHeight(newHeight);
                    window.parent.postMessage({ valproCardHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);
    
    let buttonText = "Improve accuracy with AVV"; // Default text
    let buttonDisabled = false;

    if (data?.avv?.status === "pending") {
        buttonText = "AVV Pending";
        buttonDisabled = true;
    } else if (data?.avv?.status?.toLowerCase() === "completed") {
        buttonText = "AVV Completed";
    }

    if (isLoading) {
        return (
            <CustomCard title="ValPro" className="title-2">
                <Skeleton />
            </CustomCard>
        )
    }

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    const handleAvvClick = async () => {
        try {
            const response = await axiosInstance.post('/widgets/user/report_limit/', {
                address: params?.reportName
            });
            let canAccess = response?.data?.can_access;
            if (canAccess) {
                navigate({ pathname: `/avv/${encodeURIComponent(params?.reportName)}/${encodeURIComponent(params?.code)}` });
            } else {
                message.error('You have reached your limit for AVV reports.');
            }
        } catch (error) {
            message.error('Something went wrong. Please try again later.');
        }
    };

    return (
        <ErrorBoundary>
            <CustomCard ref={elementRef} title={data?.avv && data?.avv?.status?.toLowerCase() === "completed" ?
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span className="mobile-title-font">ValPro AVV</span>
                    <Button style={{ borderRadius: "4px" }} type="primary" size="small" onClick={toggleModal}>View Images</Button>
                </div> : <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span className="mobile-title-font">ValPro AVM</span>
                </div>}
                className="report title-2" style={{ minHeight: "200px" }}
            >
                <Row>
                    <Col
                        xl={{ span: 9 }}
                        lg={{ span: 9 }}
                        md={{ span: 9 }}
                        sm={{ span: (isMobileOnly && !location?.pathname?.includes("report")) ? 8 : 12 }}
                        xs={{ span: (isMobileOnly && !location?.pathname?.includes("report")) ? 8 : 12 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            fontSize: "12px",
                        }}
                    >
                        <p className="count-up cart-item-mobile-font">
                            <CountUp end={data?.summary?.ValPro} prefix="$" />
                        </p>
                        <p>Current Valuation</p>
                    </Col>
                    <Col
                        xl={{ span: 5 }}
                        lg={{ span: 5 }}
                        md={{ span: 5 }}
                        sm={{ span: (isMobileOnly && !location?.pathname?.includes("report")) ? 8 : 12 }}
                        xs={{ span: (isMobileOnly && !location?.pathname?.includes("report")) ? 8 : 12 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            fontSize: "12px",
                        }}
                    >
                        <p className="count-up cart-item-mobile-font">
                            <CountUp
                                end={data?.summary?.valueScore}
                                prefix=""
                            />
                        </p>
                        <p>Confidence</p>
                    </Col>
                    <Col
                        xl={{ span: 5 }}
                        lg={{ span: 5 }}
                        md={{ span: 5 }}
                        sm={{ span: (isMobileOnly && !location?.pathname?.includes("report")) ? 8 : 12 }}
                        xs={{ span: (isMobileOnly && !location?.pathname?.includes("report")) ? 8 : 12 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            fontSize: "12px",
                        }}
                    >
                        <p className="count-up cart-item-mobile-font">
                            <CountUp
                                end={data?.summary?.FSD * 100}
                                prefix=""
                            />
                        </p>
                        <p>FSD</p>
                    </Col>
                    {(data?.avv && data?.avv?.status?.toLowerCase() === "completed") &&
                        location?.pathname?.includes("report") && (
                            <Col
                                xl={{ span: 5 }}
                                lg={{ span: 5 }}
                                md={{ span: 5 }}
                                sm={{ span: 12 }}
                                xs={{ span: 12 }}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    fontSize: "12px",
                                }}
                            >
                                <p className="count-up cart-item-mobile-font">
                                    <CountUp
                                        end={data?.avv?.foxy_valpro}
                                        prefix="$"
                                    />
                                </p>
                                <p>AVV Value</p>
                            </Col>
                        )}

                </Row>
                {location?.pathname?.includes("report") && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>
                            <Button type="primary" block disabled={buttonDisabled} onClick={handleAvvClick}>
                                {buttonText}
                            </Button>
                        </Col>
                    </Row>
                )}
            </CustomCard>
            <Modal
                title="Property AVV Images"
                open={isModalVisible}
                onCancel={toggleModal}
                footer={null} 
                className="image-modal" 
                width={800}
            >
                <Carousel
                    autoplay={false}
                    arrows={true}
                    dots={true}
                    effect="fade"

                >
                    {data?.avv?.images?.map((image, index) => (
                        <div key={index}>
                            <img loading="lazy" className="carousel-image" src={image} alt={`Slide ${index}`} />
                        </div>
                    ))}
                </Carousel>
            </Modal>

        </ErrorBoundary>
    );
}

export default ValProCard;
