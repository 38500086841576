import { Fragment, useState, useEffect, useRef } from "react";
import { isBrowser, isMobileOnly } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Tabs, Row, Col, Typography, Radio, Skeleton, Divider } from "antd";
import MarketSegmentGraph from "./MarketSegmentGraph";
import MarketSegmentPieCharts from "./MarketSegmentPieCharts";
import "./Tiers.css";
import useWindowSize from "../../hooks/useWindowSize";
import { useSegmentation } from "./useSegmentation";
import { ErrorBoundary } from "../shared";
import { marketSegmentInfo, zipcodeInfo } from "../../constants/defaultKeys";


const { TabPane } = Tabs;
const { Title } = Typography;
const SMALLER_SCREEN_WIDTH = 1420;

function MarketSegment() {

  const params = useParams();
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const [elementHeight, setElementHeight] = useState(500);
  const elementRef = useRef(null);

  if (location?.pathname?.includes("report")) {
    searchParams = new URLSearchParams({
      ...(searchParams ? searchParams : {}),
      isWebsite: true
    });
  }

  const filters = { ...params, queryParams: searchParams }
  const { data, isLoading, isError } = useSegmentation(filters, { enabled: params.reportName !== null });
  // eslint-disable-next-line no-unused-vars
  const [screenWidth, _] = useWindowSize();
  const [currentTab, setCurrentTab] = useState("valueBy");
  const [value3, setValue3] = useState("rsfr");
  let customWidth = null;
  let largeScreen = isBrowser && screenWidth > SMALLER_SCREEN_WIDTH

  const areAllEmptyLists = (data) => {
    for (const key in data) {
      if (!Array.isArray(data[key]) || data[key].length !== 0) {
        return false;
      }
    }
    return true;
  }

  let hasRsfrData = !areAllEmptyLists(data?.rsfr);
  let hasCondoData = !areAllEmptyLists(data?.condos);

  useEffect(() => {
    if (data?.rsfr && data?.condos) {
      if (!hasRsfrData && !hasCondoData) {
        window.parent.postMessage("NO DATA", "*");
        return null;
      } else if (!hasRsfrData && hasCondoData) {
        setValue3("condos")
      } else if (hasRsfrData && !hasCondoData) {
        setValue3("rsfr")
      }
    }
  }, [hasRsfrData, hasCondoData, data?.rsfr, data?.condos])

  useEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        const newHeight = elementRef.current.clientHeight + 108;
        setElementHeight(newHeight);
        if (params?.propType === "address") {
          window.parent.postMessage({ propertySegmentationHeight: newHeight }, "*");
        } else if (params?.propType === "zip_code") {
          window.parent.postMessage({ zipSegmentationHeight: newHeight }, "*");
        } else if (params?.propType === "metro") {
          window.parent.postMessage({ metroSegmentationHeight: newHeight }, "*");
        } else {
          window.parent.postMessage({ segmentationHeight: newHeight }, "*");
        }
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [data, elementHeight, elementRef, params?.propType]);

  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param === "width" && value && value !== "undefined") {
      try {
        let wd = parseInt(value.replace("px", ""))
        if (wd && !isNaN(wd)) {
          customWidth = wd;
        }
      } catch (e) {
        customWidth = null;
      }
    }
  }

  if (customWidth !== null) {
    largeScreen = isBrowser && customWidth > SMALLER_SCREEN_WIDTH
  }

  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null
  }

  if (!isLoading && !isError && (!data || data === null)) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  if (isLoading) {
    return <Skeleton />
  }

  let valueBy = data[value3].value_by
  let sizeBy = data[value3].size_by
  let ageBy = data[value3].age_by

  let allData = { valueBy, sizeBy, ageBy }
  let titles = { valueBy: "Value", sizeBy: "Size", ageBy: "Year Built" };
  let currentChartData = allData[currentTab]
  let currentHouse = data?.current_house

  function onChange(key) {
    setCurrentTab(key);
  }

  function renderPieCharts() {
    if ((currentChartData && currentChartData.length === 0) || !currentChartData) {
      return null;
    }
    return currentChartData?.map((item, i) => {
      let highlight = false;
      if (currentTab === "valueBy") {
        highlight = currentHouse?.value >= item.tier_min && currentHouse?.value <= item.tier_max
      } else if (currentTab === "sizeBy") {
        highlight = currentHouse?.size >= item.tier_min && currentHouse?.size <= item.tier_max
      } else {
        highlight = currentHouse?.age >= item.tier_min && currentHouse?.age <= item.tier_max
      }
      return (
        <Col
          key={i}
          lg={isBrowser ? { span: 6 } : { span: 6 }}
          md={{ span: 12 }}
          xs={{ span: 12 }}
          style={{
            textAlign: "center"
          }}
        >
          <MarketSegmentPieCharts data={item} />
          <p
            className={highlight ? "highlight-piechart-tier" : "pieChart"}
          >
            Tier {i + 1}
          </p>
          <p className="pieChart" style={{ fontSize: isMobileOnly ? "9px" : "10px" }}>
            Min:{" "}
            {currentTab === "valueBy"
              ? `$${item?.tier_min.toLocaleString()}`
              : currentTab === "sizeBy"
                ? `Sq Ft ${item?.tier_min.toLocaleString()}`
                : `Yr ${item?.tier_min}`}
          </p>
          <p className="pieChart" style={{ fontSize: isMobileOnly ? "9px" : "10px" }}>
            Max:{" "}
            {currentTab === "valueBy"
              ? `$${item?.tier_max.toLocaleString()}`
              : currentTab === "sizeBy"
                ? `Sq Ft ${item?.tier_max.toLocaleString()}`
                : `Yr ${item?.tier_max}`}
          </p>
        </Col>
      );
    });
  }

  const onChange3 = ({ target: { value } }) => {
    setValue3(value);
  };

  let showText = () => {
    if (searchParams?.get("text")) {
      if (["true", "1", "yes", "y"].includes(searchParams?.get("text"))) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  let componentTitle = () => {
    if (params?.propType === "zip_code") {
      return "Segmentation Report for ZIP Code " + params?.code
    } else if (params?.propType === "metro") {
      return "Segmentation Report for " + params?.reportName
    } else {
      return "Segmentation Reports"
    }
  }
  return (
    <ErrorBoundary>
      <div className="title-2 mb-2 segmentation-card" ref={elementRef}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
          <div>
            <span className="mobile-title-font" style={{ fontSize: "18px", fontWeight: "500" }}>{componentTitle()}</span>
            {showText() && !isMobileOnly && (params?.propType === "zip_code" ? zipcodeInfo() : marketSegmentInfo())}
          </div>
          <Radio.Group
            onChange={onChange3}
            value={value3}
            buttonStyle="solid"
            optionType="button"
            size={isMobileOnly ? "small" : "default"}
          >
            {hasRsfrData && <Radio value={"rsfr"} style={{ fontSize: isMobileOnly ? "12px" : "16px" }}>RSFR</Radio>}
            {hasCondoData && params?.propType !== 'address' && <Radio style={{ fontSize: isMobileOnly ? "12px" : "16px" }} value={"condos"}>Condos</Radio>}
          </Radio.Group>
        </div>
        <Fragment>
          <Tabs
            defaultActiveKey="0"
            size="large"
            onChange={onChange}
          >{Object.keys(allData).map(key => (
            <TabPane tab={isMobileOnly ? `By ${titles[key]}` : `Segmentation by ${titles[key]}`} key={key}>
              <Row gutter={[16, 16]}>
                <Col
                  lg={largeScreen ? { span: 12 } : { span: 20, offset: 2 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {currentChartData?.length > 0 && <center>
                    {!isMobileOnly && <h2>
                      Last Year Change <small>(vs)</small> Forecast
                    </h2>
                    }
                    {isMobileOnly && <h3>
                      Last Year Change <small>(vs)</small> Forecast
                    </h3>
                    }
                  </center>
                  }
                  <MarketSegmentGraph
                    data={currentChartData}
                    currentHouse={currentHouse}
                    CurrentTab={currentTab}
                  />
                </Col>
                <Col
                  lg={largeScreen ? { span: 12 } : { span: 24 }}
                  md={{ span: 24 }}
                >
                  {currentChartData?.length > 0 && !isMobileOnly && <center>
                    <Title level={4} style={{ marginBottom: 0 }}>
                      % Rising <small>(vs)</small> % Not Rising
                    </Title>
                  </center>
                  }
                  {isMobileOnly && <Divider />}
                  <Row gutter={[12, 12]}>{renderPieCharts()}</Row>
                </Col>
              </Row>
            </TabPane>
          ))}
          </Tabs>
        </Fragment>
      </div>
    </ErrorBoundary>
  );
}
export default MarketSegment;
