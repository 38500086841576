import { Button, Col, Result, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function Page403() {
  const navigate = useNavigate();

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Col>
        <Result
          status="403"
          title="403"
          // icon={
          //   <>
          //     <img src={error401} height="50%" width="50%" />
          //   </>
          // }
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </Col>
    </Row>
  );
}

export default Page403;
