import React from "react";
import { Marker } from "@react-google-maps/api";

const HouseMarker = ({ house, onClick, isForecast = false }) => {
  return (
    <Marker
      position={{ lat: house?.lat, lng: house?.lon }}
      onClick={() => onClick(house)}
      icon={isForecast ? house?.forecast_icon : house?.pic0_icon}
    />
  );
};

export default HouseMarker;
