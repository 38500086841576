import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Col, Row, Table, Typography, Skeleton, Divider } from "antd";
import CustomCard from "../../blocks/Card/Card";
import { getColumnSearchProps } from "../../blocks/TableSearch/SearchFunc";
import { useFixFlip } from "./useFixFlip";
import "./FixFlip.css";  // Import the CSS module
import { isMobileOnly } from "react-device-detect";
import "./style.css";


function formatDate(dateString) {
    if (dateString.length !== 8) return "Invalid input";

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${month}/${day}/${year}`;
}

function formatNumberWithCommas(x) {
    try {
        if (!x) return "-";
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    catch (err) {
        return "-";
    }
}


function FixFlip() {

    const tableContainerRef1 = useRef(null);
    const tableContainerRef2 = useRef(null);
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(2110);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useFixFlip(filters, { enabled: params.reportName !== null });
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 200;
                setElementHeight(newHeight);
                window.parent.postMessage({ fixFlipHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);


    const onScrollClick = () => {
        if (tableContainerRef1?.current) {
            // The actual scrollable element might be a child element; adjust selector as needed.
            const scrollableElement = tableContainerRef1?.current.querySelector('.ant-table-body');
            if (scrollableElement) {
                scrollableElement.scrollBy({ left: 200, behavior: 'smooth' }); // Adjust as needed
            }
        }
    };

    const onScrollClick2 = () => {
        if (tableContainerRef2?.current) {
            // The actual scrollable element might be a child element; adjust selector as needed.
            const scrollableElement = tableContainerRef2?.current.querySelector('.ant-table-body');
            if (scrollableElement) {
                scrollableElement.scrollBy({ left: 200, behavior: 'smooth' }); // Adjust as needed
            }
        }
    };

    const columns = [
        {
            title: "",
            dataIndex: "fulladdress",
            key: "fulladdress",
            render: (value) => value,
            fixed: "left",
            width: 160,

        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Sq Ft | Lot</span>,
            dataIndex: "wrr_sqft",
            key: "combinedSqftAndLot",
            render: (text, record) => (
                <>
                    <span>{formatNumberWithCommas(record?.wrr_sqft) || "-"}</span>
                    <span style={{ margin: '0 10px' }}>|</span>
                    <span>{formatNumberWithCommas(record?.wrr_lotsize) || "-"}</span>
                </>
            ),
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Yr Built</span>,
            dataIndex: "wrr_yr_blt",
            key: "wrr_yr_blt",
            className: 'centered-column',
            render: (value) => (value ? `${parseInt(value) || value}` : "-"),
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Bed | Bath</span>,
            dataIndex: "wrr_nbr_bedrms",
            key: "combinedData",
            render: (text, record) => (
                <>
                    <span>{record?.wrr_nbr_bedrms || "-"}</span>
                    <span style={{ margin: '0 10px' }}>|</span>
                    <span>{record?.wrr_nbr_bath || "-"}</span>
                </>
            ),
            className: 'centered-column',
        },
        {
            title: <span style={{  textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>After Repair Value</span>,
            dataIndex: "median",
            key: "median",
            render: (value) => (value ? `$${formatNumberWithCommas(value)}` : "-"),
            className: 'centered-column',
        },
    ];
    const columns2 = [
        {
            title: "Comps Used",
            dataIndex: "fulladdress",
            key: "fulladdress",
            ...getColumnSearchProps(
                "fulladdress",
                setSearchText,
                setSearchedColumn,
                searchText,
                searchedColumn
            ),
            fixed: "left",
            width: 160
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Sq Ft | Lot</span>,
            dataIndex: "wrr_sqft",
            key: "combinedSqftAndLot",
            render: (text, record) => (
                <>
                    <span>{formatNumberWithCommas(record?.wrr_sqft) || "-"}</span>
                    <span style={{ margin: '0 10px' }}>|</span>
                    <span>{formatNumberWithCommas(record?.wrr_lotsize) || "-"}</span>
                </>
            ),
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Yr Built</span>,
            dataIndex: "wrr_yr_blt",
            key: "wrr_yr_blt",
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center",  display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Bed | Bath</span>,
            dataIndex: "wrr_nbr_bedrms",
            key: "combinedData",
            render: (text, record) => (
                <>
                    <span>{record?.wrr_nbr_bedrms || "-"}</span>
                    <span style={{ margin: '0 10px' }}>|</span>
                    <span>{record?.wrr_nbr_bath || "-"}</span>
                </>
            ),
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Dist</span>,
            dataIndex: "dist",
            key: "dist",
            render: (value) => (value ? `${formatNumberWithCommas((value).toFixed(0))} miles` : "-"),
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>First Sale Date</span>,
            dataIndex: "sale1date",
            key: "sale1date",
            render: (value) => `${value !== "-" ? formatDate(value.toString()) : value}`,
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>First Sale Price</span>,
            dataIndex: "sale1val",
            key: "sale1val",
            render: (value) => (value ? `$${formatNumberWithCommas(value)}` : "-"),
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Second Sale Date</span>,
            dataIndex: "sale2date",
            key: "sale2date",
            render: (value) => `${value !== "-" ? formatDate(value.toString()) : value}`,
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Second Sale Price</span>,
            dataIndex: "sale2val",
            key: "sale2val",
            render: (value) => (value ? `$${formatNumberWithCommas(value)}` : "-"),
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Valuation</span>,
            dataIndex: "valuation",
            key: "valuation",
            render: (value) => (value ? `$${formatNumberWithCommas(value)}` : "-"),
            className: 'centered-column',
        },
    ];

    if (isLoading) {
        return (
            <CustomCard title="Fix Flip" className="title-2">
                <Skeleton />
            </CustomCard>
        )
    }

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    let comporableData = data?.table2Data?.comparables || [];
    let subjectData = data?.table2Data?.subject || {};
    return (
        <div ref={elementRef} className="fix-flip-card">
            <Typography.Title level={4} style={{ marginBottom: "20px" }}>
                Fix & Flip
            </Typography.Title>
            <Divider />
            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <Typography.Title level={isMobileOnly ? 5 : 4} style={{ marginBottom: 0 }}>
                        Estimated Value Analysis Using Comparable Flipped Properties
                    </Typography.Title>
                    <br />
                    <Typography.Text>
                        This tool estimates the post-repair value for the subject property
                        based on an analysis of properties with similar characteristics that
                        have recently been bought, repaired, and re-sold.
                    </Typography.Text>
                    <div className="scroll-overlay" onClick={onScrollClick}>Swipe to see more →</div>
                </Col>
                <Col span={24}>
                    <div className="responsive-table">
                        <Table
                            ref={tableContainerRef1}
                            dataSource={data?.table1Data?.map((item, index) => ({ ...item, key: index }))}
                            columns={columns}
                            scroll={{ x: "max-content" }}
                            loading={isLoading}
                            pagination={false}
                            rowKey={(record, index) => index}
                            sticky
                        />
                    </div>
                </Col>
                <Col span={24} style={{ marginTop: isMobileOnly ? "24px" : "5px" }}>
                    <div style={{ padding: "5px", marginTop: "12px", display: "flex", gap: "12px", justifyContent: "start", alignItems: "center" }}>
                        <span className="mobile-font" style={{ fontWeight: 550, minWidth: isMobileOnly ? "210px" : "200px" }}>Estimated Value using Flips</span>
                        <span className="mobile-font" style={{ fontWeight: 550 }}>
                            ${formatNumberWithCommas(subjectData[0]?.EstARV)}
                        </span>
                    </div>
                </Col>
                {isMobileOnly && <Divider />}
                <Col span={24}>
                    <Typography.Title level={isMobileOnly ? 5 : 4} style={{ marginBottom: 0, marginTop: "30px" }}>
                        Comparables
                    </Typography.Title>
                    <div className="scroll-overlay2" onClick={onScrollClick2}>Swipe to see more →</div>
                </Col>
                <Col span={24}>
                    <div className="responsive-table">
                        <Table
                            ref={tableContainerRef2}
                            dataSource={comporableData.map((item, index) => ({ ...item, key: index }))}
                            columns={columns2}
                            scroll={{ x: "max-content" }}
                            loading={isLoading}
                            pagination={true}
                            rowKey={(record, index) => index}
                            sticky
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default FixFlip;

