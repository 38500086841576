import React, { useState, useEffect, useRef, useMemo } from "react";
import { Slider, Button, Row, Col, Skeleton } from "antd";
import CustomCard from "../../blocks/Card/Card";
import "./InteractiveMap.css";
import BedBathFilter from "./BedBathFilter";
import { getSliderMarks, HouseCircle } from "./helper";
import { useInteractiveMap } from "./useInteractiveMap";
import { useParams, useLocation } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const LazyGoogleMapReact = React.lazy(() => import("google-map-react"));
const InteractiveMap = () => {
  const params = useParams();
  const location = useLocation();
  const [mapBounds, setMapBounds] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const timerRef = useRef(null);
  const [bedValue, setBedValue] = useState([0, 5]);
  const [bathValue, setBathValue] = useState([0, 4]);
  const [sqftMinValue, setSqftMinValue] = useState(0);
  const [sqftMaxValue, setSqftMaxValue] = useState(10000);
  const [yearRange, setYearRange] = useState([1900, new Date().getFullYear()]);
  const mapContainerRef = useRef(null);

  let searchParams = new URLSearchParams(location.search);
  if (location?.pathname?.includes("report")) {
    searchParams = new URLSearchParams({ ...searchParams, isWebsite: true });
  }

  const filters = { ...params, queryParams: searchParams };
  const { data, isLoading, isError } = useInteractiveMap(filters, {
    enabled: params.reportName !== null,
  });

  const filterControls = {
    bedValue,
    bathValue,
    sqftMinValue,
    sqftMaxValue,
    yearRange,
  };
  const setFilters = {
    setBedValue,
    setBathValue,
    setSqftMinValue,
    setSqftMaxValue,
    setYearRange,
  };

  const handleMapChange = ({ bounds }) => setMapBounds(bounds);

  useEffect(() => {
    if (isPlaying) {
      timerRef.current = setInterval(() => {
        setCurrentMonth((prevMonth) =>
          prevMonth < 35 ? prevMonth + 1 : prevMonth
        );
      }, 1000);
      return () => clearInterval(timerRef.current);
    }
  }, [isPlaying]);

  const handlePlay = () => setIsPlaying(true);
  const handleStop = () => {
    setIsPlaying(false);
    clearInterval(timerRef.current);
  };
  const handleReset = () => {
    setIsPlaying(false);
    clearInterval(timerRef.current);
    setCurrentMonth(0);
  };

  const filteredHouses = useMemo(() => {
    if (!data) return null;
    if (mapBounds === null) return [];
    return data?.houses?.filter(
      (house) =>
        house.sqft >= sqftMinValue &&
        house.sqft <= sqftMaxValue &&
        (house.beds === 0 ||
          (house.beds >= bedValue[0] && house.beds <= bedValue[1])) &&
        house.baths >= bathValue[0] &&
        house.baths <= bathValue[1] &&
        house.yearbuilt >= yearRange[0] &&
        house.yearbuilt <= yearRange[1] &&
        house.lat >= mapBounds?.sw.lat &&
        house.lat <= mapBounds?.ne.lat &&
        house.lon >= mapBounds?.sw.lng &&
        house.lon <= mapBounds?.ne.lng
    );
  }, [
    data,
    sqftMinValue,
    sqftMaxValue,
    bedValue,
    bathValue,
    yearRange,
    mapBounds,
  ]);

  const createMapOptions = useMemo((maps) => {
    return {
      mapTypeControl: true,
      gestureHandling: "cooperative",
      streetViewControl: true,
      fullscreenControl: false,
      styles: [
        {
          stylers: [
            { saturation: -100 },
            { gamma: 0.8 },
            { lightness: 4 },
            { visibility: "on" },
          ],
        },
      ],
    };
  }, []);

  const fullscreenHandle = useFullScreenHandle();

  const handleFullscreenChange = (isFullscreen) => {
    if (isFullscreen) {
      window.dispatchEvent(new Event("resize"));
    }
  };

  if (isLoading) {
    return (
      <CustomCard title="Interactive Map" className="title-2">
        <Skeleton />
      </CustomCard>
    );
  }

  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  return (
    <FullScreen handle={fullscreenHandle} onChange={handleFullscreenChange}>
      <CustomCard
        title={
          <BedBathFilter
            setFilters={setFilters}
            filters={filterControls}
            mapContainerRef={mapContainerRef}
            fullscreenHandle={fullscreenHandle}
          />
        }
        className="title-2"
      >
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24} ref={mapContainerRef}>
            <div
              style={{
                width: fullscreenHandle.active ? "100%" : "100%",
                height: fullscreenHandle.active ? "67vh" : "350px",
              }}
              className="map-box"
            >
              <React.Suspense fallback={<div>Loading map...</div>}>
                <LazyGoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                  defaultCenter={{ lng: data?.lng, lat: data?.lat }}
                  defaultZoom={16}
                  zoom={16}
                  yesIWantToUseGoogleMapApiInternals
                  options={createMapOptions}
                  onChange={handleMapChange}
                  libraries={["visualization"]}
                >
                  {filteredHouses?.map((house) => (
                    <HouseCircle
                      key={house.property_id}
                      mapContainerRef={mapContainerRef}
                      lat={house.lat}
                      lng={house.lon}
                      color={house.change[currentMonth] || 0}
                      to={`/report/${house.fulladdress} ${house.city} ${house.state} ${data?.zipcode}/address/${data?.zipcode}`}
                      houseDetail={`${house.fulladdress}: ${
                        house.beds || "-"
                      } bedroom(s), ${
                        house.baths || "-"
                      } bathroom(s), built in ${house.yearbuilt || "-"}`}
                    />
                  ))}
                </LazyGoogleMapReact>
              </React.Suspense>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: fullscreenHandle.active ? "20px" : "40px",
                marginBottom: "10px",
              }}
            >
              <Button
                type="primary"
                onClick={handlePlay}
                style={{ margin: "0 5px", width: "100px", borderRadius: "5px" }}
              >
                Play
              </Button>
              <Button
                type="danger"
                onClick={handleStop}
                style={{ margin: "0 5px", width: "100px", borderRadius: "5px" }}
              >
                Stop
              </Button>
              <Button
                type="primary"
                onClick={handleReset}
                style={{ margin: "0 5px", width: "100px", borderRadius: "5px" }}
              >
                Reset
              </Button>
              {!fullscreenHandle.active && (
                <Button
                  type="primary"
                  onClick={fullscreenHandle.enter}
                  style={{
                    margin: "0 5px",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                >
                  Fullscreen
                </Button>
              )}
              {fullscreenHandle.active && (
                <Button
                  type="primary"
                  onClick={fullscreenHandle.exit}
                  style={{
                    margin: "0 5px",
                    width: "120px",
                    borderRadius: "5px",
                  }}
                >
                  Exit Fullscreen
                </Button>
              )}
            </div>
          </Col>
          <Col span={24} style={{marginBottom: fullscreenHandle.active ? "40px" : '0px'}}>
            <Slider
              marks={getSliderMarks()}
              min={0}
              max={35}
              value={currentMonth}
              onChange={setCurrentMonth}
            />
          </Col>
        </Row>
      </CustomCard>
    </FullScreen>
  );
};

export default InteractiveMap;
