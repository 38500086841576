import React from "react";
import Marker from "./Marker";
import GoogleMapReact from "google-map-react";
import "./GoogleMap.css";
import { isMobileOnly } from "react-device-detect";

const GoogleMap = ({ data, curHouseKey, setValData }) => {
  let key = process.env.REACT_APP_MAP_KEY;

  const defaultProps = {
    center: {
      lat: curHouseKey?.lat,
      lng: curHouseKey?.lng,
    },
  };

  return (
    <div className="custom-map">
      <GoogleMapReact
        defaultZoom={15}
        bootstrapURLKeys={{ key }}
        defaultCenter={defaultProps.center}
        yesIWantToUseGoogleMapApiInternals={true}
        options={{
          streetViewControl: true,
          mapTypeControl: isMobileOnly ? false : true,
        }}
      >
        {data?.map((place) => (
          <Marker
            key={place.WRRID}
            data={place}
            valData = {data}
            lat={place?.lat}
            lng={place?.lng}
            setValData={setValData}
          />
        ))}

        <Marker
          key={curHouseKey?.WRRID}
          lat={curHouseKey?.lat}
          lng={curHouseKey?.lng}
          curHouse={true}
        />
      </GoogleMapReact>
    </div>
  );
};
export default GoogleMap;
