import { Fragment } from "react";
import { Button, Col, Row, Tabs, Typography } from "antd";
import { isBrowser } from "react-device-detect";
import CustomCard from "../../blocks/Card/Card";
import { clear, getItem } from "../../helpers/localStorage";
import { USER } from "../../constants/defaultKeys";
import { useNavigate } from "react-router-dom";
import MyUsage from "./MyUsage/MyUsage";
import "./Dashboard.css";

const { TabPane } = Tabs;
const { Title } = Typography;

function MyReports() {
  const userDetails = JSON.parse(getItem(USER));
  const navigate = useNavigate();
  const handleClick = () => {
    clear();
    navigate("/");
  };

  let userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  let isUserStaff = userFromLocalStorage && userFromLocalStorage.is_staff;


  return (
    <Fragment>
      <Row>
        <Col
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{ paddingBottom: 20 }}
        >
          <CustomCard
            style={{
              background: "linear-gradient(135deg, #f7fcf5 20%, #f9fbf4 80%)",
              shadow: "none",
            }}
          >
            <Row gutter={[12, 12]}>
              <Col
                xl={{ span: 10, offset: 3 }}
                lg={{ span: 10, offset: 4 }}
                md={{ span: 10 }}
                sm={{ span: 10, offset: 3 }}
                xs={{ span: 10, offset: 2 }}
              >
                <Title level={3} className="user-name">
                  {userDetails?.first_name ? userDetails.first_name : userDetails?.email}
                </Title>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 6 }}
                sm={{ span: 6 }}
                xs={{ span: 6 }}
              >
              </Col>
              <Col
                xl={{ span: 4 }}
                lg={{ span: 3, offset: 1 }}
                md={{ span: 4 }}
                sm={{ span: 4 }}
                xs={{ span: 4 }}
              >
                {isUserStaff && (
                  <Button
                    type="primary"
                    size="large"
                    style={{ textAlign: "right", marginRight: "10px"}}
                    onClick={() => navigate("/admin-report")}
                  >
                    Admin Report
                  </Button>
                
                )}
                <Button
                  type="primary"
                  size="large"
                  style={{ textAlign: "right" }}
                  onClick={() => handleClick()}
                >
                  Logout
                </Button>
              </Col>
            </Row>
          </CustomCard>
        </Col>
      </Row>
      <Row>
        <Col
          lg={isBrowser ? { span: 16, offset: 4 } : { span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          sm={{ span: 24 }}
          xs={{ span: 22, offset: 1 }}
        >
          <CustomCard style={{ marginBottom: "20px" }}>
            <Tabs className="profile-tabs" size="default">
              <TabPane tab="My Report" key="1">
                <MyUsage />
              </TabPane>
            </Tabs>
          </CustomCard>
        </Col>
      </Row>
    </Fragment>
  );
}

export default MyReports;
