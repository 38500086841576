import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { getChartLabelPlugin } from 'chart.js-plugin-labels-dv';

const ChartWithLabels = ({ type, data, options }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!Chart.registry?.plugins.get('chartjs-plugin-labels-dv')) {
      Chart.register(getChartLabelPlugin());
    }

    const ctx = chartRef.current.getContext('2d');
    const existingChart = chartRef.current.chart;
    if (existingChart) {
      existingChart.destroy();
    }

    const newChart = new Chart(ctx, {
      type,
      data,
      options
    });

    chartRef.current.chart = newChart;

    return () => {
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [type, data, options]);

  return <canvas ref={chartRef}></canvas>;
};

export default ChartWithLabels;
