import { isMobileOnly } from "react-device-detect";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Col, Row, PageHeader } from "antd";
import {
  Summary,
  PageTitle,
  IndexTools,
  Segmentation,
  BirdsEyeView,
  DynamicZipcodeMap,
  Listings,
} from "../../../Widgets";
import { useSearchHistoryUpdate } from "../../../hooks/useSearchHistoryUpdate";
import "../Report.css";
import InteractiveMap from "../../../Widgets/interactiveMap/InteractiveMap";

const ZipCodes = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("tab");

  const filters = { ...params, queryParams: searchParams }
  useSearchHistoryUpdate(filters, { enabled: params.reportName !== null });

  return (
    <Row gutter={[16, 16]}>
      <Col className="page-title-row" span={24} style={{ position: 'sticky', top: 60, zIndex: 1000 }}>
        <div className="page-title">
          <PageTitle />
        </div>
      </Col>
      {searchTerm !== "listings" && (
        <>
          <Col
            xl={{ span: 8 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 8 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            className="one-year-container"
          >
            <Summary />
          </Col>
          <Col
            xl={{ span: 16 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 16 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <IndexTools />
          </Col>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <Segmentation />
          </Col>
          <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
            <InteractiveMap />
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <DynamicZipcodeMap />
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <BirdsEyeView />
          </Col>
        </>
      )}
      {searchTerm === "listings" && (
        <>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className="mb-2"
          >
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => navigate(pathname)}
              title="Back"
            />
            <Listings />
          </Col>
        </>
      )}
    </Row>
  );
};

export default ZipCodes;
