import { Statistic } from "antd";
import React, { useContext } from "react";
import {
  Bar,
  BarChart,
  Tooltip,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
  ReferenceLine,
} from "recharts";
import { ThemeContext } from "../../context/ThemeContext";
import { isMobileOnly } from "react-device-detect";


function MarketSegmentGraph({ data, CurrentTab, currentHouse }) {
  const { theme } = useContext(ThemeContext);

  function addPrefixAndSuffix(BarData, tier, prefix = "", suffix = "") {
    BarData.minimum =
      tier.tier_min === "-" ? (
        `${prefix} ${tier.tier_max} ${suffix}`
      ) : CurrentTab === "ageBy" ? (
        `${prefix} ${tier.tier_min} ${suffix}`
      ) : (
        <Statistic
          value={tier.tier_min}
          prefix={prefix}
          suffix={suffix}
          valueStyle={{ color: "white" }}
        />
      );
    BarData.maximum =
      tier.tier_max === "-" ? (
        `${prefix} ${tier.tier_max} ${suffix}`
      ) : CurrentTab === "ageBy" ? (
        `${prefix} ${tier.tier_max} ${suffix}`
      ) : (
        <Statistic
          value={tier.tier_max}
          prefix={prefix}
          suffix={suffix}
          valueStyle={{ color: "white" }}
        />
      );
  }

  const BarChartData = data?.map((tier, i) => {
    let highlight = false;
    if (CurrentTab === "valueBy") {
      highlight = currentHouse?.value >= tier?.tier_min && currentHouse?.value <= tier?.tier_max
    } else if (CurrentTab === "sizeBy") {
      highlight = currentHouse?.size >= tier?.tier_min && currentHouse?.size <= tier?.tier_max
    } else {
      highlight = currentHouse?.age >= tier?.tier_min && currentHouse?.age <= tier?.tier_max
    }
    let BarData = {
      xAxis: `Tier ${i + 1}`,
      lastYearChange: Math.floor(tier.last_year_change * 100),
      forecast: Math.floor(tier.forecast * 100),
      highlight: highlight ? true : false,
      minimum: 0,
      maximum: 1,
    };
    if (CurrentTab === "valueBy") {
      addPrefixAndSuffix(BarData, tier, "$");
    } else if (CurrentTab === "sizeBy") {
      addPrefixAndSuffix(BarData, tier, "Sq Ft ");
    } else {
      addPrefixAndSuffix(BarData, tier, "Yr");
    }
    return BarData;
  });
  function renderTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            fontSize: "14px",
            width: "180px",
            height: "auto",
            background: "#232339",
            color: "white",
            padding: "10px",
          }}
        >
          <span>{label}</span>
          <br />
          <span>
            Last Year Change:{" "}
            {`${Math.floor(payload[0]?.payload.lastYearChange)} %`}
          </span>
          <br />
          <span>
            Forecast: {`${Math.floor(payload[0]?.payload.forecast)} %`}
          </span>
          <br />
          <span>
            Minimum:{" "}
            {CurrentTab === "ageBy"
              ? payload[0]?.payload?.minimum
              : `${
                  payload[0]?.payload?.minimum?.props?.prefix
                } ${payload[0]?.payload?.minimum?.props?.value.toLocaleString()}`}
          </span>
          <br />
          <span>
            Maximum:{" "}
            {CurrentTab === "ageBy"
              ? payload[0]?.payload?.maximum
              : `${
                  payload[0]?.payload?.maximum?.props?.prefix
                } ${payload[0]?.payload?.maximum?.props?.value.toLocaleString()}`}
          </span>
        </div>
      );
    }
    return null;
  }
  const renderCustomAxisTick = ({ x, y, payload }) => {
    const filteredData = BarChartData?.filter(
      (item) => item.xAxis === payload.value
    )[0];
    return (
      <>
        <text
          x={x}
          y={y}
          dy={10}
          fontSize={10}
          textAnchor="middle"
          className={filteredData?.highlight ? "highlight-tier" : ""}
        >
          {filteredData?.xAxis}
        </text>
        <text x={x} y={y} dy={25} dx={-30} fontSize={isMobileOnly ? 9 : 10}>
          Min:{" "}
          {CurrentTab === "ageBy"
            ? filteredData?.minimum
            : `${
                filteredData?.minimum?.props?.prefix
              }${filteredData?.minimum?.props?.value.toLocaleString()}`}
        </text>
        <text x={x} y={y} dy={43} dx={-30} fontSize={isMobileOnly ? 9 : 10}>
          Max:{" "}
          {CurrentTab === "ageBy"
            ? filteredData?.maximum
            : `${
                filteredData?.maximum.props?.prefix
              }${filteredData?.maximum.props?.value.toLocaleString()}`}
        </text>
      </>
    );
  };

  if ((data && data?.length === 0) || !data) {
    return null;
  }
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={BarChartData} margin={{ top: 5, right: 0, left: isMobileOnly ? -45 : 0, bottom: 15 }}>
        <XAxis
          dataKey="xAxis"
          tick={renderCustomAxisTick}
          tickLine={{ stroke: "red" }}
        />
        <YAxis />
        <Tooltip content={renderTooltip} />
        <Bar dataKey="lastYearChange" barSize={18} fill={"#76CAE9"}>
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={"#76CAE9"} />
          ))}
        </Bar>
        <ReferenceLine y={0} stroke="#c9c9c9" />
        <Bar dataKey="forecast" barSize={18} fill={theme?.primaryColor}>
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={theme?.primaryColor} />
          ))}
        </Bar>
        <Legend
          wrapperStyle={{
            paddingTop: "45px",
          }}
          formatter={(value) => {
            return (
              <span>
                {value === "lastYearChange" ? "Last Year Change " : "Forecast"}
              </span>
            );
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default MarketSegmentGraph;
