import React, { Fragment, useEffect } from "react";
import { Routes, Route, HashRouter, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import NoMatch from "../components/NoMatch";
import Report from "../components/Report/Report";
import Dashboard from "../components/Dashboard/Dashboard";
import MyReports from "../components/Dashboard/MyReports";
import Login from "../components/Authentication/Login/Login";
import WelcomeCard from "../components/WelcomeCard/WelcomeCard";
import Logout from "../components/Authentication/Logout/Logout";
import Signup from "../components/Authentication/Signup/Signup";
import SetPassword from "../components/Authentication/SetPassword/SetPassword";
import ForgotPassword from "../components/Authentication/ForgotPassword/ForgotPassword";
import Layout from "../layout/Layout";
import ErrorPage from "../blocks/ErrorPage/ErrorPage";
import Page404 from "../blocks/ErrorPage/Page404";
import Page500 from "../blocks/ErrorPage/Page500";
import Page403 from "../blocks/ErrorPage/Page403";
import PaymentCards from "../components/Authentication/Payment/PaymentCards";
import { Widgets } from "../Widgets";
import WidgetURLGenerator from "../components/WidgetURLGenerator";
import ImageUpload from "../Widgets/avv/ImageUpload";
import OverLimit from "../components/Report/OverLimit";
import DailySummary from "../../src/Widgets/reportMonitor/DailySummary";
import InteractiveMap from "../../src/Widgets/interactiveMap/InteractiveMap";
import National from "../Widgets/national/National";
import ValPal from "../components/Report/Property/ValPal";
import AccountExpirationPopup from "./AccountExpirationPopup";
import VersionChecker from "./VersionChecker";


function useTrackPageViews() {
  let location = useLocation();

  useEffect(() => {
    // Ensure window.gtag is defined
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-1YBLK2EHX0', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
}

function PageViewTracker() {
  useTrackPageViews();
  return null; // This component doesn't need to render anything
}

function ProjectRoutes() {
  useEffect(() => {
    let urlHash = window.location.hash;
    // extract params from url
    let params = urlHash.split("/");
    // check second item in array is "widget"
    if (params[1] === "widget") {
      // check if length is more than 7
      if (params.length > 7) {
        let token = params[params.length - 1];
        let widgetName = params[params.length - 2];
        let code = params[params.length - 3];
        let propType = params[params.length - 4];
        let reportName = params.slice(2, params.length - 4).join("/");
        reportName = encodeURIComponent(reportName);
        window.location.href = `#/widget/${reportName}/${propType}/${code}/${widgetName}/${token}`;
      }
    }
  }, []);
  return (
    <Fragment>
      <AccountExpirationPopup />
      <VersionChecker />
      <HashRouter>
      <PageViewTracker />
        <Routes>
          <Route path="/generateurl" element={<WidgetURLGenerator />} />
          <Route path="/setpassword/:token" element={<SetPassword />} />
          <Route
            path="/widget/:reportName/:propType/:code/:widgetName/:token"
            element={<Widgets />}
          />
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/forbidden" element={<Page403 />} />
          <Route path="/pagenotfound" element={<Page404 />} />
          <Route path="/internalservererror" element={<Page500 />} />
          <Route
            path="/report/:reportName/:propType/:code/:token"
            element={
              <Layout>
                <Report />
              </Layout>
            }
          />
          <Route
            element={
              <Layout>
                <PrivateRoute />
              </Layout>
            }
          >
            <Route path="/map" element={<InteractiveMap />} />
            <Route path="/national" element={<National />} />
            <Route
              path="/limit/report/:reportName/:propType/:code"
              element={<OverLimit />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/myreports" element={<MyReports />} />
            <Route strict path="/payment" element={<PaymentCards />} />
            <Route path="/search" element={<WelcomeCard />} />
            <Route path="/admin-report" element={<DailySummary />} />
            <Route
              path="/valpal/:reportName/:propType/:code"
              element={<ValPal />}
            />
            <Route
              path="/report/:reportName/:propType/:code"
              element={<Report />}
            />
            <Route path="/avv/:reportName/:code" element={<ImageUpload />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
    </Fragment>
  );
}

export default ProjectRoutes;
