import React, { useEffect, useState } from "react";
import { Skeleton, Row, Col, Typography, Empty } from "antd";
import CustomCard from "../../../blocks/Card/Card";
import { getSubscriptionPlans } from "../../../services/payment.services";

const { Text, Paragraph } = Typography;

function PlanOptions({ selectedPlan, setSelectedPlan }) {
  const [plans, setPlans] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);

  useEffect(() => {
    setPlanLoading(true);
    getSubscriptionPlans()
      .then((res) => {
        setPlans(res?.data);
        setPlanLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPlanLoading(false);
      });
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} className="srow">
        <Skeleton loading={planLoading} active>
          {plans && plans.length ? (
            plans.map((item, ind) => (
              <Col key={ind} span={6}>
                <CustomCard
                  title={item.name}
                  extra={
                    <Text
                      className={
                        "card-circle" +
                        (item.plan_id === selectedPlan?.id
                          ? " selected-plan-circle"
                          : "")
                      }
                    ></Text>
                  }
                  className={
                    "s8-card" +
                    (item.plan_id === selectedPlan?.id
                      ? " selected-plan-border"
                      : "")
                  }
                  onClick={() =>
                    setSelectedPlan({
                      id: item.plan_id,
                      amount: item.amount,
                    })
                  }
                >
                  <Paragraph style={{ textAlign: "center" }}>
                    {item.description}
                  </Paragraph>
                  {item.house_reports_count && item.house_reports_count > 0 && (
                    <Paragraph style={{ textAlign: "center" }}>
                      Property Reports: {item.house_reports_count}
                    </Paragraph>
                  )}
                  {item.zip_reports_count && item.zip_reports_count > 0 && (
                    <Paragraph style={{ textAlign: "center" }}>
                      ZIP Reports: {item.zip_reports_count}
                    </Paragraph>
                  )}
                  {item.metro_reports_count && item.metro_reports_count > 0 && (
                    <Paragraph style={{ textAlign: "center" }}>
                      Metro Reports: {item.metro_reports_count}
                    </Paragraph>
                  )}
                  <Paragraph
                    strong
                    style={{ textAlign: "center" }}
                  >{`$ ${item.amount} / ${item.validity} days`}</Paragraph>
                </CustomCard>
              </Col>
            ))
          ) : (
            <Empty description="No Plans" />
          )}
        </Skeleton>
      </Row>
    </>
  );
}

export default PlanOptions;
