import axios from "axios";
import { clear, getItem, setItem } from "../../helpers/localStorage";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../constants/defaultKeys";
import { getTokenIfNotExpired } from "../../helpers/utility";
import { message } from "antd";

class WidgetError extends Error {
  constructor(message, flag) {
    super(message);
    this.name = 'WidgetError';
    this.flag = flag;
    // Capture the stack trace for debugging
    Error.captureStackTrace(this, WidgetError);
  }
}

class OverLimitError extends Error {
  constructor(message, flag) {
    super(message);
    this.name = 'OverLimitError';
    this.flag = flag;
    // Capture the stack trace for debugging
    Error.captureStackTrace(this, OverLimitError);
  }

}

const axiosConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use((config) => {
  const token = getTokenIfNotExpired();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // This block gets called on successful responses.
    // You can add any logic you need here.

    let isWidgetError = response?.data?.widget_error === true;
    if (isWidgetError) {
      // Creating a custom error to be thrown
      const widgetError = new WidgetError('A Widget Error has been detected. The widget may not have the required data. Administrators have been notified and are looking into the issue.', "WidgetError");
      //const widgetError = new Error('A Widget Error has been detected. The widget may not have the required data. Administrators have been notified and are looking into the issue.');
      widgetError.response = response; // Attach the response to the error if you want
      // Throwing the custom error to be handled in the error block
      throw widgetError;
    }
    let isOverLimit = response?.data?.overlimit === true;
    if (isOverLimit) {
      // Creating a custom error to be thrown
      const overLimitError = new OverLimitError('You have reached the limit for this action. Please try again later.', "OverLimitError");
      //const widgetError = new Error('A Widget Error has been detected. The widget may not have the required data. Administrators have been notified and are looking into the issue.');
      overLimitError.response = response; // Attach the response to the error if you want
      // Throwing the custom error to be handled in the error block
      let report = response?.data?.report;
      window.location.href = `/#/limit/${report}`;
      throw overLimitError;
    }
    
    // Make sure to return the response, so that the requesting code gets it
    return response;
  },
  async (error) => {
    const refreshTokenApi = "/accounts/refresh-token/";
    const originalRequest = error?.config;
    const errorMessage = error?.response?.data?.code;
    const errorStatusCode = error?.response?.status;
    const tokenInvalid = "token_not_valid";
    const invalidCred = "invalid_credentials";
    const accountNotFound = "user_not_found";

    // Prevent infinite loops
    if (errorStatusCode === 401 && originalRequest.url === refreshTokenApi) {
      clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    //Invalid credentials or user not exist
    if (
      (errorMessage === invalidCred || errorMessage === accountNotFound) &&
      errorStatusCode === 401
    ) {
      clear();
      window.location.href = "/";
    }
    if (errorStatusCode === 403) {
      window.location.href = "/#/forbidden";
    }
    // if (errorStatusCode === 404) {
    //   window.location.href = "/#/pagenotfound";
    // }
    // if (errorStatusCode === 500) {
    //   window.location.href = "/#/internalservererror";
    // }

    //triggers when user session is expired
    if (error.response.data.code === tokenInvalid && errorStatusCode === 401) {
      const refreshToken = getItem(REFRESH_TOKEN);
      if (refreshToken) {
        const regex = new RegExp(
          "^[A-Za-z0-9-_=]+.[A-Za-z0-9-_=]+.?[A-Za-z0-9-_.+/=]*$"
        );

        if (regex.test(refreshToken)) {
          const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);

          //triggers if refresh token is not expired
          if (tokenParts.exp > now) {
            return axiosInstance
              .post(refreshTokenApi, { refresh: refreshToken })
              .then((response) => {
                setItem(ACCESS_TOKEN, response.data.access);
                return axiosInstance(originalRequest);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            clear();
            window.location.href = "/";
            message.error(
              "Your session has been expired, please login again",
              8
            );
          }
        } else {
          clear();
          window.location.href = "/";
        }
      } else {
        clear();
        window.location.href = "/";
        message.error("Your session has been expired, please login again", 8);
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
