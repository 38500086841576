import React from "react";
import { Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { changePassword } from "../../../services/auth.services";
import { openNotificationWithIcon } from "../../../helpers/utility";

const ChangePassword = () => {
  const [form] = Form.useForm();

  const onError = (error) => {
    openNotificationWithIcon("error", error.response.data.message);
  };

  const onSuccess = (res) => {
    openNotificationWithIcon("success", res?.data.message);
  };

  const { mutate, isLoading } = useMutation(changePassword, {
    onError,
    onSuccess,
  });

  const onFinish = (values) => {
    mutate(values);
    form.resetFields();
  };
  return (
    <Form
      layout="vertical"
      name="setpassword"
      onFinish={onFinish}
      form={form}
      requiredMark={false}
    >
      <Form.Item
        label="Your old password"
        name="old_password"
        rules={[
          {
            required: true,
            message: "Please input your old password",
          },
        ]}
      >
        <Input.Password
          autoFocus={true}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
        />
      </Form.Item>
      <Form.Item
        name="new_password"
        label="New password"
        rules={[
          {
            required: true,
            message:
              "Use 8 or more characters with a mix of letters, numbers & symbols.",
            pattern: new RegExp(
              "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            ),
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
        />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label="Confirm New Password"
        rules={[
          { required: true, message: "Please input your Password!" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("new_password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The new passwords that you entered do not match!"
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          block
          className="login-form-button"
          loading={isLoading}
        >
          {isLoading ? "Changing your password..." : "Change password"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePassword;
