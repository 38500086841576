import { Fragment, useState } from "react";
import { Button, Col, Row, List, Typography, Drawer } from "antd";
import { RightOutlined, CloseOutlined } from "@ant-design/icons";
import CustomCard from "../../blocks/Card/Card";
import { clear, getItem } from "../../helpers/localStorage";
import { USER } from "../../constants/defaultKeys";
import { useNavigate } from "react-router-dom";
import BasicDetails from "./BasicDetails/BasicDetails";
import ChangePassword from "./ChangePassword/ChangePassword";
// import Collections from "./Collections/Collections";
import Customizations from "./Customizations/Customizations";
import "./Dashboard.css";

const { Title } = Typography;

function MobileDashboard() {
  const userDetails = JSON.parse(getItem(USER));
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleClick = () => {
    clear();
    navigate("/");
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const menuItems = [
    { key: "1", title: "Basic Details", component: <BasicDetails /> },
    { key: "2", title: "Change Password", component: <ChangePassword /> },
    // { key: "3", title: "Collections", component: <Collections /> },
    { key: "4", title: "Customizations", component: <Customizations /> },
  ];

  const renderDrawerContent = () => {
    return <div style={{ marginTop: "20px" }}>{activeItem?.component}</div>;
  };

  const drawerTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{activeItem?.title}</span>
      <Button
        icon={<CloseOutlined />}
        onClick={closeDrawer}
        type="text"
        style={{ fontSize: "16px" }}
      />
    </div>
  );

  return (
    <Fragment>
      <Row>
        <Col span={24} style={{ paddingBottom: 20 }}>
          <CustomCard
            style={{
              background: "linear-gradient(135deg, #f7fcf5 20%, #f9fbf4 80%)",
              shadow: "none",
            }}
          >
            <Row gutter={[12, 12]} align="middle">
              <Col span={16}>
                <Title level={4} className="user-name">
                  {userDetails?.first_name
                    ? userDetails.first_name
                    : userDetails?.email}
                </Title>
              </Col>
              <Col span={8} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => handleClick()}
                >
                  Logout
                </Button>
              </Col>
            </Row>
          </CustomCard>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomCard style={{ marginBottom: "20px" }}>
            <List
              itemLayout="horizontal"
              dataSource={menuItems}
              renderItem={(item) => (
                <List.Item
                  onClick={() => handleItemClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <List.Item.Meta title={item.title} />
                  <RightOutlined />
                </List.Item>
              )}
            />
          </CustomCard>
        </Col>
      </Row>
      <Drawer
        title={drawerTitle}
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        width="100%"
      >
        {renderDrawerContent()}
      </Drawer>
    </Fragment>
  );
}

export default MobileDashboard;
