import ErrorTag from "./Error";
import Loading from "./Loading";
import ErrorBoundary from "./ErrorBoundary";
import Map from "./Maps";
import { isMobileOnly } from "react-device-detect";

function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

const showInfo = (val) => {
  if (isMobileOnly || !val || val === null) {
    return false;
  }
  return val === "true";
};

function hex_to_kml_color(hex_color, opacity = 255) {
  // Remove '#' if it's included in the input
  hex_color = hex_color.replace("#", "");

  // Convert HEX color to RGB
  let r = parseInt(hex_color.substring(0, 2), 16);
  let g = parseInt(hex_color.substring(2, 4), 16);
  let b = parseInt(hex_color.substring(4, 6), 16);

  // Convert RGB to KML color
  let kml_color =
    opacity.toString(16).padStart(2, "0") +
    b.toString(16).padStart(2, "0") +
    g.toString(16).padStart(2, "0") +
    r.toString(16).padStart(2, "0");

  return kml_color;
}

const styles = [
  "#a50026", // - 5
  "#d73027", // - 4
  "#f46d43", // - 3
  "#fdae61", // - 2
  "#fee08b", // - 1
  "#cfef8b", // 0
  "#d9ef8b", // 1
  "#a6d96a", // 2
  "#66bd63", // 3
  "#1a9850", // 4
  "#006837", // 5
];

export function getMapColor(value) {
  if (!value) {
    return styles[5];
  }
  try {
    value = parseFloat(value)
    let is_positive = value > 0;
    value = Math.ceil(value*1000);
    if (value === 0) {
      return styles[5];
    }

    if (value >= 5) {
      value = 5;
    } else if (value <= -5) {
      value = -5;
    }

    if (value === 0 && is_positive) {
      value = 5;
    } else if (value === 0 && !is_positive) {
      value = -5;
    }

    return hex_to_kml_color(styles[5 + value]);
  } catch (e) {
    return styles[5];
  }
}



export function getColorForValue(value) {
  if (value <= -5) {
    return styles[0];
  } else if (value >= 5) {
    return styles[10];
  } else {
    const index = Math.round(value) + 5;
    return styles[index];
  }
}

export function arraysContentsEqual(a, b) {
  if (a?.length !== b?.length) {
    return false;
  }
  for (let i = 0; i < a?.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}


export { ErrorTag, Loading, ErrorBoundary, Map, objectToQueryString, showInfo };
