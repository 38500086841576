import React, {useEffect} from 'react';
import { Tabs, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import CustomCard from '../../blocks/Card/Card';
import AggregateMetricsVisualization from './AggregateMetricsVisualization';


const { TabPane } = Tabs;

const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    if (!userFromLocalStorage) {
      navigate("/");
    } else if (userFromLocalStorage && !userFromLocalStorage.is_staff) {
      navigate("/");
    }
  })
  const dataSourceAnalysisData = [
    {User: "User1", key: "1", DateCalled: "2024-03-21", FullAddress: "106 OVERHILL AVE PHILADELPHIA PA", ReasonForFailure: "Address not found" },
    {User: "User2", key: "2", DateCalled: "2024-03-21", FullAddress: "19 ALISON CT MARLBORO NJ", ReasonForFailure: "Unsupported Property Type" },
    {User: "User3", key: "3", DateCalled: "2024-03-21", FullAddress: "1615 BORBECK AVE PHILADELPHIA PA", ReasonForFailure: "Malformed Address" },
  ];

  const dataSourceAnalysisColumns = [
    {
      title: "User",
      dataIndex: "User",
      key: "User",
      sorter: (a, b) => a.User.localeCompare(b.User),
    },
    {
      title: "Date",
      dataIndex: "DateCalled",
      key: "DateCalled",
    },
    {
      title: "Full Address",
      dataIndex: "FullAddress",
      key: "FullAddress",
    },
    {
      title: "Reason for Failure",
      dataIndex: "ReasonForFailure",
      key: "ReasonForFailure",
    },
  ]
  
  return (
    <div style={{ width: "80%", margin: "auto", marginTop: "100px" }}>
      <CustomCard>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Aggregate Metrics" key="1">
            <AggregateMetricsVisualization />
          </TabPane>
          <TabPane tab="Data Source Analysis" key="2">
            <Tabs tabPosition="left">
              <TabPane tab="PRODUCTION API" key="1">
                <Table columns={dataSourceAnalysisColumns} dataSource={dataSourceAnalysisData} />
              </TabPane>
              <TabPane tab="WEBSITE" key="2">
                <Table columns={dataSourceAnalysisColumns} dataSource={dataSourceAnalysisData} />
              </TabPane>
              <TabPane tab="GlobalDMS" key="3">
                <Table columns={dataSourceAnalysisColumns} dataSource={dataSourceAnalysisData} />
              </TabPane>
              <TabPane tab="AVV API" key="4">
                <Table columns={dataSourceAnalysisColumns} dataSource={dataSourceAnalysisData} />
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>
      </CustomCard>
    </div>
  );
};

export default Dashboard;
