import React, { Fragment } from "react";
import Avatar from "react-avatar";
import { colors } from "../../constants/defaultKeys";
import { useNavigate } from "react-router-dom";

const UserAvatar = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Avatar
        style={{ marginLeft: "5px" }}
        className="menu-avatar"
        color={user && colors[user.user_id % 24]}
        round={true}
        name={user && user.first_name ? user.first_name : "U"}
        size="40px"
        onClick={() => navigate("dashboard")}
        key="dashboard"
      />
    </Fragment>
  );
};

export default UserAvatar;
