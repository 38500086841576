import React from "react";
import { Form, Input, Button, message } from "antd";
import { useMutation } from "react-query";
import { editProfile } from "../../../services/auth.services";
import { getItem, setItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
import { openNotificationWithIcon } from "../../../helpers/utility";

var formValues;
const BasicDetails = () => {
  const [form] = Form.useForm();
  const userDetails = JSON.parse(getItem(USER));

  const onError = (err) => {
    message.error(err);
  };

  const onSuccess = (res) => {
    const userDetails = JSON.parse(getItem(USER));
    userDetails.first_name = formValues.first_name;
    userDetails.last_name = formValues.last_name;
    userDetails.email = formValues.email;
    setItem(USER, JSON.stringify(userDetails));
    openNotificationWithIcon("success", res?.data.message);
  };
  const { mutate, isLoading } = useMutation(editProfile, {
    onError,
    onSuccess,
  });

  const onFinish = (values) => {
    formValues = values;
    mutate(values);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      initialValues={{
        first_name: userDetails?.first_name,
        last_name: userDetails?.last_name,
        email: userDetails?.email,
      }}
      // requiredMark={false}
    >
      <Form.Item
        name="first_name"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please input your first name",
          },
        ]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[
          {
            required: true,
            message: "Please input your last name",
          },
        ]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input your email",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          block
          loading={isLoading}
        >
          {isLoading ? "Updating your details..." : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BasicDetails;
