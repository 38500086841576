import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Typography, Row, Col } from "antd";
import Vimeo from "@u-wave/react-vimeo";
import CustomCard from "../../blocks/Card/Card";
import { Loading, ErrorBoundary } from "../shared";
import { useDynamicMap } from "./useDynamicMap";
import { isMobileOnly } from "react-device-detect";
import { dynamicMetroInfo } from "../../constants/defaultKeys";


const { Paragraph } = Typography;

function DynamicMap() {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(610);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useDynamicMap(filters, { enabled: params.reportName !== null });

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                let newHeight = elementRef.current.clientHeight;
                if (newHeight < 610) {
                    newHeight = 610;
                }
                setElementHeight(newHeight);
                window.parent.postMessage({ dynamicMetroMapHeight: newHeight }, "*");
            }
        };
        if (elementHeight < 610) {
            setElementHeight(610);
        }
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }
    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }
    if (elementHeight > 0) {
        window.parent.postMessage({ dynamicMetroMapHeight: elementHeight }, "*");
    }

    if (isLoading) {
        return <Loading title="Dynamic Metro Map" />
    }


    let showText = () => {
        if (searchParams?.get("text")) {
            if (["true", "1", "yes", "y"].includes(searchParams?.get("text"))) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    return (
        <ErrorBoundary>
            <CustomCard
                ref={elementRef}
                className="title-2 mb-2"
                title={showText() && isMobileOnly ? dynamicMetroInfo() : "Dynamic Metro Map"}
            >
                <Row type="flex">
                    <Col
                        lg={{ span: 11 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                    >
                        {showText() && !isMobileOnly && (
                            <Paragraph style={{ lineHeight: "25px", fontSize: isMobileOnly ? "13px" : "16px" }}>
                                Weiss MapsTM dynamically illustrate historic and predicted value
                                trends at a ZIP Code or metro level for a historical period of ten
                                years and a one year forecast. Weiss Maps reveal and forewarn of
                                never before seen trends in markets, often showing waves of value
                                changes moving across a geographic area like a weather event. Each
                                dot represents a home, and its color indicates whether the home is
                                appreciating or depreciating at the time indicated on the map (based
                                on the Weiss Index) with green indicating appreciation and red
                                indicating depreciation according to the color scale on the map.
                                Click the play button to watch trends unfold over time and into the
                                future.
                            </Paragraph>
                        )}
                    </Col>
                    <Col
                        lg={{ offset: 1, span: 11 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                    >
                        <Vimeo
                            video={data?.video}
                            controls={true}
                            responsive={true}
                            keyboard={true}
                            pip={true}
                        />
                    </Col>
                </Row>
            </CustomCard>
        </ErrorBoundary>
    );
}

export default DynamicMap;
