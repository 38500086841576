import React, { useRef, useEffect } from 'react';
import './barChart.css'
import { isMobileOnly } from 'react-device-detect';

const QualityScoreBarChart = ({chartData, Chart}) => {
    const chartRef = useRef(null); 

    const getTickColor = (context) => {
        if (context?.index === 0 || context?.index === 1) {
            return '#b0403e';
        } else if (context?.index === 2 || context?.index === 3) {
            return '#c8a007';
        } else if (context?.index === 4 || context?.index === 5) {
            return '#547c77'
        } else {
            return '#21262b';
        }
    }
    const getBackgroundColor = (value) => {
        if (value >= 5) {
            return '#b0403e'; // Red
        } else if (value >= 3) {
            return '#c8a007'; // Orange
        } else if (value >= 1) {
            return '#547c77'; // Blue
        } else {
            return '#547c77'; // Default color if outside of known ranges
        }
    };

    const { ImageGroupQuality, ...conditionData } = chartData;
    const backgroundColors = Object.values(conditionData).map(getBackgroundColor);
    const maxScore = 7;

    useEffect(() => {
        const customDataLabelsPlugin = {
            id: 'customDataLabels',
            afterDatasetsDraw: function(chart, easing) {
                const ctx = chart.ctx;
                chart.data.datasets.forEach((dataset, i) => {
                    const meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach((element, index) => {
                            ctx.fillStyle = '#fff'; // Text color
                            const fontSize = isMobileOnly ? 10 : 14; // Adjust based on your isMobileOnly flag
                            const fontStyle = 'bold';
                            const fontFamily = '"Roboto Condensed", sans-serif'; // Adjust as needed
                            // Calculate the data label
                            const dataString = `Q${(maxScore - dataset.data[index]).toFixed(2)}`;
                            
                            // Determine text position
                            const textPosition = element.tooltipPosition();
                            let textX = textPosition.x;
                            let textY = textPosition.y;
                            const offset = isMobileOnly ? -33 : -50; // Adjust based on your isMobileOnly flag
        
                            // Adjust text alignment and position
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`;
        
                            // Draw text
                            if (chart.config.options.indexAxis === 'y') {
                                ctx.fillText(dataString, textX + offset, textY); // For horizontal bars, adjust position as needed
                            } else {
                                ctx.fillText(dataString, textX, textY + offset); // For vertical bars, adjust position as needed
                            }
                        });
                    }
                });
            }
        };

        let qualityChart = null;

        if (chartRef && chartRef.current && chartData) {
            const ctxQuality = chartRef.current.getContext('2d');
            qualityChart = new Chart(ctxQuality, {
                type: 'bar',
                data: {
                    labels: Object.keys(conditionData),
                    datasets: [{
                        label: 'Quality Score',
                        data: Object.values(conditionData).map((value) => maxScore - value),
                        backgroundColor: backgroundColors,
                        borderWidth: 1,
                        borderRadius: 8,
                        borderSkipped: false,
                        inflateAmount: 4,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    layout: {
                        padding: 4,
                    },
                    scales: {
                        x: {
                            min: 1,
                            max: 6,
                            ticks: {
                                stepSize: 1,
                                display: true, 
                                callback: function(value, index, values) {
                                    if (index === 0) {
                                        return `${Math.abs(value - maxScore)} ${'▲'} Basic `.split(" ")
                                    } else if (index === 5) {
                                        return `${Math.abs(value - maxScore)} ${'▲'} Luxury `.split(" ")
                                    } else {
                                        return Math.abs(value - maxScore).toFixed(0);
                                    }
                                }, 
                                color: (context) => getTickColor(context),
                                font: (context) => {
                                    if (context?.index === 0 || context?.index === 5) {
                                        return {
                                            weight: 'bold',
                                            lineHeight: 2,
                                            size: isMobileOnly ? 10 : 14
                                        }
                                    } else {
                                        return {
                                            weight: '550',
                                            lineHeight: 1.5,
                                        }
                                    }
                                },
                            },
                            grid: {
                                display: true
                            }
                        },
                        y: {
                            grid: {
                                display: true
                            },
                            ticks: {
                                color: "#21262b",
                                font: {
                                    weight: '500',
                                    lineHeight: 1.5,
                                    size: isMobileOnly ? 10 : 14
                                },
                            }
                        },
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function (context) {
                                    return context[0]?.dataset?.label || '';
                                },
                                label: function (context) {
                                    let label = context?.dataset?.label || '';

                                    if (label) {
                                        label += ': ';
                                    }
                                    if (context?.parsed?.x !== null) {
                                        label += (maxScore - context?.parsed?.x).toFixed(2); 
                                    }
                                    return label;
                                }
                            }
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Quality Score',
                            font: {
                                weight: '500',
                                size: isMobileOnly ? 14 : 18
                            },
                            color: '#21262b',
                        },
                    }
                },
                plugins: [customDataLabelsPlugin]
            });
        }
        return () => {
            if (qualityChart) {
                qualityChart.destroy();
            }
        };
    }, [chartData, backgroundColors, conditionData, maxScore, Chart]); 

    return (
        <div className="condition-quality-score-main">
            <div className="condition-quality-card">
                <div className="condition-quality-score-chart">
                    <canvas className="canvas" ref={chartRef}></canvas>
                </div>
            </div>
        </div>
    );
};

export default QualityScoreBarChart;
