import {Button, Popover} from "antd";
import { PlusOutlined, InfoCircleOutlined} from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";


const comparisonInfo = (
    <p style={{ display: "inline-block" }}>
        This button allows you to add another
        <br /> Property, ZIP, or Metro (MSA) Weiss <br />
        Index to the below graph to compare
        <br /> the performance between different <br />
        homes, ZIPs, and Metros. You can add up
        <br /> to eight additional indexes to compare to.
    </p>
);

const ComparisonBtn = ({onClick}) => {
    return (
        <Button
            size="small"
            onClick={onClick}
            icon={<PlusOutlined />}
            type="secondary"
        >
            Comparison
            {!isMobileOnly && (<Popover content={comparisonInfo} style={{ width: "20% !important" }}>
                <InfoCircleOutlined />
            </Popover>
            )}
        </Button>
    );
}

export default ComparisonBtn;