import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import HouseInfoWindow from "./HouseInfoWindow";
import HouseMarker from "./HouseMarker";

const libraries = ["places", "visualization"];

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const options = {
  zoomControl: true,
  fullscreenControl: true,
  streetViewControl: true,
  mapTypeControl: true,
  scaleControl: true,
  rotateControl: true,
  gestureHandling: "greedy",
};

const ForecastedHouses = ({ houses, defaultCenter, address }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBDqhAD8puCskqZSn2xZ1FdIvQl3rwaR-Y",
    libraries,
  });
  const [centeri, setCenteri] = useState(null);
  const [selectedHouse, setSelectedHouse] = useState(null);

  const mapRef = useRef(null);
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleMarkerClick = useCallback((house) => {
    setSelectedHouse(house);
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedHouse(null);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          setCenteri({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  }, [isLoaded, address]);
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const center = defaultCenter ||
    centeri || {
      lat: houses[0]?.lat,
      lng: houses[0]?.lon,
    };

  return (
    <GoogleMap
      mapContainerStyle={{ ...mapContainerStyle, cursor: "grab" }}
      zoom={16}
      center={center}
      options={options}
      onLoad={onMapLoad}
    >
      {houses.map((house) => (
        <HouseMarker
          key={house.property_id}
          house={house}
          onClick={handleMarkerClick}
          isForecast={true}
        />
      ))}
      {centeri && <Marker position={centeri} />}
      {selectedHouse && (
        <HouseInfoWindow
          house={selectedHouse}
          isForecast={true}
          onClose={handleInfoWindowClose}
        />
      )}
    </GoogleMap>
  );
};

export default ForecastedHouses;
