import React, { useState } from 'react';
import { Input, Select, Button, List } from 'antd';


const { Option } = Select;

const PROPERTY_WIDGETS = [
    "houseMeasures",
    "weissInsights",
    "summary",
    "indextool",
    "houseNearBy",
    "valpro",
    "segmentationReport",
    "FixFlip",
];

const METRO_WIDGETS = [
    "summary",
    "indextool",
    "bestWorstSegment",
    "worstSegment",
    "segmentationReport",
    "bubbleWatch",
    "dynamicMetroMap",
    "listScan",
    "video",
];

const ZIP_WIDGETS = [
    "summary",
    "indextool",
    "segmentationReport",
    "dynamicMap",
    "birdsEyeView",
    "listScan",
];

function WidgetURLGenerator() {

    const [address, setAddress] = useState('');
    const [code, setCode] = useState('');
    let [widgetType, setWidgetType] = useState('property');
    const [token, setToken] = useState(''); 
    const [urls, setUrls] = useState([]);

    const generateUrls = () => {
        let widgetArray = [];
        if (widgetType === 'property') {
            widgetArray = PROPERTY_WIDGETS;
        } else if (widgetType === 'metro') {
            widgetArray = METRO_WIDGETS;
        } else if (widgetType === 'zip') {
            widgetArray = ZIP_WIDGETS;
        }
        if (widgetType === 'property') {
            widgetType = "address"
        } else if (widgetType === "zip") {
            widgetType = "zip_code"
        }
        const newUrls = widgetArray.map(widgetName => ({
            url: `https://app2.weissanalytics.com/#/widget/${address}/${widgetType}/${code}/${widgetName}/${token}`,
            encodedUrl: `https://app2.weissanalytics.com/#/widget/${encodeURIComponent(address)}/${widgetType}/${code}/${widgetName}/${token}`,
            widgetName: widgetName,
        }));
        setUrls(newUrls);
    };

    return (
        <div style={{width: "90%", margin: "auto", marginTop: "40px"}}>
            <h2>Widget URL Generator</h2>
            <Input placeholder="Enter Address/Metro Name/Zip" value={address} onChange={e => setAddress(e.target.value)} />
            <Input placeholder="Enter ZIP Code / CBSA Code" value={code} onChange={e => setCode(e.target.value)} />
            <Input placeholder="Enter Token" value={token} onChange={e => setToken(e.target.value)} />
            <Select style={{ width: 200 }} value={widgetType} onChange={value => setWidgetType(value)}>
                <Option value="property">Property</Option>
                <Option value="metro">Metro</Option>
                <Option value="zip">Zip</Option>
            </Select>
            <Button type="primary" onClick={generateUrls}>Generate URLs</Button>

            <List
                dataSource={urls}
                renderItem={item => (
                    <List.Item>
                        <a href={item.url} target="_blank" rel="noopener noreferrer" style={{fontSize: "24px"}}>
                            {item.widgetName} 
                        </a> 
                        {' : '}
                        <p>{item.url}</p>
                        <p>{item.encodedUrl}</p>
                    </List.Item>
                )}
            />
        </div>
    );
}

export default WidgetURLGenerator;
