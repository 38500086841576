import React, { useEffect, useState } from "react";
import {
  Switch,
  Upload,
  message,
  Form,
  Typography,
  Card,
  Space,
  Divider,
  Skeleton,
  Empty,
} from "antd";
import { PictureOutlined } from "@ant-design/icons";
import { useCustomizations } from "./useCustomizations";
import axiosInstance from "../../../config/axios/axios.config";

const { Title, Text, Paragraph } = Typography;

const Customizations = () => {
  const { data, isLoading, isError } = useCustomizations();
  const [fileList, setFileList] = useState([]);
  const [useCustomLogo, setUseCustomLogo] = useState(false);

  useEffect(() => {
    if (data) {
      const userCustomLogoValue =
        data?.user_customization?.customization_values?.find(
          (value) => value.option.name === "Use Custom Logo"
        );
      setUseCustomLogo(userCustomLogoValue?.value);
      const initialLogoUrl = data?.logo_url;
      if (initialLogoUrl) {
        setFileList([
          {
            uid: "-1",
            name: "current-logo.png",
            status: "done",
            url: initialLogoUrl,
          },
        ]);
      }
    }
  }, [data]);

  if (isLoading) {
    return <Skeleton active />;
  }

  if (isError) {
    return <Empty description="Failed to load customizations" />;
  }

  console.log("Data", data);
  const brandingCategory = data?.categories?.find(
    (category) => category.name === "Branding"
  );
  const useCustomLogoOption = brandingCategory?.options?.find(
    (option) => option.name === "Use Custom Logo"
  );

  const handleSwitchChange = async (checked) => {
    setUseCustomLogo(checked);
    try {
      await axiosInstance.post(
        "accounts/customizations/update_customization/",
        {
          [useCustomLogoOption.id]: checked,
        }
      );
      message.success("Customization updated successfully.");
    } catch (error) {
      message.error("Failed to update customization. Please try again.");
      setUseCustomLogo(!checked);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    // Check file size
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      onError(new Error("Image must be smaller than 2MB!"));
      return;
    }

    // Check file type
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      onError(new Error("You can only upload JPG/PNG file!"));
      return;
    }

    // Check image dimensions
    const checkDimensions = () => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            if (width !== 200 || height !== 200) {
              message.warning(
                `Image dimensions are ${width}x${height}px. Recommended size is 200x200px.`
              );
            }
            resolve();
          };
        };
      });
    };

    try {
      // Check dimensions before uploading
      await checkDimensions();

      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("file", file);

      // Send the file to the server
      const response = await axiosInstance.post(
        "accounts/customizations/upload_logo/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Extract the logo URL from the response
      const logoUrl = response.data.logo_url;

      // Call onSuccess with the logo URL
      onSuccess(logoUrl);

      // Show success message
      message.success(`${file.name} file uploaded successfully.`);
      setFileList([
        { uid: "-1", name: file.name, status: "done", url: logoUrl },
      ]);
    } catch (error) {
      console.error("Failed to upload logo:", error);
      onError(error);
      message.error("Failed to upload logo. Please try again.");
    }
  };

  const handleChange = (info) => {
    setFileList(info.fileList);
  };

  return (
    <Card
      style={{
        margin: "0 auto",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
      cover={
        <div
          style={{
            background: "linear-gradient(120deg, #84fab0 0%, #97c23c 100%)",
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Title level={2} style={{ color: "white", margin: 0 }}>
            Customizations
          </Title>
        </div>
      }
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div>
          <Title level={4}>{brandingCategory?.name}</Title>
          <Paragraph type="secondary">
            {brandingCategory?.description}
          </Paragraph>
          <Divider />
        </div>

        <Form layout="vertical">
          <Form.Item>
            <Space align="center">
              <Switch checked={useCustomLogo} onChange={handleSwitchChange} />
              <Text strong>{useCustomLogoOption?.name}</Text>
            </Space>
          </Form.Item>

          {useCustomLogo && (
            <Form.Item>
              <Upload
                customRequest={handleUpload}
                onChange={handleChange}
                listType="picture"
                fileList={fileList}
                maxCount={1}
              >
                <Card hoverable style={{ width: 200, textAlign: "center" }}>
                  <PictureOutlined style={{ fontSize: 32, color: "#1890ff" }} />
                  <Paragraph style={{ marginTop: 16 }}>
                    Click or drag file to upload / update
                  </Paragraph>
                </Card>
              </Upload>
              <Text type="secondary" style={{ display: "block", marginTop: 8 }}>
                Recommended size: 200x200px. Max file size: 2MB.
              </Text>
            </Form.Item>
          )}
        </Form>
      </Space>
      <Divider />
    </Card>
  );
};

export default Customizations;
