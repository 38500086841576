import React, { useState, useMemo } from "react";
import {
  Popover,
  Button,
  Slider,
  Row,
  Col,
  Typography,
  Badge,
  Tooltip,
  Select,
} from "antd";
import { DownOutlined, FilterOutlined, FilterFilled } from "@ant-design/icons";
import MapKey from "../../assets/images/map-key.png";
import { isMobileOnly } from "react-device-detect";

const { Title } = Typography;
const { Option } = Select;

const BedBathFilter = ({
  filters,
  setFilters,
  mapContainerRef,
  fullscreenHandle,
}) => {
  const [bedVisible, setBedVisible] = useState(false);
  const [sqftVisible, setSqftVisible] = useState(false);
  const [yearVisible, setYearVisible] = useState(false);

  const generateYears = useMemo(() => {
    const startYear = 1900;
    const endYear = new Date().getFullYear();
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, index) => startYear + index
    );
  }, []);

  const handleStartYearChange = (value) => {
    setFilters?.setYearRange([value, filters.yearRange[1]]);
  };

  const handleEndYearChange = (value) => {
    setFilters?.setYearRange([filters.yearRange[0], value]);
  };

  const areFiltersSet = useMemo(() => {
    return (
      filters.bedValue[0] !== 0 ||
      filters.bedValue[1] !== 5 ||
      filters.bathValue[0] !== 0 ||
      filters.bathValue[1] !== 4 ||
      filters.sqftMinValue !== 0 ||
      filters.sqftMaxValue !== 10000 ||
      filters.yearRange.length > 0
    );
  }, [filters]);

  const whichFiltersAreSet = useMemo(() => {
    const filtersSet = [];
    if (filters.bedValue[0] !== 0 || filters.bedValue[1] !== 5) {
      filtersSet.push(`Bed: ${filters.bedValue[0]}-${filters.bedValue[1]}`);
    }
    if (filters.bathValue[0] !== 0 || filters.bathValue[1] !== 4) {
      filtersSet.push(`Bath: ${filters.bathValue[0]}-${filters.bathValue[1]}`);
    }
    if (filters.sqftMinValue !== 0 || filters.sqftMaxValue !== 10000) {
      filtersSet.push(`SQFT: ${filters.sqftMinValue}-${filters.sqftMaxValue}`);
    }
    if (filters.yearRange.length > 0) {
      filtersSet.push(`Year: ${filters.yearRange[0]}-${filters.yearRange[1]}`);
    }
    const filterString =
      filtersSet.length > 1
        ? `${filtersSet.slice(0, -1).join(", ")} and ${
            filtersSet[filtersSet.length - 1]
          }`
        : filtersSet[0];
    return { filtersSet, filterString };
  }, [filters]);

  const handleBedVisibleChange = (visible) => {
    setBedVisible(visible);
  };

  const handleSqftVisibleChange = (visible) => {
    setSqftVisible(visible);
  };

  const handleYearVisibleChange = (visible) => {
    setYearVisible(visible);
  };

  const handleBedChange = (value) => {
    setFilters?.setBedValue(value);
  };

  const handleBathChange = (value) => {
    setFilters?.setBathValue(value);
  };

  const handleSqftChange = (value) => {
    setFilters?.setSqftMinValue(value[0]);
    setFilters?.setSqftMaxValue(value[1]);
  };

  const resetFilters = () => {
    setFilters?.setBedValue([0, 5]);
    setFilters?.setBathValue([0, 4]);
    setFilters?.setSqftMinValue(0);
    setFilters?.setSqftMaxValue(10000);
    setFilters?.setYearRange([1900, new Date().getFullYear()]);
  };

  const handleResetFiltersButton = () => {
    if (areFiltersSet) {
      resetFilters();
    }
  };

  const popoverProps = {
    getPopupContainer: () => mapContainerRef.current,
    // overlayStyle: {
    //   zIndex: 9999,
    // },
  };

  const bedMarks = useMemo(
    () => ({
      0: "Any",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5+",
    }),
    []
  );

  const bathMarks = useMemo(
    () => ({
      0: "Any",
      1: "1+",
      2: "1.5+",
      3: "2+",
      4: "2.5+",
      5: "3+",
      6: "4+",
    }),
    []
  );

  const filtersTooltip = areFiltersSet
    ? whichFiltersAreSet.filterString
    : "No filters set";

  const filterButtonStyle = areFiltersSet
    ? { border: "1px solid #7cb305" }
    : { border: "1px solid grey" };

  const filterButtonText = areFiltersSet ? "Reset Filters" : "Filters";

  return (
    <div style={{ marginTop: fullscreenHandle.active ? "50px" : "0px" }}>
      <div>
        <Title level={5}>Interactive Map</Title>
      </div>
      <div className="map-filters-box">
        <div className="map-filters">
          <div style={{ display: "inline-block", marginRight: "20px" }}>
            <Tooltip
              title={filtersTooltip}
              placement={fullscreenHandle.active ? "bottom" : "top"}
              {...popoverProps}
            >
              <Badge
                count={areFiltersSet ? whichFiltersAreSet.filtersSet.length : 0}
                style={{ backgroundColor: "#7cb305" }}
              >
                <Button
                  type="text"
                  icon={
                    areFiltersSet ? (
                      <FilterFilled style={{ color: "#7cb305" }} />
                    ) : (
                      <FilterOutlined />
                    )
                  }
                  style={filterButtonStyle}
                  onClick={handleResetFiltersButton}
                >
                  {filterButtonText}
                </Button>
              </Badge>
            </Tooltip>
          </div>
          <div style={{ display: "inline-block", marginRight: "10px" }}>
            <Popover
              placement="bottom"
              className="map-filter-popover"
              title="Filter results by setting a minimum and/or maximum square footage"
              content={
                <div className="map-filter-popover">
                  <div className="filter-group" style={{minWidth: "350px"}}>
                    <h4>SQFT</h4>
                    <Slider
                      range
                      marks={{ 0: "0", 10000: "10000" }}
                      min={0}
                      max={10000}
                      value={[filters?.sqftMinValue, filters?.sqftMaxValue]}
                      onChange={handleSqftChange}
                      style={{ width: "90%" }}
                    />
                  </div>
                  <div className="filter-buttons">
                    <Button onClick={resetFilters}>Reset</Button>
                    <Button
                      type="primary"
                      onClick={() => setSqftVisible(false)}
                      style={{ marginLeft: "8px" }}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
              open={sqftVisible}
              onOpenChange={handleSqftVisibleChange}
              {...popoverProps}
            >
              <Button type="primary">
                SQFT <DownOutlined />
              </Button>
            </Popover>
          </div>
          <div style={{ display: "inline-block", marginRight: "10px" }}>
            <Popover
              placement="bottom"
              className="map-filter-popover"
              title="Filter results by setting a minimum and/or maximum number of bedrooms and bathrooms"
              content={
                <div className="map-filter-slider">
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className="filter-group">
                        <h4>Beds</h4>
                        <Slider
                          range
                          min={0}
                          max={5}
                          marks={bedMarks}
                          value={filters?.bedValue}
                          onChange={handleBedChange}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="filter-group">
                        <h4>Baths</h4>
                        <Slider
                          range
                          min={0}
                          max={6}
                          marks={bathMarks}
                          value={filters?.bathValue}
                          onChange={handleBathChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="filter-buttons">
                    <Button onClick={resetFilters}>Reset</Button>
                    <Button
                      type="primary"
                      onClick={() => setBedVisible(false)}
                      style={{ marginLeft: "8px" }}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
              open={bedVisible}
              onOpenChange={handleBedVisibleChange}
              {...popoverProps}
            >
              <Button type="primary">
                Bed & Bath <DownOutlined />
              </Button>
            </Popover>
          </div>
          <div style={{ display: "inline-block" }}>
            <Popover
              placement="bottom"
              className="map-filter-popover"
              title="Filter results by setting a date range"
              content={
                <div>
                  <div className="filter-group">
                    <h4>Date Range</h4>
                    <Select
                      placeholder="Start Year"
                      value={filters?.yearRange[0]}
                      style={{ width: 120, marginLeft: "8px" }}
                      onChange={handleStartYearChange}
                      {...popoverProps}
                    >
                      {generateYears.map((year) => (
                        <Option key={year} value={year}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      placeholder="End Year"
                      style={{ width: 120, marginLeft: "8px" }}
                      onChange={handleEndYearChange}
                      value={filters?.yearRange[1]}
                      {...popoverProps}
                    >
                      {generateYears.map((year) => (
                        <Option key={year} value={year}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="filter-buttons">
                    <Button onClick={resetFilters}>Reset</Button>
                    <Button
                      type="primary"
                      onClick={() => setYearVisible(false)}
                      style={{ marginLeft: "8px" }}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
              open={yearVisible}
              onOpenChange={handleYearVisibleChange}
              {...popoverProps}
            >
              <Button type="primary">
                Date Range <DownOutlined />
              </Button>
            </Popover>
          </div>
        </div>
        <div style={{ display: "inline-block", marginLeft: "10px" }}>
          <img
            src={MapKey}
            alt="map key"
            style={{ width: isMobileOnly ? "220px" : "300px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default BedBathFilter;
