
import {Button, Popover} from "antd";
import { PlusOutlined, InfoCircleOutlined} from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";

const content = (
    <p style={{ display: "inline-block" }}>
        This button allows you to add another Metro <br />
        (MSA) level percent rising time series. <br />
        Compare with the Percent of Homes <br /> Rising of a Metro area (MSA).
        <br /> This is defined as the percent of homes with a <br /> Weiss Index
        appreciating at a rate faster than <br /> 1.5% per year during the most
        recent month.
    </p>
);

const PropertyRisingBtn = ({ onClick }) => {
    return (
        <Button size="small" onClick={onClick} icon={<PlusOutlined />} style={{borderRadius: "4px"}}>
            {isMobileOnly ? <span>Properties Rising</span> : "% Properties Rising"}
            {!isMobileOnly && (<Popover content={content} style={{ width: "20%" }}>
                <InfoCircleOutlined />
            </Popover>
            )}
        </Button>
    );
} 

export default PropertyRisingBtn;