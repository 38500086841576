import React from "react";
import { Card, Table } from "antd";

function formatNumberWithCommas(x) {
  try {
    if (x === 0) return "0";
    if (!x) return "-";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (err) {
    return "-";
  }
}

const columns = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    filterSearch: true, // Enables the search box within the filter dropdown
    onFilter: (value, record) =>
      record.company.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.company.localeCompare(b.company),
    render: (text) => text?.toUpperCase(),
  },
  {
    title: "User",
    dataIndex: "username",
    key: "username",
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) =>
      record.username.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.username.localeCompare(b.username),
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    sorter: (a, b) => a.source.localeCompare(b.source),
    render: (text) => text?.toUpperCase(),
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    sorter: (a, b) => (a, b) => a.product.localeCompare(b.product),
    render: (text) => text?.toUpperCase(),
  },
  {
    title: "Total Orders",
    dataIndex: "call_count",
    key: "call_count",
    render: (text) => formatNumberWithCommas(text),
    sorter: (a, b) => a.call_count - b.call_count,
  },
  {
    title: "Success",
    dataIndex: "success_count",
    key: "success_count",
    render: (text) => formatNumberWithCommas(text),
    sorter: (a, b) => a.success_count - b.success_count,
  },
  {
    title: "Failure",
    dataIndex: "failure_count",
    key: "failure_count",
    render: (text) => formatNumberWithCommas(text),
    sorter: (a, b) => a.failure_count - b.failure_count,
  },
  {
    title: "Success Rate (%)",
    key: "success_rate",
    render: (text, record) => {
      const rate =
        record.call_count > 0
          ? ((record.success_count / record.call_count) * 100).toFixed(2)
          : 0;
      return `${rate}%`;
    },
  },
  {
    title: "Failure Rate (%)",
    key: "failure_rate",
    render: (text, record) => {
      const rate =
        record.call_count > 0
          ? ((record.failure_count / record.call_count) * 100).toFixed(2)
          : 0;
      return `${rate}%`;
    },
  },
];

const OrdersByUserTable = ({ data }) => (
  <Card title="Orders by User" bordered={false}>
    <Table
      dataSource={data}
      columns={columns}
      rowKey="username" // Assuming 'username' is unique for each record
    />
  </Card>
);

export default OrdersByUserTable;
