import { Radio } from "antd";
import { isMobileOnly } from "react-device-detect";

const YearsRadioBtn = ({ value, onChange }) => {
    return (
        <Radio.Group
            value={value}
            onChange={onChange}
            buttonStyle="solid"
            size="small"
        >
            <Radio.Button value="1yr" style={{ zIndex: 0 }}>
                {isMobileOnly ? <span style={{ fontSize: "12px" }}>1Yr</span> : "1Yr"}
            </Radio.Button>
            <Radio.Button value="5yr" style={{ zIndex: 0 }}>
                {isMobileOnly ? <span style={{ fontSize: "12px" }}>5Yr</span> : "5Yr"}
            </Radio.Button>
            <Radio.Button value="10yr" style={{ zIndex: 0 }}>
                {isMobileOnly ? <span style={{ fontSize: "12px" }}>10Yr</span> : "10Yr"}
            </Radio.Button>
            <Radio.Button value="max" style={{ zIndex: 0 }}>
                {isMobileOnly ? <span style={{ fontSize: "12px" }}>Max</span> : "Max"}
            </Radio.Button>
        </Radio.Group>
    )
}

export default YearsRadioBtn