import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { isBrowser, isMobileOnly } from "react-device-detect";
import { Row, Col, Typography, Radio, Skeleton } from "antd";
import CustomCard from "../../blocks/Card/Card";
import BubbleWatchBarChart from "./BubbleWatchBarChart";
import { useBubbleWatch } from "./useBubbleWatch";
import { ErrorBoundary } from "../shared";

const { Paragraph } = Typography;

function BubbleWatch() {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(0);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useBubbleWatch(filters, { enabled: params.reportName !== null });

    const [propertyType, setPropertyType] = useState("rsfr");
    const onPropertyTypeSwitch = ({ target: { value } }) => {
        setPropertyType(value);
    };

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 58;
                setElementHeight(newHeight);
                window.parent.postMessage({ bubleWatchHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);


    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }
    if (isLoading) {
        return <Skeleton />
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }
    let currentData = data[propertyType]
    let rsfrHasData = data?.rsfr?.plot_data?.length > 0
    let condosHasData = data?.condos?.plot_data?.length > 0
    return (
        <ErrorBoundary>
            {false && <CustomCard
                ref={elementRef}
                className="title-2"
                title="BubbleWatch"
                extra={(
                    <Radio.Group
                        style={{ textAlign: "center", width: "100%" }}
                        onChange={onPropertyTypeSwitch}
                        value={propertyType}
                        buttonStyle="solid"
                        optionType="button"
                        size={isMobileOnly ? "small" : "default"}
                    >
                        {rsfrHasData && <Radio style={{ fontSize: isMobileOnly ? "12px" : "16px" }} value={"rsfr"}>RSFR</Radio>}
                        {condosHasData && <Radio style={{ fontSize: isMobileOnly ? "12px" : "16px" }} value={"condos"}>Condos</Radio>}
                    </Radio.Group>
                )}
            >
                <Row type="flex" style={{ marginTop: "20px" }}>
                    <Col
                        lg={isBrowser ? { span: 11 } : { span: 22 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        style={{ marginBottom: isMobileOnly ? "20px" : "0px" }}
                    >
                        <Paragraph style={{ lineHeight: "25px", fontSize: isMobileOnly ? "13px" : "16px" }}>
                            {currentData.forecast_text}
                        </Paragraph>
                    </Col>
                    <Col
                        lg={isBrowser ? { offset: 1, span: 11 } : { span: 22 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        xs={{ span: 24 }}
                    >
                        <BubbleWatchBarChart plot_data={currentData?.plot_data} />
                    </Col>
                </Row>
            </CustomCard>
            }
        </ErrorBoundary>
    );
}

export default BubbleWatch;
