import React from 'react';
import axiosInstance from "../../config/axios/axios.config";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    let errorData = {
      level: "3", 
      report: "frontend", 
      info: `Error info: ${errorInfo?.componentStack}. Error: ${error}`
    }
    // Send error information to the backend API
    axiosInstance.post('/monitoring/frontend-errors/', errorData)
      .then(response => {
        console.log('Error information sent to the backend.');
      })
      .catch(error => {
        console.log('Failed to send error information:', error);
      });
  }

  render() {
    if (this.state.hasError) {
      window.parent.postMessage("NO DATA", "*");
      return null
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
