import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Row, Tabs, Skeleton } from "antd";
import CustomCard from "../../blocks/Card/Card";
import { useHouses } from "./useHouses";
import { ErrorBoundary } from "../shared";
import { HousesNearByInfo } from "../../constants/defaultKeys";
import { showInfo } from "../shared";
import { isMobileOnly } from "react-device-detect";
import MapKey from "../../assets/images/map-key.png";
import ForecastedHouses from "./ForecastedHouses";
import LastYearHouses from "./LastYearHouses";

const { TabPane } = Tabs;

const HousesNearByHeatMap = () => {
  const params = useParams();
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const [elementHeight, setElementHeight] = useState(740);
  const elementRef = useRef(null);

  if (location?.pathname?.includes("report")) {
    searchParams = new URLSearchParams({
      ...(searchParams ? searchParams : {}),
      isWebsite: true,
    });
  }
  const filters = {
    ...params,
    queryParams: searchParams,
    zipcode: params?.code,
  };
  const { data, isLoading, isError } = useHouses(filters, {
    enabled: params.reportName !== null,
  });
  const [tabChange, setTabChange] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        const newHeight = elementRef.current.clientHeight + 75;
        setElementHeight(newHeight);
        window.parent.postMessage({ housesNearbyHeight: newHeight }, "*");
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [data, elementHeight, elementRef, tabChange]);

  if (isLoading) {
    return (
      <CustomCard title="Houses Near By" className="title-2">
        <Skeleton />
      </CustomCard>
    );
  }

  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  if (!isLoading && !isError && (!data || data === null)) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  const onTabChange = (val) => {
    setTabChange(val === "forecast" ? true : false);
  };

  if (elementHeight > 0) {
    window.parent.postMessage({ height: elementHeight }, "*");
  }

  let showText = () => {
    if (searchParams?.get("text")) {
      if (["true", "1", "yes", "y"].includes(searchParams?.get("text"))) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  showText();

  return (
    <ErrorBoundary>
      <CustomCard
        title={
          <>
            <span>Houses Nearby</span>
            {showText() && !showInfo(searchParams?.get("showInfo")) && (
              <span>{HousesNearByInfo()}</span>
            )}
          </>
        }
        ref={elementRef}
        className="title-2"
      >
        {showText() && showInfo(searchParams?.get("showInfo")) && (
          <Row gutter={isMobileOnly ? [12, 12] : [36, 36]}>
            <Col span={24}>
              <p>
                The below map displays homes in this ZIP color-coded to reflect
                their respective appreciation or depreciation corresponding to
                the Map Key. By selecting the appropriate tab, you can view the
                home appreciation or depreciation over the Last Year (based on
                the Weiss Index) or One Year Forecast (based on the Weiss
                Forecast). The subject property has a balloon on it to
                distinguish it from other homes nearby. Selecting a home will
                display its features, such as age, size, beds, baths, and
                appreciation rate in the selected period. You can also zoom on
                the map to focus on certain areas within the ZIP, or to take a
                higher level view.
              </p>
            </Col>
          </Row>
        )}
        <Row
          gutter={isMobileOnly ? [12, 12] : [36, 36]}
          style={{
            marginTop: isMobileOnly ? "12px" : "5px",
            marginBottom: isMobileOnly ? "12px" : "5px",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={MapKey}
              alt="map key"
              style={{ width: isMobileOnly ? "220px" : "300px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              defaultActiveKey="last_year"
              onChange={onTabChange}
              style={{ minHeight: "450px", width: "100%", display: "flex" }}
            >
              <TabPane
                tab="Last Year Change"
                key="last_year"
                style={{ height: "450px", width: "100%" }}
              >
                {!tabChange && (
                  <LastYearHouses
                    houses={data?.houses}
                    defaultCenter={null}
                    address={params?.reportName}
                  />
                )}
              </TabPane>
              <TabPane
                tab="One Year Forecast"
                key="forecast"
                style={{ height: "450px" }}
              >
                {tabChange && (
                  <ForecastedHouses
                    houses={data?.houses}
                    defaultCenter={null}
                    address={params?.reportName}
                  />
                )}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </CustomCard>
    </ErrorBoundary>
  );
};

export default HousesNearByHeatMap;
