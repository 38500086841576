import React from 'react';
import ChartWithLabels from './ChartWithLabels';
import "./PieChart.css";

function formatNumberWithCommas(x) {
  try {
    if (!x) return "-";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  catch (err) {
    return x;
  }
}

function splitLabel(label, maxLength) {
  const words = label.split(' ');
  let currentLine = '';
  let newLabel = '';

  words.forEach((word, index) => {
    if ((currentLine + word).length < maxLength) {
      currentLine += `${word} `;
    } else {
      // Trim to remove any extra space at the end and add a line break
      newLabel += `${currentLine.trim()}\n`;
      currentLine = `${word} `;
    }
  });

  // Add the last line without a newline at the end
  newLabel += currentLine.trim();

  return newLabel;
}

const FinalValuationPieChart = ({ piechartData }) => {
  piechartData.sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage));

  const data = {
    labels: piechartData.map(item => item.title),
    datasets: [{
      data: piechartData.map(item => (item.percentage * 100).toFixed(0)), // Using percentages for data
      backgroundColor: [
        '#a2c64b',   // green-6
        '#dde8bf',   // green-4
        '#59acde',   // lime - 4
        '#bcd7ef',    // yellow - 7
        '#e9d959'       // gold 5
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)',
      ],
      borderWidth: 1
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 35,

    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { 
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (context.parsed !== null) {
              return ` ${context.parsed}%`;
            } else {
              return label;
            }
          }
        }
      },
      labels: [
        {
          render: (args) => {
            return `${splitLabel(piechartData[args.index].title, 15)}`; // Render value label
          },
          position: 'outside',
          textMargin: 12
        },
        {
          render: (args) => {
            return `$${formatNumberWithCommas(piechartData[args.index].value)}`; // Render percentage label
          }
        }
      ]
    },
  };

  return (
    <div className='pie-chart'>
      <ChartWithLabels type="pie" data={data} options={options} />
    </div>
  );
};

export default FinalValuationPieChart;
