import { useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./Main.css"
import { useComps } from "./useComps";
import { Loading, ErrorBoundary } from "../shared";
import { Table, Row, Col } from "antd";
import { getColumnSearchProps } from "../../blocks/TableSearch/SearchFunc";
import CustomCard from "../../blocks/Card/Card";
import { isMobileOnly } from "react-device-detect";

function formatDate(dateString) {
    if (dateString.length !== 8) return "Invalid input";

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${month}/${day}/${year}`;
}

function formatNumberWithCommas(x) {
    try {
        if (!x) return "-";
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    catch (err) {
        return "-";
    }
}

function CompsWidget() {
    const tableContainerRef2 = useRef(null);
    const params = useParams();
    const location = useLocation();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    let searchParams = new URLSearchParams(location.search);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useComps(filters, { enabled: params.reportName !== null });

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    if (isLoading) {
        return <Loading title="Comparables" />
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    const onScrollClick2 = () => {
        if (tableContainerRef2.current) {
            // The actual scrollable element might be a child element; adjust selector as needed.
            const scrollableElement = tableContainerRef2.current.querySelector('.ant-table-body');
            if (scrollableElement) {
                scrollableElement.scrollBy({ left: 200, behavior: 'smooth' }); // Adjust as needed
            }
        }
    };

    const columns2 = [
        {
            title: "Comps Used",
            dataIndex: "address",
            key: "address",
            ...getColumnSearchProps(
                "address",
                setSearchText,
                setSearchedColumn,
                searchText,
                searchedColumn
            ),
            fixed: "left",
            className: 'address-column',
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>SqFt | Lot</span>,
            dataIndex: "wrr_sqft",
            key: "combinedSqftAndLot",
            render: (text, record) => (
                <>
                    <span>{formatNumberWithCommas(record?.size) || "-"}</span>
                    <span style={{ margin: '0 10px' }}>|</span>
                    <span>{formatNumberWithCommas(record?.Lot) || "-"}</span>
                </>
            ),
            className: 'centered-column',
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Yr Built</span>,
            dataIndex: "age",
            key: "age",
            className: 'centered-column',
            render: (value) => (value ? value : "-"),
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Bed | Bath</span>,
            dataIndex: "beds",
            key: "combinedData",
            render: (text, record) => (
                <>
                    <span>{record?.beds || "-"}</span>
                    <span style={{ margin: '0 10px' }}>|</span>
                    <span>{record?.baths || "-"}</span>
                </>
            ),
            className: 'centered-column',
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Dist</span>,
            dataIndex: "distance",
            key: "distance",
            render: (value) => (value ? `${formatNumberWithCommas((value).toFixed(2))} miles` : "-"),
            className: 'centered-column',
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Pool</span>,
            dataIndex: "poolcode",
            key: "poolcode",
            render: (value) => (value ? "Yes" : "No"),
            className: 'centered-column',
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>First Sale Date</span>,
            dataIndex: "TransferDate",
            key: "TransferDate",
            render: (value) => `${value !== "-" ? formatDate(value.toString()) : value}`,
            className: 'centered-column',
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>First Sale Price</span>,
            dataIndex: "TransferValue",
            key: "TransferValue",
            render: (value) => (value ? `$${formatNumberWithCommas(value)}` : "-"),
            className: 'centered-column',
        },
        {
            title: <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Implying Value</span>,
            dataIndex: "implyingValue",
            key: "implyingValue",
            render: (value) => (value ? `$${formatNumberWithCommas(value.toFixed())}` : "-"),
            className: 'centered-column',
        },
    ];
    
    let subject_house = {
        address: data?.subject_comp?.address || "-",
        size: data?.subject_comp?.size || "-",
        Lot: data?.subject_comp?.Lot || "-",
        age: data?.subject_comp?.age || "-",
        beds: data?.subject_comp?.beds || "-",
        baths: data?.subject_comp?.baths || "-",
        distance: data?.subject_comp?.distance ? data?.subject_comp?.distance.toFixed(0) : "0",
        TransferDate: data?.subject_comp?.TransferDate ? formatDate(data?.subject_comp?.TransferDate.toString()) : "-",
        TransferValue: data?.subject_comp?.TransferValue ? `$${formatNumberWithCommas(data?.subject_comp?.TransferValue.toFixed())}` : "-",
        implyingValue: data?.subject_comp?.implyingValue ? `$${formatNumberWithCommas(data?.subject_comp?.implyingValue.toFixed())}` : "-",
        poolcode: data?.subject_comp?.poolcode ? "Yes" : "No"
    }
    return (
        <ErrorBoundary>
            <CustomCard title="Comparables" className="title-2" style={{marginRight: isMobileOnly ? "3%" : "0"}}>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <div className="scroll-overlay2" onClick={onScrollClick2}>Swipe to see more →</div>
                    </Col>
                    <Col span={24}>
                        <div className="responsive-table comp-table">
                            <Table
                                ref={tableContainerRef2}
                                dataSource={data?.used_comps?.map((item, index) => ({ ...item, key: index }))}
                                columns={columns2}
                                scroll={{ x: "max-content" }}
                                loading={isLoading}
                                pagination={true}
                                rowKey={(record, index) => index}
                                sticky
                                summary={() => (
                                    <Table.Summary fixed="top">
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} className="left-column">
                                                SUBJECT
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} className="centered-column">
                                                <span>{formatNumberWithCommas(subject_house?.size) || "-"}</span>
                                                <span style={{ margin: '0 10px' }}>|</span>
                                                <span>{formatNumberWithCommas(subject_house?.Lot) || "-"}</span>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} className="centered-column">{subject_house?.age}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={3} className="centered-column">
                                                <span>{subject_house?.beds || "-"}</span>
                                                <span style={{ margin: '0 10px' }}>|</span>
                                                <span>{subject_house?.baths || "-"}</span>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} className="centered-column">{subject_house?.distance}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} className="centered-column">{subject_house?.poolcode}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={5} className="centered-column">{subject_house?.TransferDate}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={6} className="centered-column">{subject_house?.TransferValue}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={6} className="centered-column">{subject_house?.implyingValue}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
            </CustomCard>
        </ErrorBoundary>
    );
}

export default CompsWidget;
