import React, { useContext } from "react";
import { isBrowser } from "react-device-detect";
import { Empty } from "antd";
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
import { ThemeContext } from "../../context/ThemeContext";
import "./Piechart.css";
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "middle"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function MarketSegmentPieCharts({ data }) {

  const { theme } = useContext(ThemeContext);

  const COLORS = ["#76CAE9", theme?.primaryColor];

  const customConstructedData = [
    {
      name: "% Not Rising",
      value: 100 - Math.floor(data?.frac_rising * 100),
    },
    {
      name: "% Rising",
      value: Math.floor(data?.frac_rising * 100),
    },
  ];
  if ((data && data?.length === 0) || !data) {
    return <Empty description="Data unavailable" />;
  }
  return (
    <div
      style={
        isBrowser
          ? {
              width: "75%",
              height: 260,
              display: "inline-block",
            }
          : { height: 150 }
      }
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={customConstructedData}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={50}
            fill="#8884d8"
            dataKey="value"
          >
            {customConstructedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MarketSegmentPieCharts;
