import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ConditionScoreBarChart from "./ConditionScoreBarChart";
import QualityScoreBarChart from "./QualityScoreBarChart";
import CustomCard from '../../blocks/Card/Card';
import { Col, Row } from "antd";
import { conditionQuality } from "../../constants/defaultKeys";
import { ErrorBoundary, showInfo } from "../shared";
import { useConditionScore } from "./useConditionScore";
import { Skeleton } from "antd";
import Chart from 'chart.js/auto';
import { isMobileOnly } from "react-device-detect";

const ConditionScore = () => {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(213);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }

    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useConditionScore(filters, { enabled: params.reportName !== null });

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 34;
                setElementHeight(newHeight);
                window.parent.postMessage({ conditionScoreHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);


    if (isLoading) {
        window.parent.postMessage("Data Loading", "*");
        return (
            <CustomCard title="Condition & Quality" className="title-2">
                <Skeleton />
            </CustomCard>
        )
    }

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }
    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    if ((data && data !== null) && data?.has_avv === false) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    return (
        <ErrorBoundary>
            <CustomCard
                title={
                    (
                        <>
                            <span className="mobile-title-font">Condition & Quality</span>
                            {!showInfo(searchParams?.get("showInfo")) && <span>{conditionQuality()}</span>}
                        </>
                    )
                }
                ref={elementRef}
                className="sidebar-small-card title-2"
                style={{ marginRight: isMobileOnly ? "2%" : "0" }}
            >
                <Row gutter={[12, 12]} >
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} >
                        <ConditionScoreBarChart Chart={Chart} has_avv={data?.has_avv} chartData={data?.condition_score} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <QualityScoreBarChart Chart={Chart} has_avv={data?.has_avv} chartData={data?.quality_score} />
                    </Col>
                </Row>
            </CustomCard>
        </ErrorBoundary>
    )
};

export default ConditionScore;