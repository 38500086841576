import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isBrowser, isMobileOnly } from "react-device-detect";
import { Col, Row } from "antd";
import "./Property.css";
import "../Report.css";
import {
  Summary,
  WeissInsights,
  HouseMeasures,
  HousesNearBy,
  IndexTools,
  Segmentation,
  ValProCard,
  PageTitle,
  CompsWidget,
  ConditionScore,
  GroupedImages,
  ValPro,
  HousesNearByHeatMap,
} from "../../../Widgets";
import FinalValuation from "../../../Widgets/finalValuation/FinalValuation";
import { useSearchHistoryUpdate } from "../../../hooks/useSearchHistoryUpdate";

const Property = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filters = { ...params, queryParams: searchParams };
  useSearchHistoryUpdate(filters, { enabled: params.reportName !== null });
  const valproRef = useRef(null);
  const [currentTab, setCurrentTab] = useState("property");

  const handleValPalPdf = () => {
    valproRef?.current?.handleValPalData();
  };

  const user = JSON.parse(localStorage.getItem("user"));
  console.log("user", user);

  return (
    <Row gutter={[16, 16]}>
      <Col
        className="page-title-row"
        span={24}
        style={{ position: "sticky", top: 60, zIndex: 1000 }}
      >
        <div className="page-title">
          <PageTitle
            handleValPalPdf={handleValPalPdf}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
      </Col>

      {currentTab === "property" && (
        <>
          <Col
            lg={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <ValProCard />
          </Col>
          <Col span={16} xs={isMobileOnly && { span: 22, offset: 1 }}>
            <WeissInsights />
          </Col>
          <Col
            lg={isBrowser ? { span: 8 } : { span: 24 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            className="one-year-container"
          >
            <HouseMeasures />
            <Summary />
          </Col>
          <Col
            lg={isBrowser ? { span: 16 } : { span: 24 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <FinalValuation />
          </Col>
          {user?.is_staff ? (
            <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
              <HousesNearByHeatMap address={params?.address} />
            </Col>
          ) : (
            <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
              <HousesNearBy />
            </Col>
          )}
          <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
            <Segmentation />
          </Col>
          <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
            <CompsWidget />
          </Col>
          <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
            <IndexTools />
          </Col>
        </>
      )}

      {/* ValPro */}
      {currentTab === "valpal" && (
        <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
          <ValPro ref={valproRef} />
        </Col>
      )}

      {currentTab === "avv" && (
        <>
          <Col
            lg={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <ValProCard />
          </Col>
          <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
            <ConditionScore />
          </Col>

          <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
            <GroupedImages />
          </Col>
        </>
      )}
    </Row>
  );
};

export default Property;
