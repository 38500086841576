import { Table } from "antd";
import metroJsonData from "./data.json";
import FilterSlider from "./FilterSlider";

function formatNumberWithCommas(x) {
  try {
      if (x === 0) return "0";
      if (!x) return "-";
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  catch (err) {
      return "-";
  }
}


const MetroTable = ({
  selectedMetros,
  appliedFilters,
  setFilters,
  columnMinMaxes,
}) => {
  const columns = [
    {
      title: "Metro",
      dataIndex: "metro",
      key: "metro",
      sorter: (a, b) => a.metro.localeCompare(b.metro),
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      sorter: (a, b) => a.region.localeCompare(b.region),
      // find unique regions for filters
      filters: [...new Set(metroJsonData.map((item) => item.region))].map(
        (item) => ({ text: item, value: item })
      ),
      onFilter: (value, record) => record.region === value,
    },
    {
      title: "Last Year Change",
      dataIndex: "lastyearchange",
      key: "lastyearchange",
      render: (text) => `${parseFloat(text)}%`,
      sorter: (a, b) =>
        parseFloat(a.lastyearchange) - parseFloat(b.lastyearchange),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterSlider
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          min={columnMinMaxes?.lastyearchange[0]}
          max={columnMinMaxes?.lastyearchange[1]}
          prefix=""
          suffix="%"
          formatNumber={null}
        />
      ),
      onFilter: (value, record) => {
        if (!value || value.length !== 2) {
          return true;
        }
        const [min, max] = value;
        const recordValue = parseFloat(record.lastyearchange);
        return recordValue >= min && recordValue <= max;
      },
    },
    {
      title: "Median",
      dataIndex: "median",
      key: "median",
      render: (text) =>
        `$${parseFloat(text).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}`,
      sorter: (a, b) => parseFloat(a.median) - parseFloat(b.median),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterSlider
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          min={columnMinMaxes?.median[0]}
          max={columnMinMaxes?.median[1]}
          prefix="$"
          suffix=""
          formatNumber={formatNumberWithCommas}
        />
      ),
      onFilter: (value, record) => {
        if (!value || value.length !== 2) {
          return true;
        }
        const [min, max] = value;
        const recordValue = parseFloat(record.median);
        return recordValue >= min && recordValue <= max;
      },
    },
    {
      title: "Forecast",
      dataIndex: "forecast",
      key: "forecast",
      render: (text) => `${parseFloat(text)}%`,
      sorter: (a, b) => parseFloat(a.forecast) - parseFloat(b.forecast),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterSlider
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          min={columnMinMaxes?.forecast[0]}
          max={columnMinMaxes?.forecast[1]}
          prefix=""
          suffix="%"
          formatNumber={null}
        />
      ),
      onFilter: (value, record) => {
        if (!value || value.length !== 2) {
          return true;
        }
        const [min, max] = value;
        const recordValue = parseFloat(record.forecast);
        return recordValue >= min && recordValue <= max;
      },
    },
    {
      title: "Percentage Rising",
      dataIndex: "percentrising",
      key: "percentrising",
      render: (text) => `${parseFloat(text)}%`,
      sorter: (a, b) =>
        parseFloat(a.percentrising) - parseFloat(b.percentrising),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterSlider
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          min={columnMinMaxes?.percentrising[0]}
          max={columnMinMaxes?.percentrising[1]}
          prefix=""
          suffix="%"
          formatNumber={null}
        />
      ),
      onFilter: (value, record) => {
        if (!value || value.length !== 2) {
          return true;
        }
        const [min, max] = value;
        const recordValue = parseFloat(record.percentrising);
        return recordValue >= min && recordValue <= max;
      },
    },
    {
      title: "Percentage Falling",
      dataIndex: "percentfalling",
      key: "percentfalling",
      render: (text) => `${parseFloat(text)}%`,
      sorter: (a, b) =>
        parseFloat(a.percentfalling) - parseFloat(b.percentfalling),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterSlider
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          min={columnMinMaxes?.percentfalling[0]}
          max={columnMinMaxes?.percentfalling[1]}
          prefix=""
          suffix="%"
          formatNumber={null}
        />
      ),
      onFilter: (value, record) => {
        if (!value || value.length !== 2) {
          return true;
        }
        const [min, max] = value;
        const recordValue = parseFloat(record.percentfalling);
        return recordValue >= min && recordValue <= max;
      },
    },
    {
      title: "Best Forecast",
      dataIndex: "bestforecast",
      key: "bestforecast",
      render: (text) => `${parseFloat(text)}%`,
      sorter: (a, b) => parseFloat(a.bestforecast) - parseFloat(b.bestforecast),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterSlider
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          min={columnMinMaxes?.bestforecast[0]}
          max={columnMinMaxes?.bestforecast[1]}
          prefix=""
          suffix="%"
          formatNumber={null}
        />
      ),
      onFilter: (value, record) => {
        if (!value || value.length !== 2) {
          return true;
        }
        const [min, max] = value;
        const recordValue = parseFloat(record.bestforecast);
        return recordValue >= min && recordValue <= max;
      },
    },
    {
      title: "Worst Forecast",
      dataIndex: "worstforecast",
      key: "worstforecast",
      render: (text) => `${parseFloat(text)}%`,
      sorter: (a, b) =>
        parseFloat(a.worstforecast) - parseFloat(b.worstforecast),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterSlider
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          min={columnMinMaxes?.worstforecast[0]}
          max={columnMinMaxes?.worstforecast[1]}
          prefix=""
          suffix="%"
          formatNumber={null}
        />
      ),
      onFilter: (value, record) => {
        if (!value || value.length !== 2) {
          return true;
        }
        const [min, max] = value;
        const recordValue = parseFloat(record.worstforecast);
        return recordValue >= min && recordValue <= max;
      },
    },
  ];

  if (appliedFilters?.length === 0) {
    columns.forEach((column) => {
      column.filteredValue = null;
    })
  } 

  const myFunc = (filters) => {
    filters = Object.keys(filters).reduce(
      (acc, key) => {
        if (filters[key] === null && key !== "region") {
          acc[key] = columnMinMaxes[key];
        } else {
          acc[key] =
            filters[key] && filters[key].length > 0 ? filters[key][0] : null;
        }
        return acc;
      },
      {
        ...filters,
      }
    );
    setFilters(filters);
  };

  return (
    <div className="responsive-table">
      <Table
        columns={columns}
        dataSource={selectedMetros}
        // onFilter={(column, filters) => handleFilterChange(column.key, filters)}
        onChange={(pagination, filters, sorter, extra) => myFunc(filters)}
        rowKey={(record) => record.metro}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default MetroTable;
