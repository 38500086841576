import ProjectRoutes from "./routes/Routes";
import { QueryClientProvider, QueryClient } from "react-query";
import { ThemeContext } from "./context/ThemeContext";
import { useState } from "react";
import { ConfigProvider } from "antd";
import "./App.less";

const queryClient = new QueryClient();

function App() {
  const [theme, setTheme] = useState({
    primaryColor: "#97c23c",
    errorColor: "#DF2927",
    warningColor: "#DF2927",
    successColor: "#DF2927",
    infoColor: "#DF2927",
  });

  const [font, setFont] = useState({
    fontFamily: '"Roboto Condensed", sans-serif',
  });

  const contextData = {
    theme,
    setThemeType: (themeName) => setTheme(themeName),
    font,
    setFont: (fontName) => setFont(fontName),
  };

  ConfigProvider.config({
    theme: {
      primaryColor: contextData.theme.primaryColor,
      fontFamily: contextData?.font?.fontFamily,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext.Provider value={contextData}>
        <ConfigProvider>
          <ProjectRoutes />
        </ConfigProvider>
      </ThemeContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
