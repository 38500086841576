import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Col, Row, Skeleton } from "antd";
import CountUp from "react-countup";
import CustomCard from "../../blocks/Card/Card";
import { worstSegmentInfo, bestSegmentInfo } from "../../constants/defaultKeys";
import { useBestWorstSegment } from "./useBestWorstSegment";

const BestWorstSegment = ({ segment }) => {

    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useBestWorstSegment(filters, { enabled: params.reportName !== null });

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }
    if (isLoading) {
        return (
            <CustomCard title={segment === "best" ? "Best Segment" : "Worst Segment"} className="report title-2">
                <Skeleton />
            </CustomCard>
        )
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    let segmentEnd = segment === "best" ? data?.rsfr?.best_segment : data?.rsfr?.worst_segment;
    return (
        <CustomCard
            title={
                segment === "best" ? (
                    <>
                        <span>Best Segment</span>
                        {bestSegmentInfo()}
                    </>
                ) : (
                    <>
                        <span>Worst Segment</span>
                        {worstSegmentInfo()}
                    </>
                )
            }
            className="sidebar-small-card title-2"
        >
            <Row type="flex" align="middle">
                <Col span={16}>
                    <span>{segment === "best" ? data?.rsfr?.bestsize : data?.rsfr?.worstsize}, </span>
                    <span>{segment === "best" ? data?.rsfr?.bestage : data?.rsfr?.worstage}, </span>
                    <span>{segment === "best" ? data?.rsfr?.bestprice : data?.rsfr?.worstprice} </span>
                    <span>Properties</span>
                </Col>
                <Col span={6} offset={1}>
                    <p className="count-up">
                        <CountUp
                            end={segmentEnd}
                            // decimals={1}
                            suffix="%"
                        />
                    </p>
                </Col>
            </Row>
        </CustomCard>
    )
}

export default BestWorstSegment


