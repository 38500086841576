import L from "leaflet";
import "leaflet/dist/leaflet.css";

export const filledIcon = L.divIcon({
    className: "custom-icon",
    html: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024">
      <path fill="green" d="M512 327c-29.9 0-58 11.6-79.2 32.8A111.6 111.6 0 0 0 400 439c0 29.9 11.7 58 32.8 79.2A111.6 111.6 0 0 0 512 551c29.9 0 58-11.7 79.2-32.8C612.4 497 624 468.9 624 439s-11.6-58-32.8-79.2S541.9 327 512 327m342.6-37.9a362.5 362.5 0 0 0-79.9-115.7a370.8 370.8 0 0 0-118.2-77.8C610.7 76.6 562.1 67 512 67s-98.7 9.6-144.5 28.5c-44.3 18.3-84 44.5-118.2 77.8A363.6 363.6 0 0 0 169.4 289c-19.5 45-29.4 92.8-29.4 142c0 70.6 16.9 140.9 50.1 208.7c26.7 54.5 64 107.6 111 158.1c80.3 86.2 164.5 138.9 188.4 153a43.9 43.9 0 0 0 22.4 6.1c7.8 0 15.5-2 22.4-6.1c23.9-14.1 108.1-66.8 188.4-153c47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142M512 615c-97.2 0-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176s-78.8 176-176 176" />
  </svg>`,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -24],
  });
  
export const emptyIcon = L.divIcon({
    className: "custom-icon",
    html: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024">
      <path fill="grey" d="M854.6 289.1a362.5 362.5 0 0 0-79.9-115.7a370.8 370.8 0 0 0-118.2-77.8C610.7 76.6 562.1 67 512 67s-98.7 9.6-144.5 28.5c-44.3 18.3-84 44.5-118.2 77.8A363.6 363.6 0 0 0 169.4 289c-19.5 45-29.4 92.8-29.4 142c0 70.6 16.9 140.9 50.1 208.7c26.7 54.5 64 107.6 111 158.1c80.3 86.2 164.5 138.9 188.4 153a43.9 43.9 0 0 0 22.4 6.1c7.8 0 15.5-2 22.4-6.1c23.9-14.1 108.1-66.8 188.4-153c47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142M512 880.2c-65.9-41.9-300-207.8-300-449.1c0-77.9 31.1-151.1 87.6-206.3C356.3 169.5 431.7 139 512 139s155.7 30.5 212.4 85.9C780.9 280 812 353.2 812 431.1c0 241.3-234.1 407.2-300 449.1m0-617.2c-97.2 0-176 78.8-176 176s78.8 176 176 176s176-78.8 176-176s-78.8-176-176-176m79.2 255.2A111.6 111.6 0 0 1 512 551c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 439c0-29.9 11.7-58 32.8-79.2C454 338.6 482.1 327 512 327s58 11.6 79.2 32.8S624 409.1 624 439s-11.6 58-32.8 79.2" />
  </svg>`,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -24],
  });
  
export const emptySatelliteIcon = L.divIcon({
    className: "custom-icon",
    html: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024">
      <path fill="white" d="M854.6 289.1a362.5 362.5 0 0 0-79.9-115.7a370.8 370.8 0 0 0-118.2-77.8C610.7 76.6 562.1 67 512 67s-98.7 9.6-144.5 28.5c-44.3 18.3-84 44.5-118.2 77.8A363.6 363.6 0 0 0 169.4 289c-19.5 45-29.4 92.8-29.4 142c0 70.6 16.9 140.9 50.1 208.7c26.7 54.5 64 107.6 111 158.1c80.3 86.2 164.5 138.9 188.4 153a43.9 43.9 0 0 0 22.4 6.1c7.8 0 15.5-2 22.4-6.1c23.9-14.1 108.1-66.8 188.4-153c47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142M512 880.2c-65.9-41.9-300-207.8-300-449.1c0-77.9 31.1-151.1 87.6-206.3C356.3 169.5 431.7 139 512 139s155.7 30.5 212.4 85.9C780.9 280 812 353.2 812 431.1c0 241.3-234.1 407.2-300 449.1m0-617.2c-97.2 0-176 78.8-176 176s78.8 176 176 176s176-78.8 176-176s-78.8-176-176-176m79.2 255.2A111.6 111.6 0 0 1 512 551c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 439c0-29.9 11.7-58 32.8-79.2C454 338.6 482.1 327 512 327s58 11.6 79.2 32.8S624 409.1 624 439s-11.6 58-32.8 79.2" />
  </svg>`,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -24],
  });