import {
  Col,
  Divider,
  Row,
  Skeleton,
  Space,
  Statistic,
  Typography,
  Tooltip,
  Badge,
    Tag,
} from "antd";
import CountUp from "react-countup";
import { isBrowser, isMobileOnly } from "react-device-detect";
import calendar from "../../assets/images/calendar.png";
import bed from "../../assets/images/bed.png";
import area from "../../assets/images/area.png";
import shower from "../../assets/images/shower.png";
import { useCallback } from "react";

const { Paragraph, Title, Text } = Typography;
const formatter = (value) => <CountUp end={value} separator="," prefix="$" />;

const ValproHeader = ({
  selectedComp,
  weightedAverage,
  subject_comp,
  properUpdateLoading,
  adjustedValue,
  adjustedValues,
}) => {

    const renderAdjustedValues = useCallback(() => {
        if (!adjustedValues || adjustedValues?.length === 0) return;
        let numAdjustments = `${adjustedValues?.length} Adjustment(s) Made`;
        return (
            <div>
                {numAdjustments}
                {adjustedValues.map((val, i) => (
                    <Tag key={i} color="green" style={{marginBottom: "5px"}}>
                        {val?.description}
                    </Tag>
                ))}
            </div>
        );
      }, [adjustedValues]);

  if (properUpdateLoading) {
    return <Skeleton active />;
  }

  return (
    <Col span={24}>
      <Row className="val-pro-header" gutter={[10, 10]}>
        <Col
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
          xs={{ span: 12 }}
        >
          <Space
            direction="vertical"
            size={[1, 1]}
            style={{ paddingBottom: "8px" }}
          >
            <Paragraph
              style={{
                fontSize: isMobileOnly ? "12px" : "14px",
                textAlign: "start",
              }}
            >
              User Estimate
            </Paragraph>
            {selectedComp !== 0 || (parseInt(adjustedValue) || 0) !== 0 ? (
              <Tooltip title={renderAdjustedValues()} placement="top">
                <Badge
                  count={
                    adjustedValues?.length > 0 ? adjustedValues?.length : 0
                  }
                  style={{ backgroundColor: "#7cb305" }}
                >
                  <Statistic
                    value={
                      (Math.floor(weightedAverage) || 0) +
                      (parseInt(adjustedValue) || 0)
                    }
                    precision={2}
                    valueStyle={{
                      fontSize: isMobileOnly ? "12px" : "20px",
                      fontWeight: "bold",
                    }}
                    formatter={formatter}
                  />
                </Badge>
              </Tooltip>
            ) : (
              <Text style={{ fontSize: isMobileOnly ? "11px" : "14px" }}>
                Please Choose Comps
              </Text>
            )}
          </Space>
        </Col>
        <Col
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
          xs={{ span: 12 }}
        >
          <Paragraph style={{ fontSize: isMobileOnly ? "12px" : "14px" }}>
            Comps Selected
          </Paragraph>
          {isMobileOnly && <Text strong>{selectedComp}</Text>}
          {!isMobileOnly && (
            <Paragraph
              strong
              style={{ fontSize: isMobileOnly ? "18px" : "25px" }}
            >
              {selectedComp}
            </Paragraph>
          )}
        </Col>
        <Divider style={{ margin: "5px 0" }} />
        {subject_comp && (
          <Col span={24} style={{ paddingBottom: "8px" }}>
            <Row gutter={[16, 16]} type="flex">
              <Col
                lg={isBrowser ? { span: 4 } : { span: 14 }}
                md={{ span: 6 }}
                sm={{ span: 6 }}
                xs={{ span: 6 }}
              >
                <img
                  src={calendar}
                  width={isMobileOnly ? "18px" : "24px"}
                  height="auto"
                  alt="calendar"
                />
                <Title level={isMobileOnly ? 5 : 4}>
                  {subject_comp?.age && subject_comp?.age !== 0
                    ? subject_comp?.age
                    : "-"}
                </Title>
                <small>YR BUILT</small>
              </Col>
              <Col
                lg={{ span: 6 }}
                md={{ span: 6 }}
                sm={{ span: 6 }}
                xs={{ span: 6 }}
              >
                <img
                  src={area}
                  width={isMobileOnly ? "18px" : "24px"}
                  height="auto"
                  alt="area"
                />
                <Title level={isMobileOnly ? 5 : 4}>
                  {subject_comp?.size && subject_comp?.size !== 0
                    ? subject_comp?.size
                    : "-"}
                </Title>
                <small>SQ FT</small>
              </Col>
              <Col
                lg={{ span: 6 }}
                md={{ span: 6 }}
                sm={{ span: 6 }}
                xs={{ span: 6 }}
              >
                <img
                  src={bed}
                  width={isMobileOnly ? "18px" : "24px"}
                  height="auto"
                  alt="bed"
                />
                <Title level={isMobileOnly ? 5 : 4}>
                  {subject_comp?.beds && subject_comp?.beds !== 0
                    ? subject_comp?.beds
                    : "-"}
                </Title>
                <small>BEDS</small>
              </Col>
              <Col
                lg={isBrowser ? { span: 6 } : { span: 14 }}
                md={{ span: 6 }}
                sm={{ span: 6 }}
                xs={{ span: 6 }}
              >
                <img
                  src={shower}
                  width={isMobileOnly ? "18px" : "24px"}
                  height="auto"
                  alt="shower"
                />
                <Title level={isMobileOnly ? 5 : 4}>
                  {subject_comp?.baths && subject_comp?.baths !== 0
                    ? subject_comp?.baths
                    : "-"}
                </Title>
                <small>BATHS</small>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default ValproHeader;
