import { useQuery } from 'react-query';
import axiosInstance from  "../config/axios/axios.config";

async function updateSearchHistory(filters) {
  const {code, reportName, propType} = filters;
  let url = `reports/update-search/${reportName}/${propType}/${code}/?`
  try {
      const response = await axiosInstance.get(url)
    return response.data;
  } catch (error) {
    console.error("Search update has failed with error: ", error);
  }
}


export function useSearchHistoryUpdate(filters, options={}) {
  return useQuery(["SearchHistoryUpdateData", filters], () => updateSearchHistory(filters), {
    staleTime: 60000, 
    ...options
  })
}