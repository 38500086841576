import React, { useState, useMemo, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { Row, Col, Button } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import { getColorForValue } from "../shared";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const MetroCircle = ({ metroData, to, color, mapContainerRef }) => {
  const popoverProps = {
    getPopupContainer: () => mapContainerRef.current,
    overlayStyle: {
      zIndex: 9999,
    },
  };

  let TitleData = () => {
    return (
      <div style={{ color: "#434343", fontWeight: "bolder" }}>
        <p>{metroData}</p>
        <Link to={to}>View Report</Link>
      </div>
    );
  };
  return (
    <Tooltip title={<TitleData />} color="#f5f5f5" {...popoverProps}>
      <Link to={null}>
        <EnvironmentFilled
          style={{ fontSize: "20px", color: getColorForValue(color) }}
        />
      </Link>
    </Tooltip>
  );
};

const MetroMap = ({ selectedMetros }) => {
  const [mapBounds, setMapBounds] = useState(null);
  const mapContainerRef = useRef(null);
  let key = process.env.REACT_APP_MAP_KEY;

  const handleMapChange = ({ bounds }) => {
    setMapBounds(bounds);
  };
  let filteredMetros = selectedMetros;

  let visibleMetros = filteredMetros?.filter(
    (metro) =>
      metro.latitude >= mapBounds?.sw.lat &&
      metro.latitude <= mapBounds?.ne.lat &&
      metro.longitude >= mapBounds?.sw.lng &&
      metro.longitude <= mapBounds?.ne.lng
  );

  const fullscreenHandle = useFullScreenHandle();
  const handleFullscreenChange = (isFullscreen) => {
    if (isFullscreen) {
      window.dispatchEvent(new Event("resize"));
    }
  };
  const createMapOptions = useMemo((maps) => {
    return {
      mapTypeControl: true,
      gestureHandling: "cooperative",
      streetViewControl: true,
      fullscreenControl: false,
    };
  }, []);

  return (
    <FullScreen handle={fullscreenHandle} onChange={handleFullscreenChange}>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          {!fullscreenHandle.active && (
            <Button
              type="primary"
              onClick={fullscreenHandle.enter}
              style={{
                margin: "0 5px",
                width: "100px",
                borderRadius: "5px",
              }}
            >
              Fullscreen
            </Button>
          )}
          {fullscreenHandle.active && (
            <Button
              type="primary"
              onClick={fullscreenHandle.exit}
              style={{
                margin: "0 5px",
                width: "120px",
                borderRadius: "5px",
              }}
            >
              Exit Fullscreen
            </Button>
          )}
        </Col>
        <Col span={24} ref={mapContainerRef}>
          <div
            style={{
              width: fullscreenHandle.active ? "100%" : "100%",
              height: fullscreenHandle.active ? "90vh" : "40vh",
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: key }}
              defaultCenter={{ lat: 39.8283, lng: -98.5795 }}
              defaultZoom={5}
              options={createMapOptions}
              onChange={handleMapChange}
            >
              {visibleMetros.map((metro, index) => (
                <MetroCircle
                  mapContainerRef={mapContainerRef}
                  key={index}
                  lat={metro.latitude}
                  lng={metro.longitude}
                  metroData={`${metro.metro} - ${metro.region}-${metro.cbsa_code}`}
                  to={`/report/${metro.metro}/metro/${metro.cbsa_code}`}
                  color={metro.lastyearchange}
                />
              ))}
            </GoogleMapReact>
          </div>
        </Col>
      </Row>
    </FullScreen>
  );
};

export default MetroMap;
