import { useRef, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { reactFormatter } from "react-tabulator";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { Button, Col, Input, Row, Space } from "antd";
import { useListings } from "./useListings";
import { Loading, ErrorBoundary } from "../shared";
import ListingAddress from "./ListingAddress";
import ListingAgent from "./ListingAgent";
import ListingAgentOffice from "./ListingAgentOffice";
import "../../components/Report/Metro/Listings/Listings.css";

function Listings() {
  const tableRef = useRef(null);
  const [table, setTable] = useState(null);
  const [value, setValue] = useState(null);
  const [selectedFieldOption, setSelectedFieldOption] = useState('');
  const [selectedTypeOption, setSelectedTypeOption] = useState('');
  const params = useParams();
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);

  if (location?.pathname?.includes("report")) {
    searchParams = new URLSearchParams({
      ...(searchParams ? searchParams : {}),
      isWebsite: true
    });
  }
  const filters = { ...params, queryParams: searchParams };
  const { data, isLoading, isError } = useListings(filters, {
    enabled: params.reportName !== null && typeof params.reportName !== 'undefined',
  });

  useEffect(() => {
    const columns = [
      {
        title: "Address",
        field: "Address",
        width: 200,
        formatter: reactFormatter(<ListingAddress />),
      },
      {
        title: "Days on market",
        field: "FACalculatedDaysOnMarket",
        width: 150,
        sorter: "number",
      },
      {
        title: "Asking price",
        field: "CurrentListingPrice",
        width: 150,
        sorter: "number",
        formatter: "money",
        formatterParams: {
          thousand: false,
          symbol: "$",
          precision: false,
        },
      },
      {
        title: "Valuation",
        field: "Valuation",
        width: 140,
        sorter: "number",
        formatter: "money",
        formatterParams: {
          thousand: false,
          symbol: "$",
          precision: false,
        },
      },
      {
        title: "Discount",
        field: "DiscountAmount",
        width: 140,
        sorter: "number",
        formatter: "money",
        formatterParams: {
          thousand: false,
          symbol: "$",
          precision: false,
        },
      },
      {
        title: "Discount %",
        field: "DiscountPercent",
        width: 120,
        sorter: "number",
        formatter: "money",
        formatterParams: {
          thousand: false,
          symbol: "%",
          symbolAfter: "p",
          precision: false,
        },
      },
      {
        title: "Forecast %",
        field: "forecast",
        width: 120,
        sorter: "number",
        formatter: "money",
        formatterParams: {
          thousand: false,
          symbol: "%",
          symbolAfter: "p",
          precision: false,
        }
      },
      {
        title: "Year built",
        field: "YearBuilt",
        width: 130,
        sorter: "number",
      },
      {
        title: "Lot Size",
        field: "LotSize",
        sorter: "number",
        width: 120,
      },
      {
        title: "Home Size",
        field: "HomeSize",
        width: 150,
        sorter: "number",
      },
      {
        title: "Bed Rooms",
        field: "Bedrooms",
        width: 120,
        sorter: "number",
      },
      {
        title: "Bath Rooms",
        field: "Bathrooms",
        width: 120,
        sorter: "number",
      },
      {
        title: "LTV",
        field: "LTV",
        width: 120,
        sorter: "number",
        formatter: "money",
        formatterParams: {
          thousand: false,
          symbol: "%",
          symbolAfter: "p",
          precision: false,
        }
      },
      {
        title: "Year sold",
        field: "YearSold",
        width: 120,
        sorter: "number",
      },
      {
        title: "Agent",
        field: "ListingsAgentName",
        width: 250,
        hozAlign: "left",
        formatter: reactFormatter(<ListingAgent />),
      },
      {
        title: "Agents Office",
        field: "OfficeName",
        width: 500,
        formatter: reactFormatter(<ListingAgentOffice />),
      },
    ];
    if (data) {
      let t = new Tabulator(tableRef.current, {
        height: "auto",
        layout: "fitColumns",
        columns: columns,
        data: data,
        pagination: "local",
        paginationSize: 20,
      });
      setTable(t);
    }

  }, [data]);


  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }
  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && !isError && (!data || data === null)) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }
  const handleSelectFieldChange = (event) => {
    setSelectedFieldOption(event.target.value);
    let values = {
      inpVal: value,
      fieldVal: event.target.value,
      typeVal: selectedTypeOption,
    }
    updateFilter(values)
  };

  const handleSelectTypeChange = (event) => {
    let values = {
      typeVal: event.target.value,
      inpVal: value,
      fieldVal: selectedFieldOption,
    }
    setSelectedTypeOption(event.target.value);
    updateFilter(values)
  };

  const handleInputChange = (event) => {
    let values = {
      typeVal: selectedTypeOption,
      inpVal: event.target.value,
      fieldVal: selectedFieldOption,
    }
    setValue(event.target.value);
    updateFilter(values)
  };


  function updateFilter(values) {
    const { fieldVal, typeVal, inpVal } = values;
    if (fieldVal !== "" && typeVal !== "" && inpVal !== "") {

      table?.clearFilter();
      table?.setFilter(fieldVal, typeVal, inpVal);
    } else {
      table?.clearFilter();
    }
  }

  function clearFilter() {
    setSelectedFieldOption('');
    setSelectedTypeOption('');
    setValue('');
    table?.clearFilter();
  }


  return (
    <ErrorBoundary>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Space wrap>
            <label>Select field:</label>

            <select
              placeholder="Choose value"
              style={{ width: "200px", height: "30px" }}
              onChange={handleSelectFieldChange}
              value={selectedFieldOption}
            >
              <option value="" defaultValue>
                Select your option
              </option>
              <option value="Address">Address</option>
              <option value="FACalculatedDaysOnMarket">Days on market</option>
              <option value="CurrentListingPrice">Asking price</option>
              <option value="Valuation">Valuation</option>
              <option value="DiscountAmount">Discount</option>
              <option value="DiscountPercent">Discount %</option>
              <option value="forecast">Forecast %</option>
              <option value="YearBuilt">Year built</option>
              <option value="LotSize">Lot Size</option>
              <option value="HomeSize">Home Size</option>
              <option value="Bedrooms">Bed Rooms</option>
              <option value="Bathrooms">Bath Rooms</option>
              <option value="LTV">LTV</option>
              <option value="YearSold">Year sold</option>
              <option value="ListingsAgentName">Agent</option>
              <option value="OfficeName">Agents Office</option>
            </select>

            <label>Select filter type:</label>
            <select
              placeholder="Choose value"
              style={{ width: "200px", height: "30px" }}
              onChange={handleSelectTypeChange}
              value={selectedTypeOption}
            >
              <option value="" defaultValue>Choose value</option>
              <option value="=">Equals</option>
              <option value="<">Less than</option>
              <option value=">">Greater than</option>
              <option value="!=">Not equals</option>
              <option value="<=">Less than or equals</option>
              <option value=">=">Greater than or equals</option>
            </select>

            <label>Filter value:</label>
            <Input
              id="filter-value"
              value={value}
              style={{ width: "200px", height: "30px" }}
              onChange={handleInputChange}
            />

            <Button type="primary" onClick={clearFilter}>
              Clear Filter
            </Button>
          </Space>
        </Col>

        <Col span={24}>
          <div ref={tableRef} />
        </Col>
      </Row>
    </ErrorBoundary>
  );
}

export default Listings;
