import axiosInstance from "../config/axios/axios.config";

export const getPaymentToken = () => {
  return axiosInstance.post(`/payments/create-payment-url/`);
};

export const capturePayment = (payload) => {
  return axiosInstance.post(`/payments/capture-payment/`, payload);
};

export const getSubscriptionPlans = () => {
  return axiosInstance.get(`/payments/get-subscription-plans/`);
};

export const getSubscriptionTransactions = (startDate, endDate) => {
  return axiosInstance.get(
    `/payments/get-my-transactions/?start_date=${startDate}&end_date=${endDate}`
  );
};
export const cancelSubscriptionApi = () => {
  return axiosInstance.post(`/payments/cancel-subscription/`);
};
