import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

export const TOKEN_KEY = "token";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const USER = "user";
export const uploadFileUrl = `${process.env.REACT_APP_BASE_URL}news/upload-file-or-image/`;
export const colors = [
  "#2c699a",
  "#048ba8",
  "#0db39e",
  "#83e377",
  "#54478c",
  "#d81159ff",
  "#8f2d56ff",
  "#cc4400",
  "#000077",
  "#b9e769",
  "#efea5a",
  "#f1c453",
  "#f29e4c",
  "#d66915",
  "#e08e29",
  "#f0c761",
  "#7cc6de",
  "#3890bc",
  "#be1e2dff",
  "#1c489a",
  "#006ba6ff",
  "#471ca8ff",
  "#884ab2ff",
  "#72bad5ff",
  "#03324eff",
  "#ef4043ff",
  "#16db93",
  "#c43240ff",
  "#52ace4",
  "#FF6633",
  "#FFB399",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
  "#FF6633",
  "#FFB399",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

export const bestSegmentInfo = () => {
  const content = (
    <p>
      The Best Segment is Weiss Analytic’s pick
      <br /> for the segment of houses, within a single
      <br /> MSA, that will have the strongest price
      <br /> appreciation next year. The segments are <br />
      based on a combination of home size,
      <br /> age and price. The best segment is
      <br /> expressed as relative size, relative age <br />
      and relative price compared to other
      <br /> homes in the MSA.
    </p>
  );

  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};

export const indexOverTheYearInfo = () => {
  const content = (
    <p>
      Below is a line graph of the Weiss Indexes for this Metro (MSA). <br />A
      Weiss Index tracks the movement of home values for <br />
      properties, ZIPs, or Metros (MSA) over time due to market
      <br /> conditions (meaning that changes in the condition of the
      properties, <br />
      ZIPs, or Metros themselves will not be reflected in the index value).
      <br /> The index value at any given time is NOT the average home value
      <br /> of the Metro but instead is the relative value of the Metro
      compared
      <br /> to the index value of that Metro at another point in time. For
      example,
      <br /> if the index value of a Metro was 150 in January 2010 and the index
      <br />
      value of the Metro was 100 in January 2005, then that would mean <br />
      the Metro's average home value was worth 1.5 times as much in <br />
      January 2010 than in January 2005 or 50% more.
    </p>
  );

  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};

export const weissInsightsInfo = () => {
  const content = (
    <p className="weissinfo-font">
      <span>Weiss Ranking is a relative score comparing the current property</span>
      <span>relative to properties in the same ZIP Code. The score ranges</span>
      <span>between 1 to 10 and the higher the score, the better the property is</span>
      <span>relative to other properties in the same ZIP Code. This score</span>
      <span>is based on near-term historical performance and Weiss forecast.</span>
    </p>
  );
  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined className="weissinfo-popover" />
    </Popover>
  );
}

export const conditionQuality = () => {
  const content = (
    <div className="weissinfo-font">
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
        <h3 style={{fontWeight: "bold"}}>Condition Score</h3>
        <span>The Condition Score is a proprietary model that analyzes your property media</span>
        <span>and provides a continuous condition score using a 6-point scale ranging from</span>
        <span> Brand New to Heavy Damage/Not Livable. This score is also based on</span>
        <span>the scoring system from the Uniform Appraisal Databaset</span>
        <span>used by Fannie Mae, Fredie Mac and others, for underwriting.</span>
      </div>

      <div style={{marginTop: "12px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
        <h3 style={{fontWeight: "bold"}}>Quality Score</h3>
        <span>The Quality Score is a proprietary model that analyzes your property photos</span>
        <span>and determines the quality of finishes, allowing easy organization,</span>
        <span>classification, and search. Using a continuous 6-point scale ranging from Luxury to Basic,</span>
        <span> this score is based on the scoring system from Uniform Appraisal Dataset.</span>
      </div>
    </div>
  );
  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined className="weissinfo-popover" />
    </Popover>
  );
}

export const dynamicMetroInfo = () => {
  const content = (
    <p className="weissinfo-font">
      <span>Weiss MapsTM dynamically illustrate historic and predicted value</span>
      <span>trends at a ZIP Code or metro level for a historical period of ten</span>
      <span>years and a one year forecast. Weiss Maps reveal and forewarn of</span>
      <span>never before seen trends in markets, often showing waves of value</span>
      <span>changes moving across a geographic area like a weather event. Each</span>
      <span>dot represents a home, and its color indicates whether the home is</span>
      <span>appreciating or depreciating at the time indicated on the map</span>
      <span>(based on the Weiss Index) with green indicating appreciation and red</span>
      <span>indicating depreciation according to the color scale on the map.</span>
      <span>Click the play button to watch trends unfold over time and into the future.</span>
    </p>
  );
  return (
    <Popover content={content} style={{ width: "100%" }}>
      <span className="mobile-title-font">Dynamic Metro Map</span> <InfoCircleOutlined className="weissinfo-popover" />
    </Popover>
  );
}


export const worstSegmentInfo = () => {
  const content = (
    <p>
      The Worst Segment is Weiss Analytics pick <br />
      for the segment of houses, within a single
      <br /> MSA, that will have the weakest price
      <br /> appreciation next year. The segments are
      <br /> based on a combination of home size, age <br />
      and price. The worst segment is expressed <br />
      as relative size, relative age and relative price
      <br /> compared to other homes in the MSA. .
    </p>
  );

  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};

export const marketSegmentInfo = () => {
  const content = (
    <p style={{ display: "inline-block" }}>
      Below we have sorted the homes in this Metro (MSA) into four tiers based
      on the attribute
      <br />
      displayed on the selected tab. For example, if the Value tab is selected,
      the homes will be <br /> sorted into a low end tier, a mid-low end tier, a
      mid-high end tier, and a high end tier. <br /> Each tier has a quarter of
      the homes in the Metro. The charts below then show the Last Year <br />
      performance (based on the Weiss Index), the One Year Forecast (based on
      the Weiss Forecast), <br /> and the Percent of Homes Rising in the tier
      (based on the Weiss Index) enabling you to compare <br />
      how different types of homes in the Metro are performing and to determine
      if certain segments of <br /> the market are stronger than others..
    </p>
  );
  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};

export const HousesNearByInfo = () => {
  const content = (
    <p style={{ display: "inline-block" }}>
      The below map displays homes in this ZIP color-coded <br />
      to reflect their respective appreciation or depreciation
      <br /> corresponding to the Map Key. By selecting the appropriate
      <br />
      tab, you can view the home appreciation
      <br /> or depreciation over the Last Year
      <br /> (based on the Weiss Index) or One Year Forecast <br />
      (based on the Weiss Forecast). The subject property
      <br /> has a balloon on it to distinguish it from other homes nearby.
      <br />
      Selecting a home will display its features, such as age, size,
      <br /> beds, baths, and appreciation rate in the selected period. <br />
      You can also zoom on the map to focus on certain areas
      <br /> within the ZIP, or to take a higher level view.
    </p>
  );
  return (
    <Popover content={content}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};

export const valProInfo = () => {
  const content = (
    <p>
      ValPal will determine the value of the subject home
      <br />
      based on the comps you select and the condition
      <br />
      adjustments using the robust estimation method.
    </p>
  );
  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};

export const zipcodeInfo = () => {
  const content = (
    <p>
      Below we have sorted the homes in this ZIP into four tiers based on the
      <br />
      attribute displayed on the selected tab. For example, if the Value tab is
      <br />
      selected, the homes will be sorted into a low end tier, a mid-low end
      tier,
      <br /> a mid-high end tier, and a high end tier. Each tier has a quarter
      of the homes
      <br /> in the ZIP . The charts below then show the Last Year performance
      <br />
      (based on the Weiss Index), the One Year Forecast (based on the Weiss
      <br />
      Forecast), and the Percent of Homes Rising in the tier (based on the Weiss
      <br />
      Index) enabling you to compare how different types of homes in the ZIP are
      <br />
      performing and to determine if certain segments of the market are stronger
      <br />
      than others.
    </p>
  );
  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};

export const dynamicMapInfo = () => {
  const content = (
    <p>
      The map below displays all of the homes in this ZIP <br />
      color-coded to reflect their respective appreciation rate
      <br /> over the Last Year (based on the Weiss Index) or One
      <br /> Year Forecast (based on the Weiss Forecast) depending
      <br /> on the selected tab. The intensity of color corresponds to
      <br /> the values depicted on the map key. Clicking on any home
      <br /> will allow you to see the various properties of that home
      <br /> such as its age, size, beds, baths, and appreciation rate <br />
      in the selected period. You can also zoom in and out on
      <br /> the map to focus in on certain areas in the ZIP or to take <br />a
      higher level view.
    </p>
  );
  return (
    <Popover content={content} style={{ width: "100%" }}>
      <InfoCircleOutlined style={{ fontSize: "16px", marginLeft: "5px" }} />
    </Popover>
  );
};
