import { useNavigate } from "react-router-dom";
import { Card, List, Tag, message } from "antd";
import { isMobileOnly } from "react-device-detect";

const getBackgroundColor = (reportType, item) => {
    if (reportType === 'avv' && item?.status?.toLowerCase() === 'completed') {
        return '#e6ffe6';  // light green
    } else if (reportType === 'avv' && item?.status === 'pending') {
        return '#ffffcc';  // light yellow
    } else if (reportType === "property" && item?.avv?.status?.toLowerCase() === 'completed') {
        return '#e6ffe6';  // light green
    } else if (reportType === "property" && item?.avv?.status === 'pending') {
        return '#ffffcc';  // light yellow
    } else {
        return '#e6f7ff';
    }
}

const MyUsageList = ({ data, reportType }) => {
    const navigate = useNavigate();

    const handleItemClick = (reportType, item) => {
        if (reportType === "avv") {
            if (item?.status?.toLowerCase() === 'completed') {
                navigate(`/report/${encodeURIComponent(item?.search_string)}/address/${encodeURIComponent(item?.search_string.slice(-5))}`)
            } else {
                message.warning("AVV reports are not available at this time.");
            }
        } else {
            navigate(
                `/report/${encodeURIComponent(item?.report_address)}/${encodeURIComponent(item?.prop_type)}/${encodeURIComponent(item?.code)}`
            )
        }
    }

    return (
        <List
            grid={{
                gutter: isMobileOnly ? 16 : 24,
                column: isMobileOnly ? 1 : 4,
            }}
            dataSource={data}
            renderItem={(item) => (
                <List.Item>
                    <Card
                        hoverable
                        style={{
                            margin: '10px 0',
                            height: "150px",
                            borderRadius: '10px',
                            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
                            backgroundColor: getBackgroundColor(reportType, item), 
                            textAlign: "center", 
                            cursor: "pointer",
                        }}
                        onClick={() => handleItemClick(reportType, item)}
                    >
                        {reportType === "avv" && (
                            <>
                                <b>{item?.search_string?.split(",")[0]}</b>
                                <br />
                                {item?.search_string?.split(",")[1]}
                                <br />
                                <br />
                                <Tag color={getBackgroundColor(reportType, item)}>
                                    <span style={{ color: "black" }}>{item?.status}</span>
                                </Tag>
                            </>
                        )}
                        {reportType !== "avv" && (
                            <>
                                <b>{item?.report_address?.split(",")[0]}</b>
                                <br />
                                {item?.report_address?.split(",")[1]}
                                <br />
                                <br />
                                {reportType === "property" && (
                                    <Tag color={getBackgroundColor(reportType, item)}>
                                        <span style={{ color: "black" }}>{item?.avv ? `AVV Report: ${item?.avv?.status}` : "ValPro Report Completed"}</span>
                                    </Tag>
                                )}
                            </>
                        )}
                    </Card>
                </List.Item>
            )}
        />
    );
}

export default MyUsageList;
