import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import {
  Col,
  Row,
  Space,
  Menu,
  Button,
  message,
  Skeleton,
  Input,
  Modal,
  Dropdown,
} from "antd";
import { DownOutlined, CopyOutlined } from "@ant-design/icons";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { usePageTitle } from "./usePageTitle";
import "../../components/Report/Property/Property.css";
import "../../components/Report/Report.css";
import { ErrorBoundary } from "../shared";
import { USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";
import axiosInstance from "../../config/axios/axios.config";
import HamburgerMenu from "./HamburgerMenu";
import PageTabs from "./PageTabs";

function formatNumberWithCommas(x) {
  try {
    if (!x) return "-";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (err) {
    return "-";
  }
}
function PageTitle({
  handleValPalPdf,
  valPdfLoading,
  currentTab,
  setCurrentTab,
}) {
  const navigate = useNavigate();
  const params = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("tab");
  const pageTitle = params?.reportName.split(",");
  const [startDownloading, setStartDownloading] = useState(false);
  const [shareableUrl, setShareableUrl] = useState("");
  const inputRef = useRef(null);
  const filters = { ...params, queryParams: searchParams };
  const { data, isLoading, isError } = usePageTitle(filters, {
    enabled: params.code !== null,
  });
  const userDetails = JSON.parse(getItem(USER));
  const isAuthenticated = userDetails?.access_token && userDetails?.user_id;

  const handlepdfReportLinkClick = useCallback(
    (reportType) => {
      let downloadUrl = `/widgets/downloadreportpdf/${encodeURIComponent(
        params?.reportName
      )}/avm/${encodeURIComponent(params?.code)}/`;
      if (reportType === "excel") {
        downloadUrl = `/widgets/downloadreportpdf/${encodeURIComponent(
          params?.reportName
        )}/excel/${encodeURIComponent(params?.code)}/`;
      } else if (reportType === "avv") {
        downloadUrl = `/widgets/downloadreportpdf/${encodeURIComponent(
          params?.reportName
        )}/avv/${encodeURIComponent(params?.code)}/`;
      } else if (reportType === "valpal") {
        setStartDownloading(true);
        handleValPalPdf();
        setStartDownloading(false);
        return;
      }
      if (!isAuthenticated) {
        navigate("/");
      }
      setStartDownloading(true);
      console.log("downloadUrl", downloadUrl);

      axiosInstance
        .get(downloadUrl)
        .then((response) => {
          console.log("response", response);
          console.log("report name", response?.data?.report_name);
          console.log("url", response?.data?.url);
          const url = response?.data?.url;
          const a = document.createElement("a");
          a.href = url;
          let reportName = encodeURIComponent(response?.data?.report_name);
          a.download = `${reportName}`;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setStartDownloading(false);
        })
        .catch((error) => {
          console.log(error);
          message.error("Unable to download report. Please try again");
          setStartDownloading(false);
        });
    },
    [
      params?.reportName,
      params?.code,
      isAuthenticated,
      navigate,
      handleValPalPdf,
    ]
  );
  const metroName = useMemo(() => {
    let name = data?.metro_name;
    if (name?.includes("/")) {
      name = name?.replace("/", " ").trim();
    }
    if (name?.includes("#")) {
      name = name?.replace("#", " ").trim();
    }
    return name;
  }, [data?.metro_name]);

  let showMetroZipDownloadReport = false;

  const metroLink = useMemo(() => {
    if (data?.cbsa_code === 99999) {
      return null;
    }
    return isAuthenticated
      ? `/report/${encodeURIComponent(metroName)}/metro/${encodeURIComponent(
          data?.cbsa_code
        )}`
      : "/";
  }, [isAuthenticated, metroName, data?.cbsa_code]);

  const zipLink = useMemo(() => {
    return isAuthenticated
      ? `/report/${encodeURIComponent(
          params?.code
        )}/zip_code/${encodeURIComponent(params?.code)}`
      : "/";
  }, [isAuthenticated, params?.code]);

  const isValpal = currentTab === "valpal";

  const downloadMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="avm">
          <Button type="link" onClick={() => handlepdfReportLinkClick("avm")}>
            ValPro AVM PDF
          </Button>
        </Menu.Item>
        {data?.has_avv && (
          <Menu.Item key="avv">
            <Button type="link" onClick={() => handlepdfReportLinkClick("avv")}>
              ValPro AVV PDF
            </Button>
          </Menu.Item>
        )}
        {isValpal && (
          <Menu.Item key="valpal">
            <Button
              type="link"
              onClick={() => handlepdfReportLinkClick("valpal")}
            >
              ValPal PDF
            </Button>
          </Menu.Item>
        )}
        <Menu.Item key="excel">
          <Button type="link" onClick={() => handlepdfReportLinkClick("excel")}>
            Excel Eval
          </Button>
        </Menu.Item>
      </Menu>
    ),
    [data?.has_avv, handlepdfReportLinkClick, isValpal]
  );

  const generateShareableUrl = useCallback(async () => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      let reportName = encodeURIComponent(params?.reportName);
      let propType = encodeURIComponent(params?.propType);
      let code = encodeURIComponent(params?.code);
      try {
        const token = await axiosInstance.get("reports/share-report/token/");
        if (!token?.data?.token) {
          message.warn("Unable to generate shareable URL. Please try again");
          return;
        }
        let url = `${window.location.origin}/#/report/${reportName}/${propType}/${code}/${token?.data?.token}`;
        setShareableUrl(url);
        showModal();
      } catch (error) {
        message.error("Unable to generate shareable URL. Please try again");
        setShareableUrl("");
      }
    }
  }, [
    isAuthenticated,
    params?.reportName,
    params?.propType,
    params?.code,
    navigate,
  ]);

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(shareableUrl);
    message.info("Sharable URL copied to clipboard");
  };

  const handleShareClose = () => {
    setShareableUrl("");
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    handleShareClose();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select(); // Select the input field text
    }
  }, [shareableUrl]);

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="0">
          <Button
            type="primary"
            onClick={() => navigate("/national")}
            style={{ width: "100%", borderRadius: "4px" }}
          >
            National
          </Button>
        </Menu.Item>
        {metroLink !== null && params.propType !== "metro" && (
          <Menu.Item key="3">
            <Button
              type="primary"
              onClick={() => navigate(metroLink)}
              style={{ width: "100%", borderRadius: "4px" }}
            >
              Metro
            </Button>
          </Menu.Item>
        )}
        {zipLink !== null && params.propType === "address" && (
          <Menu.Item key="4">
            <Button
              onClick={() => navigate(zipLink)}
              style={{ width: "100%", borderRadius: "4px" }}
            >
              Zip
            </Button>
          </Menu.Item>
        )}
        <Menu.Item key="5">
          {showMetroZipDownloadReport &&
            (params?.propType === "metro" ||
              params?.propType === "zip_code") && (
              <Button
                type="primary"
                loading={startDownloading}
                style={{ width: "100%", borderRadius: "4px" }}
              >
                Download Report
              </Button>
            )}
          {params?.propType !== "metro" && params?.propType !== "zip_code" && (
            <Dropdown overlay={downloadMenu}>
              <Button
                type="primary"
                loading={startDownloading}
                style={{ width: "100%", borderRadius: "4px" }}
              >
                Download Report <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Menu.Item>
        <Menu.Item key="6">
          <Button
            onClick={generateShareableUrl}
            style={{ width: "100%", borderRadius: "4px" }}
          >
            Share Report
          </Button>
        </Menu.Item>
      </Menu>
    ),
    [
      metroLink,
      zipLink,
      downloadMenu,
      showMetroZipDownloadReport,
      startDownloading,
      generateShareableUrl,
      params?.propType,
      navigate,
    ]
  );

  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  if (!isLoading && !isError && (!data || data === null)) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  if (isLoading) {
    return (
      <Row className="page-title-container title-2">
        <Skeleton />
      </Row>
    );
  }

  return (
    <ErrorBoundary>
      <Row style={{ padding: "5px" }}>
        <Col
          lg={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 12 }}
          xs={{ span: 12 }}
          style={{ padding: "5px" }}
        >
          {params?.propType === "metro" ? (
            <div className="page-title-address-text">
              {!isError && pageTitle?.length < 2 && pageTitle[0]?.toUpperCase()}
              {!isError &&
                pageTitle?.length > 1 &&
                pageTitle[0]?.toUpperCase() + pageTitle[1]?.toUpperCase()}
            </div>
          ) : (
            <>
              <div className="page-title-address-text">
                {pageTitle[0] && pageTitle[0]?.toUpperCase()}
              </div>
              <div>
                {pageTitle[1] && pageTitle[1]?.toUpperCase()}
                {params?.propType === "zip_code" && "ZIP Code"}
              </div>
            </>
          )}
        </Col>

        <Col
          lg={{ span: 16 }}
          md={{ span: 16 }}
          sm={{ span: 12 }}
          xs={{ span: 12 }}
          style={{
            textAlign: "right",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingRight: "10px",
            paddingLeft: "5px",
          }}
        >
          <>
            <div className="menu-container">
              <div className="hamburger-menu">
                <HamburgerMenu menu={menu} />
              </div>
              <div className="regular-menu">
                <Row>
                  <Col span={24}>
                    <Space size={10}>
                      <Button
                        type="primary"
                        onClick={() => navigate("/national")}
                        style={{ borderRadius: "4px", width: "140px" }}
                      >
                        National
                      </Button>
                      {metroLink !== null && params.propType !== "metro" && (
                        <Button
                          type="primary"
                          onClick={() => navigate(metroLink)}
                          style={{ borderRadius: "4px", width: "140px" }}
                        >
                          Metro
                        </Button>
                      )}
                      {zipLink !== null && params.propType === "address" && (
                        <Button
                          onClick={() => navigate(zipLink)}
                          style={{ borderRadius: "4px", width: "140px" }}
                        >
                          Zip
                        </Button>
                      )}
                      {searchTerm !== "listings" && (
                        <>
                          {showMetroZipDownloadReport &&
                            (params?.propType === "metro" ||
                              params?.propType === "zip_code") && (
                              <Button type="primary" loading={startDownloading}>
                                Download Report
                              </Button>
                            )}
                          {params?.propType === "address" && (
                            <Dropdown overlay={downloadMenu}>
                              <Button type="primary" loading={startDownloading}>
                                Download Report <DownOutlined />
                              </Button>
                            </Dropdown>
                          )}
                          <Button
                            style={{ borderRadius: "4px", width: "140px" }}
                            onClick={generateShareableUrl}
                          >
                            Share Report
                          </Button>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        </Col>
        {params.propType === "address" && (
          <Col
            lg={{ span: 8 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="page-title-house-address">
              {data?.subject_house?.beds || "-"} bedrooms,{" "}
              {data?.subject_house?.baths || "-"} bathrooms,{" "}
              {data?.subject_house?.size
                ? formatNumberWithCommas(data?.subject_house?.size)
                : "-"}{" "}
              sq ft, {isMobileOnly ? <br /> : null}
              {data?.subject_house?.lot_size
                ? formatNumberWithCommas(data?.subject_house?.lot_size)
                : "-"}{" "}
              lot, Built in, {data?.subject_house?.age || "-"}, with{" "}
              {data?.subject_house?.has_pool ? "" : "no"} pool
            </div>
          </Col>
        )}
        <Modal
          title="Share Report"
          open={isModalVisible}
          onCancel={handleModalCancel}
          footer={null} // No default footer buttons
        >
          <Row justify="center" aligh="middle">
            <Col style={{ width: "80%" }}>
              <Input
                style={{ borderColor: "#41a8f7" }}
                value={shareableUrl}
                readOnly
                ref={inputRef}
              />
            </Col>
            <Col>
              <Button onClick={handleCopyButtonClick}>
                <CopyOutlined />
              </Button>
            </Col>
          </Row>
        </Modal>
      </Row>
      {params.propType === "address" && (
        <Row>
          <Col span={24}>
            <PageTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          </Col>
        </Row>
      )}
    </ErrorBoundary>
  );
}

export default PageTitle;
