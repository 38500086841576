import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Col, Row } from "antd";
import Vimeo from "@u-wave/react-vimeo";
import CustomCard from "../../blocks/Card/Card";
import { Loading, ErrorBoundary } from "../shared";
import { useVideo } from "./useVideo";


function VidoeWidget() {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
    ...(searchParams ? searchParams : {}),
    isWebsite: true
});
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useVideo(filters, { enabled: params.reportName !== null });

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    if (isLoading) {
        return <Loading title="Video Map" />
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }
    

    return (
        <ErrorBoundary>
            <CustomCard className="title-2 mb-2" title="Video Map">
                <Row type="flex">
                    <Col
                        lg={{ span: 22, offset: 1 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                    >
                        <Vimeo
                            video={data?.video}
                            controls={true}
                            responsive={true}
                            keyboard={true}
                            pip={true}
                        />
                    </Col>
                </Row>
            </CustomCard>
        </ErrorBoundary>
    );
}

export default VidoeWidget;
