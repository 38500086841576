
import React, { useEffect, useState } from "react";
import { isBrowser, isMobileOnly } from "react-device-detect";
import { Col, Form, Input, Row, Skeleton, Modal, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import "./Property/Property.css";
import "./Report.css";
import PageTitleUnAuthorized from "../../Widgets/page_title/PageTitleUnAuthorized";
import { USER } from "../../constants/defaultKeys";
import { getItem } from  "../../helpers/localStorage";
import { useParams } from "react-router-dom";
import axiosInstance from "../../config/axios/axios.config";

const OverLimit = () => {
  // State to control the visibility of the modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const userDetails = JSON.parse(getItem(USER));
  const params = useParams();

  const initialFormValues = {
    username: userDetails?.email || "",
    address: params?.reportName || "",
  }
  
  useEffect(() => {
    setIsModalVisible(true);
  }, []);


  const handleCancel = () => {
    setIsModalVisible(false);
    navigate("/search")
  };


  const handleFormSubmit = async (values) => {
    try {
      const response = await axiosInstance.post('widgets/upgrade_subscription/', values);
      setIsModalVisible(false);
      if (response.status !== 200) {
        message.error('An error occurred. Please try again');
        return;
      }
      message.success('Request submitted successfully');
      navigate("/dashboard"); 
    } catch (error) {
      message.error('An error occurred. Please try again');
      setIsModalVisible(false);
      navigate("/dashboard"); 
    }
  };


  return (
    <Row gutter={[16, 16]} className="blur-background">
      <Col className="page-title-row" span={24} style={{ position: 'sticky', top: 60, zIndex: 1000 }}>
        <div className="page-title">
          <PageTitleUnAuthorized />
        </div>
      </Col>

      <Col
        lg={{ span: 8 }}
        md={{ span: 8 }}
        sm={{ span: 8 }}
        xs={isMobileOnly && { span: 22, offset: 1 }}
      >

        <Skeleton active={false} />
      </Col>

      {/* WeissInsights */}
      <Col span={16} xs={isMobileOnly && { span: 22, offset: 1 }}>
        <Skeleton active={false} />
      </Col>

      {/* Summary */}
      <Col
        lg={isBrowser ? { span: 8 } : { span: 24 }}
        md={{ span: 8 }}
        sm={{ span: 8 }}
        xs={isMobileOnly && { span: 22, offset: 1 }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
        className="one-year-container"
      >
        <Skeleton active={false} />
      </Col>

      {/* Index Tools */}
      <Col
        lg={isBrowser ? { span: 16 } : { span: 24 }}
        xs={isMobileOnly && { span: 22, offset: 1 }}
      >
        <Skeleton active={false} />
      </Col>

      {/* ValPro */}
      <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
        <Skeleton active={false} />
      </Col>

      {/* Houses Near By */}
      <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
        <Skeleton active={false} />
      </Col>

      {/* Segmentation */}
      <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
        <Skeleton active={false} />
      </Col>
      <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
        <Skeleton active={false} />
      </Col>
      <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
        <Skeleton active={false} />
      </Col>

      <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
        <Skeleton active={false} />
      </Col>

      <Col span={24} xs={isMobileOnly && { span: 23, offset: 1 }}>
        <Skeleton active={false} />
      </Col>

      <Modal
        title="Limit Reached"
        open={isModalVisible}
        onCancel={handleCancel}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Go Back to Dashboard
          </Button>,
          <Button form="upgradeRequestForm" key="submit" type="primary" htmlType="submit">
            Request Upgrade
          </Button>,
        ]}
      >
        <p>You have reached your viewing limit. Please upgrade your plan for more access.</p>
        <Form 
          id="upgradeRequestForm" 
          layout="vertical" 
          onFinish={handleFormSubmit}
          initialValues={initialFormValues}
          >
          <Form.Item
            name="username"
            label="Username (Email)"
            rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: 'Please input your address!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default OverLimit;
