import { useEffect } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Card,
  Typography,
} from "antd";
import Logo from "../../../assets/images/logo.png";
import {
  USER,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "../../../constants/defaultKeys";
import { setItem, clear, getItem } from "../../../helpers/localStorage";
import { emailLogin } from "../../../services/auth.services";
import "./Login.css";

const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(getItem(USER));
  const accessToken = getItem(ACCESS_TOKEN);
  const refreshToken = getItem(REFRESH_TOKEN);

  if (accessToken === null || refreshToken === null) {
    clear();
  }

  const onSuccess = (data) => {
    clear();
    setItem(ACCESS_TOKEN, data?.data?.payload?.access_token);
    setItem(REFRESH_TOKEN, data?.data?.payload?.refresh_token);
    setItem(USER, JSON.stringify(data?.data?.payload));

    let redirectedHash = window?.location?.hash;
    let redirectUrl = null;
    if (redirectedHash) {
      // hash #/?redirect=/report/19%20ALISON%20ct,%20MARLBORO%20NJ%2007746/address/07746
      let hash = redirectedHash.split("#/?redirect=");
      if (hash && hash.length > 1) {
        redirectUrl = hash[1];
      }
    }

    navigate(redirectUrl || "/search");
  };

  useEffect(() => {
    if (userData && accessToken && refreshToken) {
      navigate({ pathname: "/search" });
    } else {
      clear();
    }
  }, [userData, navigate, accessToken, refreshToken]);

  const onError = (error) => {
    message.error(error.response.data.message);
    clear();
  };

  const { mutate, isLoading } = useMutation(emailLogin, {
    onError,
    onSuccess,
  });

  const onFinish = (values) => {
    clear();
    mutate(values);
  };

  let showForgotPassword = false;
  return (
    <Row type="flex" justify="center" align="middle" className="login">
      <Col
        lg={{ span: 8, offset: 8 }}
        md={{ span: 12, offset: 6 }}
        sm={{ span: 20, offset: 2 }}
        xs={{ span: 20, offset: 2 }}
        style={{ marginLeft: 0 }}
      >
        <Card
          bordered={false}
          style={{ padding: "20px 20px 10px 20px", borderRadius: "6px" }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={Logo} alt="logo" style={{ width: "250px", height: "auto" }} />

            <Title level={4} type="secondary" style={{ marginTop: "25px" }}>
              Subscriber Login
            </Title>
          </div>
          <Form name="login" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input
                autoFocus={true}
                size="large"
                // prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
            >
              <Input.Password
                size="large"
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {!showForgotPassword && (<div style={{ textAlign: "right", paddingBottom: "10px" }}>
              <Link to="/forgot-password" className="footer-links">
                Forgot password?
              </Link>
            </div>)
            }

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                className="login-form-button"
                loading={isLoading}
              >
                Log In
              </Button>
            </Form.Item>
          </Form>
          <div> 
            Don't have an account?
            <Link to="/signup" className="footer-links" style={{marginLeft: "4px"}}>
              Sign Up
            </Link>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
