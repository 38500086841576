import React, { Fragment, useState } from "react";
import { Button, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import DropIn from "braintree-web-drop-in-react";
import {
  getPaymentToken,
  capturePayment,
} from "../../../services/payment.services";
import { openNotificationWithIcon } from "../../../helpers/utility";
import { getItem, setItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";

const Payment = ({ selectedPlan }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(getItem(USER));

  const [instance, setInstance] = useState(null);

  const onError = (error) => {
    openNotificationWithIcon("error", error.response.data.message);
  };

  const { data: token, isLoading: getTokenLoading } = useQuery(
    "get-token",
    () => getPaymentToken(),
    { onError }
  );

  const onSuccess = (response) => {
    openNotificationWithIcon("success", response.data.message);
    navigate({ pathname: "/search" });
    setItem(USER, JSON.stringify({ ...userData, subscription: true }));
  };

  const { mutate, isLoading: onSubmitLoading } = useMutation(capturePayment, {
    onError,
    onSuccess,
  });

  const buy = async () => {
    const { nonce } = await instance.requestPaymentMethod();

    await mutate({
      paymentMethodNonce: nonce,
      plan_id: selectedPlan?.id,
      amount: selectedPlan?.amount,
    });
  };

  return (
    <Fragment>
      {getTokenLoading || onSubmitLoading ? (
        <Skeleton active />
      ) : (
        <>
          <DropIn
            options={{ authorization: token?.data?.token?.[1] }}
            onInstance={(ins) => setInstance(ins)}
          />
          <div style={{ textAlign: "right" }}>
            <Button type="primary" onClick={buy} style={{ marginTop: "10px" }}>
              Proceed to checkout
            </Button>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Payment;
