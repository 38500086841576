import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { BulbTwoTone } from "@ant-design/icons";
import { Col, Progress, Row, Skeleton } from "antd";
import CustomCard from "../../blocks/Card/Card";
import { useWeissInsights } from "./useWeissInsights";
import { ErrorBoundary } from "../shared";
import { weissInsightsInfo } from "../../constants/defaultKeys";
import { showInfo } from "../shared";
import { isMobileOnly } from "react-device-detect";


function WeissInsights() {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(213);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useWeissInsights(filters, { enabled: params.reportName !== null });

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 25;
                setElementHeight(newHeight);
                window.parent.postMessage({ weissInsightsHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);

    if (isLoading) {
        return <Skeleton />
    }
    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }


    return (
        <ErrorBoundary>
            <CustomCard
                title={
                    (
                        <>
                            <span className="mobile-title-font">Weiss Insights</span>
                            {!showInfo(searchParams?.get("showInfo")) && <span>{weissInsightsInfo()}</span>}
                        </>
                    )
                }
                ref={elementRef}
                className="sidebar-small-card title-2"
            >
                {showInfo(searchParams?.get("showInfo")) && (<Row gutter={isMobileOnly ? [12, 12] : [36, 36]}>
                    <Col span={24}>
                        <p>
                        Weiss Ranking is a relative score comparing the current property relative to 
                        properties in the same ZIP Code. The score ranges between 1 to 10 and the 
                        higher the score, the better the property is relative to other properties 
                        in the same ZIP Code. This score is based on near-term historical performance 
                        and Weiss forecast.
                        </p>
                    </Col>
                </Row>
                )}
                <Row gutter={isMobileOnly ? [12, 12] : [36, 36]}>
                    <>
                        <Col
                            xl={{ span: 8 }}
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            style={{ textAlign: "center" }}
                        >
                            <Progress
                                format={(percent) => (
                                    <>
                                        <small style={{ display: "block", fontSize: "10px" }}>
                                            Weiss Rank
                                        </small>
                                        <small>
                                            {" "}
                                            {data?.houseRanking
                                                ? data?.houseRanking
                                                : "-"}
                                        </small>
                                    </>
                                )}
                                strokeWidth={14}
                                width={90}
                                type="dashboard"
                                strokeColor={{
                                    "0%": "#108ee9",
                                    "100%": "#87d068",
                                }}
                                percent={
                                    data?.houseRanking
                                        ? data?.houseRanking * 10
                                        : 0
                                }
                                status="active"
                            />
                        </Col>
                        <Col
                            xl={{ span: 8 }}
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            className="weiessInsights-block"
                        >
                            <BulbTwoTone
                                twoToneColor="#52c41a"
                                style={{ fontSize: "18px", marginRight: "5px" }}
                            />
                            <span>
                                This home rose {" "} {`${data?.oneYearChange}% `}
                                in the past year which is better than {" "} {`${data?.oneYearRank}% `}
                                of homes in ZIP Code {params?.code}.
                            </span>
                        </Col>
                        <Col
                            xl={{ span: 8 }}
                            lg={{ span: 8 }}
                            md={{ span: 8 }}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            className="weiessInsights-block"
                        >
                            <BulbTwoTone
                                twoToneColor="#52c41a"
                                style={{ fontSize: "18px", marginRight: "5px" }}
                            />
                            <span>
                                This home is forecasted to rise {" "}
                                {`${data?.forecast}% `} next year which is better than {" "}
                                {`${data?.forecastRank}% `} of homes in ZIP Code {params?.code}.
                            </span>
                        </Col>
                    </>
                </Row>
            </CustomCard>
        </ErrorBoundary>
    );
}

export default WeissInsights;
