import { Fragment, useContext } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Col, Layout, Row, Space, Divider } from "antd";
import Toolbar from "../components/Toolbar/Toolbar";
import { ThemeContext } from "../context/ThemeContext";
import "./Layout.css";

const { Header, Content, Footer } = Layout;

function DefaultLayout({ children }) {
  const { font } = useContext(ThemeContext);
  const currentYear = new Date().getFullYear();

  return (
    <Fragment>
      <Layout className="layout" style={{ fontFamily: font.fontFamily }}>
        <Header className="header">
          <Toolbar />
        </Header>
        <Content className="layout-body">{children}</Content>
        {!isMobileOnly && !isTablet && (
          <Footer className="footer">
            <Row>
              <Col span={10}>
              Copyright © {currentYear} WeissAnalytics. All Rights Reserved
              </Col>
              <Col span={14}>
                <Space split={<Divider type="vertical" />}>
                  <a
                    href="https://www.weissanalytics.com/about-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About us
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.weissanalytics.com/privacy-policy"
                  >
                    Privacy
                  </a>
                  <a
                    href="https://www.weissanalytics.com/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </Space>
              </Col>
            </Row>
          </Footer>
        )}
      </Layout>
    </Fragment>
  );
}

export default DefaultLayout;
