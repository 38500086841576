import axiosInstance from "../../config/axios/axios.config";

export async function fetchSummaryData(filters) {
    const {code, reportName, propType, queryParams, token} = filters;
    if (token) {
      queryParams.append("token", token)
    }
    let queryString = "";
    if (queryParams) {
      queryString = queryParams.toString()
    }
    let url = `widgets/${encodeURIComponent(reportName)}/${encodeURIComponent(propType)}/${encodeURIComponent(code)}/summary/?${queryString}`
    try {
        const response = await axiosInstance.get(url)
      return response.data;
    } catch (error) {
      if (error?.flag === "WidgetError") {
        return null;
      }
      throw new Error(`Failed fetch data. fetchSummaryData with error: ${error}`);
    }
  }

