import Summary from "./summary";
import HouseMeasures from "./houseMeasures";
import IndexTools from "./indextools";
import WeissInsights from "./weisinsights";
import HousesNearBy from "./housesNearBy";
import Segmentation from "./segmentation";
import ValPro from "./valpro";
import PageTitle from "./page_title";
import BubbleWatch from "./bubbleWatch";
import Listings from "./listings";
import DynamicMap from "./dynamicMap";
import BirdsEyeView from "./birdsEyeView";
import DynamicZipcodeMap from "./dynamicZipcodeMap";
import BestWorstSegment from "./worstSegment";
import FixFlip from "./fixflip";
import VideoWidget from "./video";
import ValProCard from "./val_pro_card";
import CompsWidget from "./comps/CompsWidget";
import Widgets from "./Widgets";
import ConditionScore from "./conditionScore";
import GroupedImages from "./groupedImages";
import HousesNearByHeatMap from "./heatpmap";

const WIDGET_NAMES = {
  SUMMARY: "summary",
  HOUSE_MEASURES: "housemeasures",
  INDEX_TOOLS: "indextool",
  WEISS_INSIGHTS: "weissinsights",
  HOUSES_NEARBY: "housenearby",
  SEGMENTATION: "segmentationreport",
  VAL_PRO: "valpro",
  PAGE_TITLE: "pagetitle",
  BUBBLE_WATCH: "bubblewatch",
  LISTINGS: "listscan",
  DYNAMIC_MAP: "dynamicmetromap",
  BIRDS_EYE_VIEW: "birdseyeview",
  DYNAMIC_ZIPCODE_MAP: "dynamicmap",
  BEST_WORST_SEGMENT: "bestworstsegment",
  FIX_FLIP: "fixflip",
  VIDEO: "video",
  BEST_SEGMENT: "bestsegment",
  WORST_SEGMENT: "worstsegment",
  VAL_PRO_CARD: "valprocard",
  COMPS: "comps",
  CONDITION_SCORE: "conditionscore",
  GROUPED_IMAGES: "groupedimages",
  HOUSES_NEARBY_HEATMAP: "housesnearbyheatmap",
};

export {
  Summary,
  HouseMeasures,
  IndexTools,
  WeissInsights,
  HousesNearBy,
  Segmentation,
  ValPro,
  PageTitle,
  BubbleWatch,
  Listings,
  DynamicMap,
  BirdsEyeView,
  DynamicZipcodeMap,
  BestWorstSegment,
  FixFlip,
  VideoWidget,
  Widgets,
  ValProCard,
  CompsWidget,
  ConditionScore,
  GroupedImages,
  HousesNearByHeatMap,
  WIDGET_NAMES,
};
