import { Tooltip } from "antd";
import CircleImage from "../../../assets/images/circle.png";
import { EnvironmentOutlined, EnvironmentFilled } from "@ant-design/icons";
import mapMarker from "../../../assets/images/marker.png";

function Marker({ data, curHouse, setValData, valData }) {
  const onClick = (val) => {
    let beingChecked = valData.filter(item => item.WRRID === val.WRRID)
    let newData = [...valData].filter(item => item.WRRID !== val.WRRID)
    if (beingChecked?.length > 0) {
      beingChecked[0].contributor = beingChecked[0].contributor === 1 ? 0 : 1
      setValData([...newData, beingChecked[0]])
    }
  };

  return (
    <div className="marker">
      {!curHouse && !data?.customComp ? (
        <div onClick={() => onClick(data)}>
          <Tooltip
            placement="top"
            title={<span>{data.address}</span>}
            trigger={["hover"]}
          >
            {data?.contributor ? (
              <EnvironmentFilled
                style={{ fontSize: "25px", color: "#89AF37" }}
              />
            ) : (
              <EnvironmentOutlined
                style={{ fontSize: "25px", color: "#595959" }}
              />
            )}
          </Tooltip>
        </div>
      ) : data?.customComp ? (
        <img src={CircleImage} height="30px" alt="circle" />
      ) : (
        <img src={mapMarker} alt="mapMarker" style={{ fontSize: "35px", color: "#FA8072" }} />
      )}
    </div>
  );
}

export default Marker;
