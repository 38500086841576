import { useEffect } from "react";
import axiosInstance from "../config/axios/axios.config";

const VersionChecker = () => {
  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const response = await axiosInstance.get(
          "/accounts/frontend_app_version/"
        );
        const currentVersion = response.data.version;
        const storedVersion = localStorage.getItem("appVersion");
        if (storedVersion && storedVersion !== currentVersion) {
          localStorage.setItem("appVersion", currentVersion);
          localStorage.clear();
          window.location.reload();
        } else {
          localStorage.setItem("appVersion", currentVersion);
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    };

    checkForUpdates();
    const intervalId = setInterval(checkForUpdates, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return null;
};

export default VersionChecker;
