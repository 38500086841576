import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Skeleton, Empty } from "antd";
import Metro from "./Metro/Metro";
import Property from "./Property/Property";
import ZipCodes from "./ZipCodes/ZipCodes";
import axiosInstance from "../../config/axios/axios.config";
import { useMyUsage } from "../Dashboard/MyUsage/useMyUsage";

import "./Report.css";

function Report() {
  const { propType, token } = useParams();
  const [validToken, setValidToken] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const { data: userUsageData, isLoading: userUsageDataLoading, isError: useUsageDataError } = useMyUsage();

  const validateToken = useCallback(() => {
    setTokenLoading(true);
    axiosInstance
      .get(`reports/share-report/validate-token/?token=${token}`)
      .then((res) => {
        setValidToken(res?.data?.status);
        setTokenLoading(false);
      })
      .catch((err) => {
        console.error("Error validating token:", err);
        setValidToken(false);
        setTokenLoading(false);
      });
  }, [token]);

  useEffect(() => {
    if (token) {
      validateToken();
    }
  }, [token, validateToken]);

  if (tokenLoading || (!token && userUsageDataLoading)) {
    return <Skeleton />;
  }

  if (token && !validToken) {
    return <Empty description="Invalid Token" />;
  }

  if (!token && !userUsageDataLoading && (useUsageDataError || !userUsageData)) {
    const description = useUsageDataError ? "Something went wrong" : "No data found";
    return <Empty description={description} />;
  }

  const componentMap = {
    zip_code: <ZipCodes />,
    metro: <Metro />,
    default: <Property />
  };

  return (
    <Row className="report-container">
      <Col lg={{ span: 20, offset: 2 }} md={{ span: 22, offset: 1 }}>
        {componentMap[propType] || componentMap.default}
      </Col>
    </Row>
  );
}

export default Report;
