import { useState, useEffect } from "react";
import {
  Modal,
  Form,
  InputNumber,
  Button,
  Skeleton,
  Row,
  Col,
  Card,
  Typography,
} from "antd";
import axiosInstance from "../../../config/axios/axios.config";
import { formatDate } from "../../../helpers/utility";
import { isMobileOnly } from "react-device-detect";

function formatNumberWithCommas(x) {
  try {
    if (!x) return "-";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (err) {
    return "-";
  }
}

const MAPPING = {
  age: {
    coeff: 250,
    key: "adj_year_built",
  },
  size: {
    coeff: 15,
    key: "adj_gla_size",
  },
  Lot: {
    coeff: 5,
    key: "adj_lot_size",
  },
  beds: {
    coeff: 5000,
    key: "adj_beds",
  },
  baths: {
    coeff: 5000,
    key: "adj_baths",
  },
  poolcode: {
    coeff: 20000,
    key: "adj_pool",
  },
};

function applyCoefficient(objectToUpdate, obj, subject, key) {
  let diff = 0;
  if (key === "poolcode") {
    let hasSubjectPool = [2, 4, 5, 6, 7, 8, 10, 12].includes(subject[key] || 0);
    let hasObjPool = [2, 4, 5, 6, 7, 8, 10, 12].includes(
      obj[MAPPING[key].key] || 0
    );
    if (!hasSubjectPool && !hasObjPool) {
      return diff;
    } else if (hasSubjectPool && hasObjPool) {
      return diff;
    } else {
      diff = ((subject[key] ? 1 : 0) - (obj[key] ? 1 : 0)) * MAPPING[key].coeff;
      obj[MAPPING[key].key] = diff;
    }
  } else {
    diff = (subject[key] - obj[key]) * MAPPING[key].coeff;
    obj[MAPPING[key].key] = diff;
  }
  objectToUpdate[MAPPING[key].key] = obj[MAPPING[key].key];
  return diff;
}

const { Text } = Typography;
const CompFormModal = ({
  isVisible,
  setIsVisible,
  editList,
  setValData,
  data,
  subject_comp,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [totalDiff, setTotalDiff] = useState(0);

  useEffect(() => {
    if (editList) {
      let editListCopy = {
        ...editList,
      };

      let total = 0;
      total += applyCoefficient(editList, editListCopy, subject_comp, "age");
      total += applyCoefficient(editList, editListCopy, subject_comp, "size");
      total += applyCoefficient(editList, editListCopy, subject_comp, "Lot");
      total += applyCoefficient(editList, editListCopy, subject_comp, "beds");
      total += applyCoefficient(editList, editListCopy, subject_comp, "baths");
      total += applyCoefficient(
        editList,
        editListCopy,
        subject_comp,
        "poolcode"
      );
      form.setFieldsValue(editListCopy);
      setTotalDiff(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array

  const handleCancel = () => {
    setIsVisible(false);
  };

  const onFinish = async (values) => {
    let total =
      (parseFloat(values?.adj_baths) || 0) +
      (parseFloat(values?.adj_beds) || 0) +
      (parseFloat(values?.adj_gla_size) || 0) +
      (parseFloat(values?.adj_lot_size) || 0) +
      (parseFloat(values?.adj_pool) || 0) +
      (parseFloat(values?.adj_year_built) || 0);
    let updatedHouse = {
      ...data.filter((item) => item.WRRID === editList.WRRID)[0],
    };

    if (total !== totalDiff) {
      if (
        updatedHouse?.implyingValue &&
        updatedHouse?.implyingValue !== null &&
        updatedHouse?.implyingValue !== undefined &&
        updatedHouse?.implyingValue !== "" &&
        !isNaN(updatedHouse?.implyingValue) &&
        updatedHouse?.implyingValue !== 0
      ) {
        updatedHouse["implyingValue"] =
          updatedHouse["implyingValue"] + (total - totalDiff);
      }
    }

    let subjectId = updatedHouse?.WRRID;
    let address = `${updatedHouse?.address}, ${updatedHouse?.city} ${updatedHouse?.state} ${updatedHouse?.zipcode}`;
    let saleAmount = values.TransferValue;
    let saleDate = updatedHouse?.TransferDate;
    let zipcode = updatedHouse?.zipcode;
    try {
      setLoading(true);
      let url = `widgets/${address}/address/${zipcode}/usercomp/?full_address=${address}&sale_amount=${saleAmount}&sale_date=${saleDate}&subject_id=${subjectId}`;
      let allHousesWithoutBeingUpdated = data.filter(
        (item) => item.WRRID !== editList.WRRID
      );
      if (values?.TransferValue !== updatedHouse?.TransferValue) {
        let updatedHouseFromApi = await axiosInstance.get(url);
        if (updatedHouseFromApi) {
          let weight = values?.weight / 100 || 0;
          setValData([
            ...allHousesWithoutBeingUpdated,
            {
              ...updatedHouseFromApi?.data,
              contributor: updatedHouse?.contributor,
              weight: weight,
            },
          ]);
        }
      } else {
        setValData([
          ...allHousesWithoutBeingUpdated,
          {
            ...updatedHouse,
            contributor: updatedHouse?.contributor,
            weight:
              values?.weight !== updatedHouse?.weight
                ? values?.weight / 100 || 0
                : updatedHouse?.weight,
          },
        ]);
      }
      setLoading(false);
      setIsVisible(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setIsVisible(false);
    }
  };

  const TotalDiff = (value) => {
    return (
      <div style={{ width: isMobileOnly ? "" : "400px" }}>
        <Text>Net Adjustment(s) Total: ${value}</Text>
      </div>
    );
  };
  return (
    <Modal
      title={editList.address}
      footer={false}
      onCancel={handleCancel}
      open={isVisible}
      width={800}
    >
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          {!loading ? (
            <Form
              name="Add Comp"
              layout="vertical"
              onFinish={onFinish}
              form={form}
              style={{ display: "flex", width: "100%" }}
            >
              <Row gutter={[0, 4]} style={{ width: "100%" }}>
                <Col span={12}>
                  <Card title="Edit Comp">
                    <Form.Item
                      label={`Last Sale | ${formatDate(
                        editList?.TransferDate
                      )}`}
                      name="TransferValue"
                    >
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item label="SIMILARITY" name="weight">
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) =>
                          `${
                            value && value <= 1
                              ? Math.floor(value * 100)
                              : value
                          }%`
                        }
                        parser={(value) => value.replace("%", "")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item label="BEDS" name="beds">
                      <InputNumber disabled style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item label="BATHS" name="baths">
                      <InputNumber disabled style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item label="SQFT" name="size">
                      <InputNumber disabled style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item label="Year Built" name="age">
                      <InputNumber disabled style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item label="DISTANCE" name="distance">
                      <InputNumber disabled style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item label="WRRID" name="WRRID" hidden>
                      <InputNumber disabled style={{ width: "100%" }} />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Value Adjustments">
                    <Form.Item label="Year Built" name="adj_year_built">
                      <InputNumber
                        formatter={(value) =>
                          `${
                            value && value !== null
                              ? "$ " + formatNumberWithCommas(value)
                              : ""
                          }`
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item label="GLA Size" name="adj_gla_size">
                      <InputNumber
                        formatter={(value) =>
                          `${
                            value && value !== null
                              ? "$ " + formatNumberWithCommas(value)
                              : ""
                          }`
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item label="Lot Size" name="adj_lot_size">
                      <InputNumber
                        formatter={(value) =>
                          `${
                            value && value !== null
                              ? "$ " + formatNumberWithCommas(value)
                              : ""
                          }`
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item label="BEDS" name="adj_beds">
                      <InputNumber
                        formatter={(value) =>
                          `${
                            value && value !== null
                              ? "$ " + formatNumberWithCommas(value)
                              : ""
                          }`
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item label="BATHS" name="adj_baths">
                      <InputNumber
                        formatter={(value) =>
                          `${
                            value && value !== null
                              ? "$ " + formatNumberWithCommas(value)
                              : ""
                          }`
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item label="Pool" name="adj_pool">
                      <InputNumber
                        formatter={(value) =>
                          `${
                            value && value !== null
                              ? "$ " + formatNumberWithCommas(value)
                              : ""
                          }`
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    {isMobileOnly && (
                      <Text>
                        Net Adjustment(s) Total: $
                        {formatNumberWithCommas(totalDiff)}
                      </Text>
                    )}
                    {!isMobileOnly && (
                      <Form.Item
                        label={TotalDiff(formatNumberWithCommas(totalDiff))}
                      ></Form.Item>
                    )}
                  </Card>
                </Col>
              </Row>
            </Form>
          ) : (
            <Skeleton />
          )}
        </Col>
        <Col
          span={24}
          style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
        >
          <Button
            onClick={handleCancel}
            style={{ width: "150px" }}
            type="danger"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            style={{ width: "150px" }}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CompFormModal;
