import { Fragment, useRef, useState, useEffect, useMemo } from "react";
import { Tag, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  Tooltip,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import CustomCard from "../../blocks/Card/Card";
import ComparisionModal from "./ComparisionModal";
import PropRisingModal from "./PropRisingModal";
import { indexOverTheYearInfo, colors } from "../../constants/defaultKeys";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useIndexTools } from "./useIndexTools";
import AllButtons from "./buttons";
import { Loading, ErrorBoundary } from "../shared";
import { isMobileOnly } from "react-device-detect";
import "./indextools.css";
import { showInfo } from "../shared";

function IndexTools() {
  const [elementHeight, setElementHeight] = useState(662);
  const elementRef = useRef(null);
  const params = useParams();
  const location = useLocation();
  const searchParams = useMemo(() => {
    let params = new URLSearchParams(location.search);
    if (location?.pathname?.includes("report")) {
      params = new URLSearchParams({
        ...(params ? params : {}),
        isWebsite: true,
      });
    }
    return params;
  }, [location]);

  const [allData, setAllData] = useState(null);
  const [isCompareModalVisible, setCompareModalVisible] = useState(false);
  const [isPropertyRisingModal, setPropertyRisingModalVisible] =
    useState(false);
  const [selectedBtn, setSelectedBtn] = useState("max");
  const filters = useMemo(
    () => ({ ...params, queryParams: searchParams }),
    [params, searchParams]
  );
  const { data, isLoading, isError } = useIndexTools(filters, {
    enabled: params.reportName !== null,
  });
  const [chartData, setChartData] = useState(data);
  const [unselectedKeys, setUnselectedKeys] = useState([]);

  useEffect(() => {
    if (data) {
      setAllData(data?.indices);
      setChartData(data?.indices);
      setUnselectedKeys(data?.unselected_keys || [])
    }
  }, [data, allData, setAllData, setChartData, setUnselectedKeys]);

  useEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        const newHeight = elementRef.current.clientHeight + 74;
        setElementHeight(newHeight);
        window.parent.postMessage({ indexToolsHeight: newHeight }, "*");
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [data, elementHeight, elementRef]);

  const mobileCss = useMemo(
    () => ({
      borderRadius: "6px",
      border: "1px solid #f0f0f0",
      backgroundColor: "#fff",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      margin: "4px",
      paddingTop: "36px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "12px",
      width: "98.5%",
    }),
    []
  );

  const titleCss = useMemo(
    () => ({
      direction: window.innerWidth < 768 ? "column" : "row",
      align: window.innerWidth < 768 ? "start" : "center",
    }),
    []
  );

  let toolTipPosition =
    elementRef?.current?.querySelector(".recharts-wrapper")?.clientWidth ||
    document.getElementById("indexTools")?.clientWidth;
  toolTipPosition = toolTipPosition
    ? toolTipPosition - 20
    : parseInt(window.innerWidth * 0.72);

  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  if (isLoading) {
    return <Loading title="Index Over the Years" />;
  }

  if (data?.indices?.length === 0) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  if (!isLoading && !isError && (!data || data === null)) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }
  const changeGraphData = (e) => {
    setSelectedBtn(e.target.value);
    const tenYrLength = allData.length - 120;
    const yearLength = allData.length - 12;
    const fiveYrLength = allData.length - 60;

    switch (e.target.value) {
      case "1yr":
        setChartData(allData.slice(yearLength));
        break;
      case "5yr":
        setChartData(allData.slice(fiveYrLength));
        break;
      case "10yr":
        setChartData(allData.slice(tenYrLength));
        break;
      default:
        setChartData(allData);
    }
  };

  function removeChartData(value) {
    if (unselectedKeys?.includes(value)) {
      setUnselectedKeys(unselectedKeys?.filter((item) => item !== value));
    } else {
      setUnselectedKeys([...unselectedKeys, value]);
    }
  }

  function openPropertyModal() {
    setPropertyRisingModalVisible(true);
  }

  const renderTooltipWithLabel = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            fontSize: "14px",
            width: "180px",
            height: "auto",
            background: "#232339",
            color: "white",
            padding: "10px",
          }}
        >
          <span style={{ fontSize: "12px" }}>Year: {`${label}`}</span>
          <br />
          {Object.keys(payload[0]?.payload).map((key, index) => {
            return payload[0]?.payload[key] !== undefined &&
              key !== "xAxis" &&
              !unselectedKeys?.includes(key) ? (
              <Fragment key={index}>
                <span style={{ fontSize: "12px" }}>
                  {key.replace("_", " ")}: {payload[0].payload[key]}
                </span>
                <br />
              </Fragment>
            ) : null;
          })}
        </div>
      );
    }
    return null;
  };

  function openComparisonModal() {
    setCompareModalVisible(true);
  }

  return (
    <ErrorBoundary>
      <div
        style={{ overflow: "hidden", marginRight: isMobileOnly ? "3%" : "0" }}
      >
        {!isMobileOnly && (
          <CustomCard
            ref={elementRef}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: titleCss?.align,
                  flexDirection: titleCss?.direction,
                }}
              >
                <div>
                  {
                    <span className="mobile-title-font">
                      Index Over the Years
                    </span>
                  }
                  {!showInfo(searchParams?.get("showInfo")) && (
                    <span>{indexOverTheYearInfo()}</span>
                  )}
                </div>
                <div>
                  {
                    <AllButtons
                      openComparisonModal={openComparisonModal}
                      openPropertyModal={openPropertyModal}
                      changeGraphData={changeGraphData}
                      selectedBtn={selectedBtn}
                    />
                  }
                </div>
              </div>
            }
            className="title-2"
          >
            {showInfo(searchParams?.get("showInfo")) && (
              <Row
                style={{ overflow: "hidden" }}
                gutter={isMobileOnly ? [12, 12] : [36, 36]}
              >
                <Col span={24}>
                  <p>
                    Below is a line graph of the Weiss Indexes for this Metro
                    (MSA).A Weiss Index tracks the movement of home values for
                    properties, ZIPs, or Metros (MSA) over time due to market
                    conditions (meaning that changes in the condition of the
                    properties, ZIPs, or Metros themselves will not be reflected
                    in the index value). The index value at any given time is
                    NOT the average home value of the Metro but instead is the
                    relative value of the Metro compared to the index value of
                    that Metro at another point in time. For example, if the
                    index value of a Metro was 150 in January 2010 and the index
                    value of the Metro was 100 in January 2005, then that would
                    mean the Metro's average home value was worth 1.5 times as
                    much in January 2010 than in January 2005 or 50% more.
                  </p>
                </Col>
              </Row>
            )}
            <ResponsiveContainer
              width="90%"
              minHeight={null || 400}
              id="indexTools"
            >
              <LineChart
                data={chartData}
                margin={{
                  top: 5,
                  right: isMobileOnly ? 0 : 30,
                  left: isMobileOnly ? -35 : 20,
                  bottom: 15,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="xAxis"
                  textAnchor="middle"
                  tick={{ fontSize: 11 }}
                ></XAxis>
                <YAxis tick={{ fontSize: isMobileOnly ? 11 : 12 }}></YAxis>

                <Tooltip
                  position={{ x: toolTipPosition - 20, y: 0 }}
                  content={renderTooltipWithLabel}
                />
                {allData &&
                  Object.keys(allData[0])?.map((key, index) => {
                    return allData[0][key] !== undefined && key !== "xAxis" ? (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={key}
                        stroke={
                          unselectedKeys?.includes(key)
                            ? "transparent"
                            : colors[index]
                        }
                        dot={false}
                        strokeWidth={2}
                      />
                    ) : (
                      ""
                    );
                  })}
                <Legend
                  wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    marginLeft: isMobileOnly ? "10%" : "0px",
                    paddingTop: "20px",
                  }}
                  layout="horizontal"
                  formatter={(value, index) => {
                    const truncatedValue =
                      isMobileOnly && value.length > 12
                        ? value.substring(0, 10) + "..."
                        : value;

                    return (
                      <Tag
                        color={
                          unselectedKeys?.includes(value)
                            ? "#bcbcbc"
                            : "geekblue"
                        }
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontSize: isMobileOnly ? "9px" : "12px",
                        }}
                        size="small"
                        className="text-color-class"
                        onClick={() => removeChartData(value)}
                        title={value}
                      >
                        {truncatedValue.replace("_", " ")}
                        {unselectedKeys?.includes(value) && !isMobileOnly && (
                          <PlusOutlined style={{ fontSize: "8px" }} />
                        )}
                      </Tag>
                    );
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </CustomCard>
        )}
        {isMobileOnly && (
          <>
            <div ref={elementRef} style={mobileCss}>
              <div style={{ padding: "2px 12px 24px 12px" }}>
                <span className="mobile-title-font">Index Over the Years</span>
                <span>{indexOverTheYearInfo()}</span>
              </div>
              <div style={{ padding: "2px 12px 24px 12px" }}>
                <AllButtons
                  openComparisonModal={openComparisonModal}
                  openPropertyModal={openPropertyModal}
                  changeGraphData={changeGraphData}
                  selectedBtn={selectedBtn}
                />
              </div>
              <ResponsiveContainer width="100%" minHeight={null || 400}>
                <LineChart
                  data={chartData}
                  margin={{ top: 5, right: 0, left: -35, bottom: 15 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="xAxis"
                    textAnchor="middle"
                    tick={{ fontSize: 11 }}
                  ></XAxis>
                  <YAxis tick={{ fontSize: isMobileOnly ? 11 : 12 }}></YAxis>

                  <Tooltip content={renderTooltipWithLabel} />
                  {allData &&
                    Object.keys(allData[0])?.map((key, index) => {
                      return allData[0][key] !== undefined &&
                        key !== "xAxis" ? (
                        <Line
                          key={index}
                          type="monotone"
                          dataKey={key}
                          stroke={
                            unselectedKeys?.includes(key)
                              ? "transparent"
                              : colors[index]
                          }
                          dot={false}
                          strokeWidth={2}
                        />
                      ) : (
                        ""
                      );
                    })}
                  <Legend
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      marginLeft: isMobileOnly ? "10%" : "0px",
                      paddingTop: "20px",
                    }}
                    layout="horizontal"
                    formatter={(value, index) => {
                      const truncatedValue =
                        isMobileOnly && value.length > 12
                          ? value.substring(0, 10) + "..."
                          : value;

                      return (
                        <Tag
                          color={
                            unselectedKeys?.includes(value)
                              ? "#bcbcbc"
                              : "geekblue"
                          }
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontSize: isMobileOnly ? "9px" : "12px",
                          }}
                          size="small"
                          className="text-color-class"
                          onClick={() => removeChartData(value)}
                          title={value.replace("_", " ")} // Display the full name on hover/tap
                        >
                          {truncatedValue.replace("_", " ")}
                          {unselectedKeys?.includes(value) && !isMobileOnly && (
                            <PlusOutlined style={{ fontSize: "8px" }} />
                          )}
                        </Tag>
                      );
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        )}

        {isCompareModalVisible && (
          <ComparisionModal
            isModalVisible={isCompareModalVisible}
            setIsModalVisible={setCompareModalVisible}
            setSelectedBtn={setSelectedBtn}
            chartData={chartData}
            setChartData={setChartData}
            allData={allData}
            setAllData={setAllData}
          />
        )}
        {isPropertyRisingModal && (
          <PropRisingModal
            isModalVisible={isPropertyRisingModal}
            setIsModalVisible={setPropertyRisingModalVisible}
            setSelectedBtn={setSelectedBtn}
            chartData={chartData}
            setChartData={setChartData}
            allData={allData}
            setAllData={setAllData}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}

export default IndexTools;
