import React, { useEffect, useState } from "react";
import { Modal } from "antd";

const AccountExpirationPopup = () => {
  const [visible, setVisible] = useState(false);
  const [userDaysRemaining, setUserDaysRemaining] = useState(0);
  useEffect(() => {
    const checkExpirationDate = () => {
      const userData = localStorage.getItem("user");
      if (userData && userData !== "undefined" && userData !== "null") {
        const user = JSON.parse(userData);
        if (user.expiration_date) {
          const currentDate = new Date();
          const expirationDate = new Date(user.expiration_date);
          const daysRemaining = Math.ceil(
            (expirationDate - currentDate) / (1000 * 60 * 60 * 24)
          );
          setUserDaysRemaining(daysRemaining);
          if (daysRemaining <= 5 && daysRemaining > 0) {
            setVisible(true);
          }
        }
      }
    };

    checkExpirationDate();
  }, []);

  const handleOk = () => {
    setVisible(false);
    if (userDaysRemaining <= 0) {
      localStorage.removeItem("user");
      window.location.reload();
    }
  };

  return (
    <Modal
      title="Account Expiration Reminder"
      open={visible}
      onOk={handleOk}
      onCancel={handleOk}
    >
      <p>
        Your account is set to expire in {userDaysRemaining} days. To prevent
        any disruption in service, please contact our support team as soon as
        possible.
      </p>
    </Modal>
  );
};

export default AccountExpirationPopup;
