import { Row, Col, Skeleton } from "antd";
import { useEffect, useContext, useState, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";
import axiosInstance from "../config/axios/axios.config";
import {
  Summary,
  HouseMeasures,
  IndexTools,
  WeissInsights,
  HousesNearBy,
  Segmentation,
  ValPro,
  BubbleWatch,
  Listings,
  DynamicMap,
  BirdsEyeView,
  DynamicZipcodeMap,
  BestWorstSegment,
  VideoWidget,
  FixFlip,
  CompsWidget,
  WIDGET_NAMES,
  ValProCard,
  ConditionScore,
  GroupedImages,
  HousesNearByHeatMap,
} from "./index";

// Custom hook for theme and font updates
function useUpdateThemeAndFont(queryParams, theme) {
  useEffect(() => {
    if (queryParams?.theme && queryParams.theme !== theme.theme.primaryColor) {
      theme.setThemeType({
        ...theme.theme,
        primaryColor: queryParams.theme,
      });
    }
    if (
      queryParams?.font &&
      ["Barlow", "Cairo", "Poppins"].includes(queryParams?.font) &&
      `'${queryParams?.font}, sans-serif'` !== theme.font?.fontFamily
    ) {
      theme.setFont({
        fontFamily: `'${queryParams?.font}, sans-serif'`,
      });
    }
  }, [queryParams?.font, queryParams.theme, theme]);
}

function Widgets() {
  const [validToken, setValidToken] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const theme = useContext(ThemeContext);
  const token = params.token;

  const queryParams = Array.from(searchParams.entries()).reduce(
    (acc, [param, value]) => ({ ...acc, [param]: value }),
    {}
  );

  useUpdateThemeAndFont(queryParams, theme);

  let width = queryParams?.width || "99vw";
  let height = queryParams?.height || "100vh";

  const validateToken = useCallback(() => {
    if (token) {
      setTokenLoading(true);
      axiosInstance
        .get(`widgets/validate_token/?token=${token}`)
        .then((res) => {
          setValidToken(res?.data?.status);
          setTokenLoading(false);
        })
        .catch((err) => {
          console.error("Error validating token:", err);
          setValidToken(false);
          setTokenLoading(false);
        });
    } else {
      setValidToken(false);
    }
  }, [token]);

  useEffect(() => {
    validateToken();
  }, [token, validateToken]);

  if (tokenLoading) {
    return <Skeleton />;
  }
  if (!validToken) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  function renderWidget(widgetName) {
    if (widgetName) {
      widgetName = widgetName.toLowerCase();
    }
    switch (widgetName) {
      case WIDGET_NAMES.WEISS_INSIGHTS:
        return <WeissInsights />;
      case WIDGET_NAMES.INDEX_TOOLS:
        return <IndexTools />;
      case WIDGET_NAMES.VAL_PRO:
        return <ValPro />;
      case WIDGET_NAMES.SEGMENTATION:
        return <Segmentation />;
      case WIDGET_NAMES.VIDEO:
        return <VideoWidget />;
      case WIDGET_NAMES.HOUSES_NEARBY:
        return <HousesNearBy />;
      case WIDGET_NAMES.HOUSE_MEASURES:
        return <HouseMeasures />;
      case WIDGET_NAMES.SUMMARY:
        return <Summary />;
      case WIDGET_NAMES.BEST_SEGMENT:
        return <BestWorstSegment />;
      case WIDGET_NAMES.WORST_SEGMENT:
        return <BestWorstSegment />;
      case WIDGET_NAMES.DYNAMIC_MAP:
        return <DynamicMap />;
      case WIDGET_NAMES.BUBBLE_WATCH:
        return <BubbleWatch />;
      case WIDGET_NAMES.DYNAMIC_ZIPCODE_MAP:
        return <DynamicZipcodeMap />;
      case WIDGET_NAMES.BIRDS_EYE_VIEW:
        return <BirdsEyeView />;
      case WIDGET_NAMES.FIX_FLIP:
        return <FixFlip />;
      case WIDGET_NAMES.LISTINGS:
        return <Listings />;
      case WIDGET_NAMES.VAL_PRO_CARD:
        return <ValProCard />;
      case WIDGET_NAMES.COMPS:
        return <CompsWidget />;
      case WIDGET_NAMES.CONDITION_SCORE:
        return <ConditionScore />;
      case WIDGET_NAMES.GROUPED_IMAGES:
        return <GroupedImages />;
      case WIDGET_NAMES.HOUSES_NEARBY_HEATMAP:
        return <HousesNearByHeatMap />;
      default:
        window.parent.postMessage("NO DATA", "*");
        return null;
    }
  }

  return (
    <Row>
      <Col
        style={{
          fontFamily: theme.font?.fontFamily,
          width,
          height,
        }}
      >
        {renderWidget(params.widgetName)}
      </Col>
    </Row>
  );
}
export default Widgets;
