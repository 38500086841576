import { Button, Col, Result, Row } from "antd";
import React from "react";
import "./ErrorPage.css";
function ErrorPage() {
  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Col span={24}>
        <Result
          className="error-result"
          status="500"
          subTitle="Ouch! You have discovered a problem in our site. We have received your
          error and are working frantically to fix it! We will be in touch as soon
          as we know what is causing this error. We are very sorry for the
          inconvenience"
          extra={
            <Button type="primary">
              <a href={window.location.origin}>Back Home </a>
            </Button>
          }
        />
      </Col>
    </Row>
  );
}

export default ErrorPage;
