import { Col, Typography, Row } from "antd";
import { useParams } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import "../../components/Report/Property/Property.css";
import "../../components/Report/Report.css";
import { ErrorBoundary } from "../shared";

const { Title, Text } = Typography;

function PageTitleUnAuthorized() {
  const params = useParams();
  const pageTitle = params?.reportName.split(",");

  return (
    <ErrorBoundary>
      <Row style={{padding: "5px"}}>
        <Col
          lg={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 12 }}
          xs={{ span: 12 }}
          style={{ padding: "5px" }}
        >
          {params?.propType === "metro" ? (
            <Title
              level={2}
              style={
                !isMobileOnly
                  ? {
                    color: "#495057",
                    marginBottom: "1px",
                    marginLeft: "10px",
                    lineHeight: "1.1",
                  }
                  : {
                    color: "#495057",
                    marginBottom: 0,
                    marginLeft: "10px",
                    lineHeight: "1.1",
                    fontSize: "14px",
                  }
              }
            >
              {pageTitle?.length < 2 && pageTitle[0]?.toUpperCase()}
              {pageTitle?.length > 1 && pageTitle[0]?.toUpperCase() + pageTitle[1]?.toUpperCase()}
            </Title>
          ) : (
            <>
              <Title
                level={2}
                style={
                  !isMobileOnly
                    ? {
                      color: "#495057",
                      marginBottom: 0,
                      marginLeft: "10px",
                      lineHeight: "1.1",
                    }
                    : {
                      color: "#495057",
                      marginBottom: 0,
                      marginLeft: "10px",
                      lineHeight: "1.1",
                      fontSize: "14px",
                    }
                }
              >
                {pageTitle[0] && pageTitle[0]?.toUpperCase()}
              </Title>
              <Text
                style={
                  !isMobileOnly
                    ? {
                      fontSize: "12px",
                      color: "#666",
                      marginLeft: "10px",
                      fontWeight: "bold",
                    }
                    : {
                      fontSize: "10px",
                      color: "#666",
                      marginLeft: "10px",
                      fontWeight: "bold",
                    }
                }
              >
                {pageTitle[1] && pageTitle[1]?.toUpperCase()}
                {params?.propType === "zip_code" && "ZIP Code"}
              </Text>
            </>
          )}
        </Col>
      </Row>
    </ErrorBoundary>
  );
}

export default PageTitleUnAuthorized;
