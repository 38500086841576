import axiosInstance from "../../config/axios/axios.config";

export async function fetchZipCodeHouses(filters) {
  const { zipcode } = filters;

  let url = `/widgets/get_houses/${zipcode}/`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    if (error?.flag === "WidgetError") {
      return null;
    }
    throw new Error(
      `Failed fetch data. fetchZipCodeHouses with error: ${error}`
    );
  }
}
