import React from "react";
import {
  Modal,
  Form,
  InputNumber,
  Select,
  Button,
  Typography,
  DatePicker,
} from "antd";

const { Option } = Select;
const { Paragraph } = Typography;

const OwnValutionModal = ({
  setCustomCompVal,
  ownValModalVisible,
  setOwnValModalVisible,
}) => {
  const handleCancel = () => {
    setOwnValModalVisible(false);
  };

  const onFinish = (values) => {
    setCustomCompVal(values);
    setOwnValModalVisible(false);
  };

  return (
    <Modal
      title="What do you want to pick?"
      footer={false}
      width={800}
      onCancel={handleCancel}
      open={ownValModalVisible}
    >
      <Paragraph>
        Please indicate the type and value of the valuation you'd like to add.
      </Paragraph>
      <Form name="Add Comp" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Type Of Valuation"
          name="type_of_valution"
          rules={[
            {
              required: true,
              message: "Please choose valuation type!",
            },
          ]}
        >
          <Select style={{ maxWidth: "150px" }}>
            <Option value="appraisal">Appraisal</Option>
            <Option value="bpo">BPO</Option>
            <Option value="internet_estimate">Internet Estimate</Option>
            <Option value="past_sale">Past Sale</Option>
            <Option value="others">Others</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Date Of Sale"
          name="date_of_sale"
          rules={[
            {
              required: true,
              message: "Please choose date!",
            },
          ]}
        >
          <DatePicker style={{ minWidth: "150px" }} />
        </Form.Item>

        <Form.Item
          label="Sale Price"
          name="sale_price"
          rules={[
            {
              required: true,
              message: "Please input your sale price!",
            },
          ]}
        >
          <InputNumber
            style={{ minWidth: "150px" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Comp
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OwnValutionModal;
