import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clear } from "../../../helpers/localStorage";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ pathname: "/" });
    clear();
  }, [navigate]);

  return <Fragment></Fragment>;
};

export default Logout;
