import React, { useState, useRef, useEffect } from "react";
import { Col, Row } from "antd";
import CustomCard from "../../blocks/Card/Card";
import CountUp from "react-countup";

function MetroSummary({ data }) {
  const [elementHeight, setElementHeight] = useState(205);
  const elementRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
        if (elementRef.current) {
            const newHeight = elementRef.current.clientHeight + 27;
            setElementHeight(newHeight);
            window.parent.postMessage({ metroSummaryHeight: newHeight }, "*");
        }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
        window.removeEventListener('resize', updateHeight);
    };
}, [data, elementHeight, elementRef]);


  return (
    <CustomCard ref={elementRef} title={<span style={{ fontSize: "20px" }}>Metro Summary</span>} className="report title-2">
      <Row
        gutter={[16, 16]}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 8 }}
          xs={{ span: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <p className="count-up cart-item-mobile-font">
            <CountUp
              end={data?.metro_ten_year_change}
              suffix="%"
            />
          </p>
          <p className="mobile-font-metro-summary">Last 10 Years</p>
        </Col>
        <Col
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 7 }}
          xs={{ span: 7 }}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <p className="count-up cart-item-mobile-font">
            <CountUp
              end={data?.metro_one_year_change}
              suffix="%"
            />
          </p>
          <p className="mobile-font-metro-summary">Last Year</p>
        </Col>
        <Col
          xl={{ span: 8 }}
          lg={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 9 }}
          xs={{ span: 9 }}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <p className="count-up cart-item-mobile-font">
            <CountUp end={data?.metroforcast} suffix="%" />
          </p>
          <p className="mobile-font-metro-summary"> One Year Forecast</p>
        </Col>
      </Row>
    </CustomCard>
  );
}

export default MetroSummary;
