import axiosInstance from "../../../config/axios/axios.config";

export async function fetchUserCustomizations() {
  let url = "accounts/customizations/user_customizations/";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    if (error?.flag === "WidgetError") {
      return null;
    }
    throw new Error(
      `Failed fetch data. fetchUserCustomizations with error: ${error}`
    );
  }
}
