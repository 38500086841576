import React from "react";
import { Card } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function ReportLineChart({ data }) {
  if (!data) {
    return null;
  } else if (data && data.length === 0) {
    return <Card>No data available</Card>;
  }
  data = data?.sort((a, b) => b.call_count - a.call_count);
  return (
    <Card title="Orders by Data Source" bordered={false}>
      <ResponsiveContainer width="70%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="source" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="success_count" fill="#82ca9d" />
          <Bar dataKey="failure_count" fill="#ff6961" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default ReportLineChart;
