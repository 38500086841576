import { Row, Col, Space } from "antd";
import ComparisonBtn from "./ComparisonBtn";
import PropertyRisingBtn from "./PropertyRisingBtn";
import YearsRadioBtn from "./YearsRadioBtn";
import { isMobileOnly } from "react-device-detect";

const AllButtons = ({ openComparisonModal, openPropertyModal, changeGraphData, selectedBtn }) => {
    return (
        !isMobileOnly ? (
            <Space>
                <ComparisonBtn onClick={openComparisonModal} />
                <PropertyRisingBtn onClick={openPropertyModal} />
                <YearsRadioBtn value={selectedBtn} onChange={changeGraphData} />
            </Space>
        ) : (
            <div>
                <Row align="middle" justify="space-between" style={{marginBottom: "10px"}}>
                    <Col><PropertyRisingBtn onClick={openPropertyModal} /></Col>
                </Row>
                <Row align="middle" justify="space-between" gutter={[4,4]}>
                    <Col><ComparisonBtn onClick={openComparisonModal} /></Col>
                    <Col><YearsRadioBtn value={selectedBtn} onChange={changeGraphData} /></Col>
                </Row>
            </div>

        )
    )
}

export default AllButtons;