import { useState, useEffect, useCallback, useMemo } from "react";
import { isMobileOnly } from "react-device-detect";
import { Modal, Spin } from "antd";
import PropertyResults from "../../components/Toolbar/PropertyResults";
import { openNotificationWithIcon } from "../../helpers/utility";
import { fetchAdditionalData } from "./api";

function PropRisingModal({
  isModalVisible,
  setIsModalVisible,
  setAllData,
  setChartData,
  setSelectedBtn,
  allData,
}) {
  const [searchedResult, setSearchedResult] = useState(null);
  const [getMetroData, setMetroData] = useState(null);
  const [fetchData, setFetchData] = useState(false);

  const onCancel = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const updateAllData = useCallback(
    (newData) => {
      for (let i = 0; i < allData?.length; i++) {
        allData[i] = {
          ...allData[i],
          ...newData[i],
        };
        setAllData(allData);
        setChartData(allData);
      }
    },
    [allData, setAllData, setChartData]
  );

  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);

  useEffect(() => {
    if (searchedResult) {
      setFetchData(true);
      try {
        if (getMetroData?.cbsaCode) {
          fetchAdditionalData({
            code: getMetroData?.cbsaCode,
            report_address: searchedResult,
            prop_type: "property_rising_metro",
            customer_id: user?.email || "0",
            cbsa_code: getMetroData?.cbsaCode || "",
          }).then((res) => {
            updateAllData(res?.indices);
            setFetchData(false);
            setSelectedBtn("max");
            setIsModalVisible(false);
          });
        }
      } catch (error) {
        setFetchData(false);
        setSelectedBtn("max");
        setIsModalVisible(false);
        openNotificationWithIcon("error", "Data Unavailable");
      }
    }
  }, [
    searchedResult,
    getMetroData,
    setFetchData,
    updateAllData,
    setIsModalVisible,
    setSelectedBtn,
    user?.email,
  ]);

  return (
    <Modal
      title="What do you want to add?"
      closable={false}
      footer={false}
      width={isMobileOnly ? "85%" : "40%"}
      open={isModalVisible}
      onCancel={onCancel}
    >
      <Spin spinning={fetchData} tip={`Fetching ${searchedResult} data`}>
        <PropertyResults
          setSearchedResult={setSearchedResult}
          setMetroData={setMetroData}
          placeholderText="Search for Metro"
          showOnlyMetro={true}
        />
      </Spin>
    </Modal>
  );
}

export default PropRisingModal;
