import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import email from "../../assets/images/email.png";



const ListingAgent = (props) => {
    const record = props.cell._cell.row.data;
    const value = props?.cell?._cell?.value || "-";
    return (
      <ul style={{ listStyle: "none" }}>
        <li>
          <UserOutlined /> {value}
        </li>
        <li>
          <img src={email} height="14px" width="14px" alt="email" />{" "}
          {record?.ListingAgentEmail ? record?.ListingAgentEmail : "-"}
          {/* {record?.City} {record?.State}, */}
        </li>
        <li>
          <PhoneOutlined />
          {record?.ListingAgentPhone ? record?.ListingAgentPhone : "-"}
        </li>
      </ul>
    );
}

export default ListingAgent;