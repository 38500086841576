import React, { useState, useRef, useEffect } from "react";
import { Table } from "antd";
import CustomCard from "../../blocks/Card/Card";

const getZipSummary = (data) => {
    let dataSource = [
        {
            key: "1",
            category: "ZIP Code RSFR",
            one_year: data?.rsfr?.zipforcast !== "-" ? data?.rsfr?.zipforcast + " %" : "-",
            last_year: data?.rsfr?.zip_one_year_change !== "-" ? data?.rsfr?.zip_one_year_change + " %" : "-",
            ten_year: data?.rsfr?.zip_ten_year_change !== "-" ? data?.rsfr?.zip_ten_year_change + " %" : "-",
        },
        {
            key: "2",
            category: "ZIP Code Condos",
            one_year: data?.condos?.zipforcast !== "-" ? data?.condos?.zipforcast + " %" : "-",
            last_year: data?.condos?.zip_one_year_change !== "-" ? data?.condos?.zip_one_year_change + " %" : "-",
            ten_year: data?.condos?.zip_ten_year_change !== "-" ? data?.condos?.zip_ten_year_change + " %" : "-",
        },
        {
            key: "3",
            category: "Metro RSFR",
            one_year: data?.rsfr?.metroforcast !== "-" ? data?.rsfr?.metroforcast + " %" : "-",
            last_year: data?.rsfr?.metro_one_year_change !== "-" ? data?.rsfr?.metro_one_year_change + " %" : "-",
            ten_year: data?.rsfr?.metro_ten_year_change !== "-" ? data?.rsfr?.metro_ten_year_change + " %" : "-",
        },
        {
            key: "4",
            category: "Metro Condos",
            one_year: data?.condos?.metroforcast !== "-" ? data?.condos?.metroforcast + " %" : "-",
            last_year: data?.condos?.metro_one_year_change !== "-" ? data?.condos?.metro_one_year_change + " %" : "-",
            ten_year: data?.condos?.metro_ten_year_change !== "-" ? data?.condos?.metro_ten_year_change + " %" : "-",
        },
    ];

    const columns = [
        {
            title: "Property Type",
            dataIndex: "category",
            key: "category",
            responsive: ["xs", "sm"],
            width: "25%",
        },
        {
            title: <span style={{textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>Last Ten Years</span>,
            dataIndex: "ten_year",
            responsive: ["xs", "sm"],
            width: "25%",
            key: "ten_year",
            className: 'centered-column',
        },
        {
            title: <span style={{textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>Last One Year</span>,
            dataIndex: "last_year",
            responsive: ["xs", "sm"],
            width: "25%",
            key: "last_year",
            className: 'centered-column',
        },
        {
            title: <span style={{textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>One Year Forecast</span>,
            dataIndex: "one_year",
            responsive: ["xs", "sm"],
            width: "25%",
            key: "one_year",
            className: 'centered-column',
        },
    ];
    if (data?.rsfr?.zipforcast === "-" && data?.rsfr?.zip_one_year_change === "-" && data?.rsfr?.zip_ten_year_change === "-") {
        dataSource = dataSource.filter((item) => item.category !== "ZIP Code RSFR");
    } else if (data?.condos?.zipforcast === "-" && data?.condos?.zip_one_year_change === "-" && data?.condos?.zip_ten_year_change === "-") {
        dataSource = dataSource.filter((item) => item.category !== "ZIP Code Condos");
    } else if (data?.rsfr?.metroforcast === "-" && data?.rsfr?.metro_one_year_change === "-" && data?.rsfr?.metro_ten_year_change === "-") {
        dataSource = dataSource.filter((item) => item.category !== "Metro RSFR");
    } else if (data?.condos?.metroforcast === "-" && data?.condos?.metro_one_year_change === "-" && data?.condos?.metro_ten_year_change === "-") {
        dataSource = dataSource.filter((item) => item.category !== "Metro Condos");
    }
    return { dataSource, columns };
}
function ZipSummary({ data }) {
    const { dataSource, columns } = getZipSummary(data);
    const [elementHeight, setElementHeight] = useState(436);
    const elementRef = useRef(null);

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 48;
                setElementHeight(newHeight);
                window.parent.postMessage({ zipSummaryHeight: newHeight }, "*");
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);

    
    return (
        <CustomCard ref={elementRef} title={<span className="mobile-title-font">ZIP Code Summary</span>} className="report responsive-table-property-summary title-2">
            <Table dataSource={dataSource} columns={columns} pagination={false} />
        </CustomCard>
    );
}

export default ZipSummary;
