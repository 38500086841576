import React, { useEffect, useState } from "react";
import { Divider, Button, Popover, Tag, Badge } from "antd";
import { FilterOutlined, FilterFilled } from "@ant-design/icons";
import CustomCard from "../../blocks/Card/Card";
import MetroMap from "./MetroMap";
import MetroTable from "./MetroTable";
import metroJsonData from "./data.json";
import "./metroTable.css";
import { isMobileOnly } from "react-device-detect";
import MapKey from "../../assets/images/map-key.png";
import { arraysContentsEqual } from "../shared";

function formatNumberWithCommas(x) {
  try {
      if (x === 0) return "0";
      if (!x) return "-";
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  catch (err) {
      return "-";
  }
}

const National = () => {
  const defaultFilters = {
    median: [],
    forecast: [],
    percentrising: [],
    percentfalling: [],
    bestforecast: [],
    worstforecast: [],
    lastyearchange: [],
    region: null,
  };

  let savedFilters = localStorage.getItem("filters")
    ? JSON.parse(localStorage.getItem("filters"))
    : defaultFilters;

  if (
    savedFilters &&
    (savedFilters?.lastyearchange?.includes(":") ||
      savedFilters?.lastyearchange?.length === 0 ||
      typeof savedFilters === "string")
  ) {
    savedFilters = defaultFilters;
  }
  let newColumnMinMaxes = {
    lastyearchange: [
      Math.floor(
        Math.min(
          ...metroJsonData.map((metro) => parseFloat(metro.lastyearchange) || 0)
        )
      ),
      Math.ceil(
        Math.max(
          ...metroJsonData.map((metro) => parseFloat(metro.lastyearchange) || 0)
        )
      ),
    ],
    median: [
      Math.floor(
        Math.min(...metroJsonData.map((metro) => parseFloat(metro.median) || 0))
      ),
      Math.ceil(
        Math.max(...metroJsonData.map((metro) => parseFloat(metro.median) || 0))
      ),
    ],
    forecast: [
      Math.floor(
        Math.min(
          ...metroJsonData.map((metro) => parseFloat(metro.forecast) || 0)
        )
      ),
      Math.ceil(
        Math.max(
          ...metroJsonData.map((metro) => parseFloat(metro.forecast) || 0)
        )
      ),
    ],
    percentrising: [
      Math.floor(
        Math.min(
          ...metroJsonData.map((metro) => parseFloat(metro.percentrising) || 0)
        )
      ),
      Math.ceil(
        Math.max(
          ...metroJsonData.map((metro) => parseFloat(metro.percentrising) || 0)
        )
      ),
    ],
    percentfalling: [
      Math.floor(
        Math.min(
          ...metroJsonData.map((metro) => parseFloat(metro.percentfalling) || 0)
        )
      ),
      Math.ceil(
        Math.max(
          ...metroJsonData.map((metro) => parseFloat(metro.percentfalling) || 0)
        )
      ),
    ],
    bestforecast: [
      Math.floor(
        Math.min(
          ...metroJsonData.map((metro) => parseFloat(metro.bestforecast) || 0)
        )
      ),
      Math.ceil(
        Math.max(
          ...metroJsonData.map((metro) => parseFloat(metro.bestforecast) || 0)
        )
      ),
    ],
    worstforecast: [
      Math.floor(
        Math.min(
          ...metroJsonData.map((metro) => parseFloat(metro.worstforecast) || 0)
        )
      ),
      Math.ceil(
        Math.max(
          ...metroJsonData.map((metro) => parseFloat(metro.worstforecast) || 0)
        )
      ),
    ],
  };

  const [filters, setFilters] = useState(savedFilters);

  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  let filteredMetros = metroJsonData.filter((metro) => {
    return Object.keys(filters).every((filterKey) => {
      if (filterKey === "region") {
        return (
          filters[filterKey] === null || filters[filterKey] === metro[filterKey]
        );
      }
      if (filters[filterKey]?.length === 0) {
        return true;
      }
      const [min, max] = filters[filterKey];
      const recordValue = parseFloat(metro[filterKey]);
      return recordValue >= min && recordValue <= max;
    });
  });

  const CardTitle = () => {
    return (
      <div className="metro-map-card-title">
        <h3 className="title-2">National Metro Map</h3>
        <img
          src={MapKey}
          alt="map key"
          style={{ width: isMobileOnly ? "220px" : "350px" }}
        />
        <span>
          <Tag color="processing">Based on Last Year Change</Tag>
        </span>
      </div>
    );
  };

  const appliedFilters = Object.keys(filters).reduce((acc, filterKey) => {
    if (filterKey === "region" && filters[filterKey] !== null) {
      acc.push({ key: filterKey, value: filters[filterKey] });
    } else if (
      filters[filterKey]?.length > 0 &&
      !arraysContentsEqual(filters[filterKey], newColumnMinMaxes[filterKey])
    ) {
      acc.push({ key: filterKey, value: filters[filterKey].join(" - ") });
    }
    return acc;
  }, []);

  let keyDict = {
    median: "Median",
    forecast: "Forecast",
    percentrising: "Percent Rising",
    percentfalling: "Percent Falling",
    bestforecast: "Best Forecast",
    worstforecast: "Worst Forecast",
    lastyearchange: "Last Year Change",
    region: "Region",
  };
  
  const getValue = (key, value) => {
    if (key === "region") {
      return value;
    } else if (key === "median") { 
      let val = value.split(" - ");
      return `From $${formatNumberWithCommas(val[0])} To $${formatNumberWithCommas(val[1])}`;
    } else  {
      let val = value.split(" - ");
      return `From ${val[0]}% To ${val[1]}%`;
    }
  }
  const renderFilterTags = () => {
    return appliedFilters?.map(({ key, value }) => (
      <Tag key={key} color="blue">
        {`${keyDict[key]}: ${getValue(key, value)}`}
      </Tag>
    ));
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const popoverContent = (
    <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
      {renderFilterTags()}
      <Button
        type="primary"
        onClick={handleResetFilters}
        style={{ marginTop: "10px" }}
      >
        Reset Filters
      </Button>
    </div>
  );

  const popoverNoContent = (
      <div style={{ marginBottom: "8px" }}>No Filters Applied.</div>
  );

  let filterButtonStyle = {
    border: "1px solid grey",
    width: "140px",
    borderRadius: "5px",
    marginBottom: "14px",
    height: "32px",
    textAlign: "left",
    paddingLeft: "18px",
  };
  return (
    <div className="metro-map">
      <CustomCard title={<CardTitle />} className="title-2">
        <Popover
          content={appliedFilters?.length > 0 ? popoverContent : popoverNoContent}
          trigger="click"
          placement="bottomRight"
        >
          <Badge
            count={appliedFilters?.length > 0 ? appliedFilters?.length : 0}
            style={{ backgroundColor: "#7cb305" }}
          >
            <Button
              type="text"
              icon={
                appliedFilters?.length > 0 ? (
                  <FilterFilled style={{ color: "#7cb305" }} />
                ) : (
                  <FilterOutlined />
                )
              }
              style={filterButtonStyle}
            >
              Filters
            </Button>
          </Badge>
          {/* <Button
              type="primary"
              icon={<FilterOutlined />}
              style={{ marginBottom: "16px" }}
            >
              {appliedFilters?.length} Filter
              {appliedFilters?.length > 1 ? "s" : ""} Applied
            </Button> */}
        </Popover>
        <MetroMap selectedMetros={filteredMetros} />
        <Divider />
        <MetroTable
          filters={filters}
          setFilters={setFilters}
          selectedMetros={filteredMetros}
          columnMinMaxes={newColumnMinMaxes}
          appliedFilters={appliedFilters}
        />
      </CustomCard>
    </div>
  );
};

export default National;
