import axiosInstance from "../config/axios/axios.config";

export const emailLogin = (payload) => {
  return axiosInstance.post(`/accounts/login/`, payload);
};

export const register = (payload) => {
  return axiosInstance.post(`/accounts/signup/`, payload);
};

export const editProfile = (payload) => {
  return axiosInstance.put(`/accounts/edit-profile/`, payload);
};

export const changePassword = (payload) => {
  return axiosInstance.put(`/accounts/change-password/`, payload);
};

export const resetPassword = (payload) => {
  return axiosInstance.post(`/accounts/reset-password/`, payload);
};

export const setPassword = (payload) => {
  return axiosInstance.post(`/accounts/set-password/`, payload);
};
