import axiosInstance from "../config/axios/axios.config";

export async function fetchTermsConditionsContent() {
  try {
    const response = await axiosInstance.get("accounts/get_terms_and_conditions/")
    return response.data;
  } catch (error) {
    if (error?.flag === "WidgetError") {
      return null;
    }
    throw new Error(`Failed fetch data. fetchTermsConditionsContent with error: ${error}`);
  }
}
