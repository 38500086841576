import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Tag } from "antd";
import { isTablet } from "react-device-detect";
import PropertyResults from "../Toolbar/PropertyResults";
import "./WelcomeCard.css";
import { useAvvs } from "../../Widgets/avv/useAvv";
import houseImage from "../../assets/images/house.png";
import LoupeInmanBlue from "../../assets/images/LoupeInmanBlue.svg";

function toTitleCase(str) {
  if (!str) {
    return str;
  }
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

function getBackgroundColor(process) {
  if (
    process?.status?.toLowerCase() === "completed" &&
    process?.report_type === "avv"
  ) {
    return "#e6ffe6"; // light green
  } else if (process?.status === "pending" && process?.report_type === "avv") {
    return "#ffffcc"; // light yellow
  } else if (
    process?.status?.toLowerCase() === "completed" &&
    process?.report_type === "avm"
  ) {
    return "#e6f7ff"; // light blue
  } else if (process?.status === "pending" && process?.report_type === "avm") {
    return "#ffffcc"; // light yellow
  } else {
    return "#ffe6e6"; // light red
  }
}

function WelcomeCard() {
  const { data: avvs, isLoading: avvLoading, isError: avvError } = useAvvs();
  const navigate = useNavigate();

  const handleAvvClick = (process) => {
    navigate(
      `/report/${encodeURIComponent(
        process?.search_string
      )}/address/${encodeURIComponent(process?.search_string.slice(-5))}`
    );
  };

  if (avvLoading) {
    return <div>Loading...</div>;
  }
  if (avvError) {
    return <div>Error...</div>;
  }
  let data = avvs?.map((avv) => {
    return {
      report_type: avv.report_type,
      search_string: avv.address,
      timestamp: avv.updated,
      status: avv.status,
      report_url: avv.report_url,
      updated: avv.updated,
    };
  });

  return (
    <div className="welcome-card-container">
      <Row
        type="flex"
        align="middle"
        justify="center"
        style={
          isTablet
            ? { minHeight: "60vh" }
            : { minHeight: "80vh", paddingBottom: "30px" }
        }
      >
        <Col
          xl={{ span: 14 }}
          lg={{ span: 16 }}
          md={{ span: 18 }}
          sm={{ span: 18 }}
          xs={{ span: 22 }}
          style={{ textAlign: "center" }}
        >
          <img
            alt="houses"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
            src={houseImage}
          />
          <div style={{ marginTop: "20px" }}>
            <h1 className="text-blue">
              <img
                alt="loupe"
                src={LoupeInmanBlue}
                style={{ height: "30px" }}
              />
              <span style={{ marginLeft: "20px" }}>
                Enter <span className="text-bold-extra">Metro,</span>
              </span>
              <span className="text-bold-extra"> Zip</span>
              <span style={{ padding: 5 }}>or</span>
              <span className="text-bold-extra">Address</span>
              <PropertyResults isReportSearch={true} />
            </h1>
          </div>

          <div style={{ marginTop: "20px" }}>
            <Row gutter={[16, 16]}>
              {data.map((process, index) => (
                <Col
                  key={index}
                  xl={{ span: 6 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <Card
                    hoverable
                    style={{
                      margin: "10px 0",
                      height: "100%",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                      backgroundColor: getBackgroundColor(process),
                    }}
                    onClick={() => handleAvvClick(process)}
                  >
                    <b>{process?.search_string?.split(",")[0]}</b>
                    <br />
                    {process?.search_string?.split(",")[1]}
                    <br />
                    <br />
                    <Tag color={getBackgroundColor(process)}>
                      <span style={{ color: "black" }}>
                        {process?.report_type === "avv" ? "AVV" : "AVM"} Report:{" "}
                        {toTitleCase(process?.status)}
                      </span>
                    </Tag>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default WelcomeCard;
