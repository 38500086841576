import { Button, Col, Modal, Row } from "antd";
import React, { Fragment, useState } from "react";
import PlanOptions from "../../Dashboard/ManageSubscribtions/PlanOptions";
import Payment from "./Payment";

function PaymentCards() {
  const [selectedPlan, setSelectedPlan] = useState({ id: "", amount: null });
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <Fragment>
      <Row type="flex" align="middle" justify="center">
        <Col
          lg={{ span: 16, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: "0px", paddingTop: "100px" }}
        >
          {/* <PaymentCards11 /> */}
          <PlanOptions
            selectedPlan={selectedPlan}
            setSelectedPlan={(sp) => setSelectedPlan(sp)}
          />
        </Col>
        <Col span={18} style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            disabled={!selectedPlan?.id}
          >
            Subscribe
          </Button>
        </Col>
      </Row>

      {isModalVisible && (
        <Modal
          visible={isModalVisible}
          width="70%"
          footer={null}
          closable={true}
          onCancel={() => setIsModalVisible(false)}
        >
          <Payment selectedPlan={selectedPlan} />
        </Modal>
      )}
    </Fragment>
  );
}

export default PaymentCards;
