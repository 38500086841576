import React, { useRef } from "react";
import { isMobileOnly } from "react-device-detect";
import { Col, Row } from "antd";
import "./Property.css";
import "../Report.css";
import { ValPro, PageTitle } from "../../../Widgets";

const ValPal = () => {
  const valproRef = useRef(null);

  const handleValPalPdf = () => {
    valproRef?.current?.handleValPalData();
  };

  return (
    <Row className="report-container">
      <Col lg={{ span: 20, offset: 2 }} md={{ span: 22, offset: 1 }}>
        <Row gutter={[16, 16]}>
          <Col
            className="page-title-row"
            span={24}
            style={{ position: "sticky", top: 60, zIndex: 1000 }}
          >
            <div className="page-title">
              <PageTitle handleValPalPdf={handleValPalPdf} />
            </div>
          </Col>
          {/* ValPro */}
          <Col span={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
            <ValPro ref={valproRef} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ValPal;
