import axiosInstance from "../../config/axios/axios.config";

export async function fetchAggregateData(filters) {
  const { start_date, end_date } = filters;

  let url = "reports/aggregated-report/";
  try {
    const response = await axiosInstance.post(url, {
      start_date,
      end_date,
    });
    let data = response.data;
    data?.forEach((item) => {
      if (item?.company?.toLowerCase() === "realty bid") {
        item.company = "COVIUS";
      }
    });
    return data;
  } catch (error) {
    if (error?.flag === "WidgetError") {
      return null;
    }
    throw new Error(
      `Failed fetch data. fetchAggregateData with error: ${error}`
    );
  }
}
