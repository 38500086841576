import { Radio } from "antd";
import "./PageTitle.css";

function PageTabs({ currentTab, setCurrentTab }) {
  return (
    <>
      <Radio.Group
        value={currentTab}
        buttonStyle="outline"
        onChange={(e) => setCurrentTab(e.target.value)}
        style={{
          display: "flex",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Radio.Button
          value="property"
          className="tab-item"
          style={{ borderLeft: "none" }}
        >
          <span className="radio-item">Property</span>
        </Radio.Button>
        <Radio.Button value="valpal" className="tab-item">
          <span className="radio-item">ValPal</span>
        </Radio.Button>
        <Radio.Button
          value="avv"
          className="tab-item"
          style={{ borderRight: "none" }}
        >
          <span className="radio-item">AVV</span>
        </Radio.Button>
      </Radio.Group>
    </>
  );
}

export default PageTabs;
