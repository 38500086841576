import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import {
  GoogleMap,
  useJsApiLoader,
  HeatmapLayer,
  Marker,
} from "@react-google-maps/api";
import HouseInfoWindow from "./HouseInfoWindow";
import HouseMarker from "./HouseMarker";

const libraries = ["places", "visualization"];

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const options = {
  zoomControl: true,
  fullscreenControl: true,
  streetViewControl: true,
  mapTypeControl: true,
  scaleControl: true,
  rotateControl: true,
  gestureHandling: "greedy",
};

const LastYearHouses = ({ houses, defaultCenter, address }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBDqhAD8puCskqZSn2xZ1FdIvQl3rwaR-Y",
    libraries,
  });

  const [zoomLevel, setZoomLevel] = useState(10);
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [centeri, setCenteri] = useState(null);

  const mapRef1 = useRef(null);
  const onMapLoad = useCallback((map) => {
    mapRef1.current = map;
  }, []);

  const heatmapData = useMemo(() => {
    if (!isLoaded || !window.google) return [];
    return houses.map((house) => ({
      location: new window.google.maps.LatLng(house?.lat, house?.lon),
      weight: house?.weight,
    }));
  }, [houses, isLoaded]);

  const heatmapOptions = useMemo(
    () => ({
      radius: 20,
      opacity: 0.6,
      gradient: [
        "rgba(165, 0, 38, 0)", // #a50026, Transparent
        "rgba(165, 0, 38, 1)", // #a50026
        "rgba(215, 48, 39, 1)", // #d73027
        "rgba(244, 109, 67, 1)", // #f46d43
        "rgba(253, 174, 97, 1)", // #fdae61
        "rgba(254, 224, 139, 1)", // #fee08b
        "rgba(207, 239, 139, 1)", // #cfef8b
        "rgba(217, 239, 139, 1)", // #d9ef8b
        "rgba(166, 217, 106, 1)", // #a6d96a
        "rgba(102, 189, 99, 1)", // #66bd63
        "rgba(26, 152, 80, 1)", // #1a9850
        "rgba(0, 104, 55, 1)", // #006837
      ],
    }),
    []
  );

  const handleZoomChanged = () => {
    if (mapRef1.current) {
      setZoomLevel(mapRef1.current.getZoom());
    }
  };

  const handleMarkerClick = useCallback((house) => {
    setSelectedHouse(house);
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedHouse(null);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          setCenteri({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  }, [isLoaded, address]);
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const center = defaultCenter ||
    centeri || {
      lat: houses[0]?.lat,
      lng: houses[0]?.lon,
    };

  return (
    <GoogleMap
      mapContainerStyle={{ ...mapContainerStyle, cursor: "grab" }}
      zoom={13}
      center={center}
      options={options}
      onLoad={onMapLoad}
      onZoomChanged={handleZoomChanged}
    >
      {zoomLevel < 15 && (
        <HeatmapLayer data={heatmapData} options={heatmapOptions} />
      )}
      {zoomLevel >= 15 &&
        houses.map((house) => (
          <HouseMarker
            key={house.property_id}
            house={house}
            onClick={handleMarkerClick}
          />
        ))}
      {centeri && <Marker position={centeri} />}
      {defaultCenter &&
        new window.google.maps.Marker({
          position: defaultCenter,
          map: mapRef1.current,
        })}
      {selectedHouse && (
        <HouseInfoWindow
          house={selectedHouse}
          onClose={handleInfoWindowClose}
        />
      )}
    </GoogleMap>
  );
};

export default LastYearHouses;
