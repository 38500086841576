import { Col, Tag, AutoComplete, Input, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import "./PropertyResults.css";
import axiosInstance from "../../config/axios/axios.config";

const setSearchOptions = (data) => {
  const propData = data.map((item) => {
    let title = item?.title;
    if (title === null || title === undefined) {
      title = "";
    }

    return {
      propType: item?.doc_type,
      key: title,
      value: title,
      cbsaCode: item?.source?.code,
      zipCode: item?.source?.zip_code,
      label: (
        <Row>
          <Col span={16}>
            <span
              className={
                isMobileOnly ? "search-result-mobile" : "search-result"
              }
            >
              {item?.title?.toUpperCase()}
            </span>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Tag
              color="blue"
              className={
                isMobileOnly ? "search-result-mobile" : "search-result"
              }
            >
              {item?.doc_type === "zip_code"
                ? "ZIP CODE"
                : item?.doc_type === "metro"
                ? "METRO"
                : "PROPERTY"}
            </Tag>
          </Col>
        </Row>
      ),
    };
  });
  return propData;
};

function PropertyResults(props) {
  const {
    setSearchedResult,
    isReportSearch,
    HideModal,
    setMetroData = null,
    setZipCodeData = null,
    setPropertyData = null,
    showOnlyMetro = false,
    showBothMetroandZip = false,
    showOnlyProperty = false,
    placeholderText = "Search for Properties / Metro / Zipcode",
    existingSearchText = null,
  } = props;

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [searchedText, setSearchedText] = useState(null);
  const timeout = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (existingSearchText !== null) {
      setSearchedText(existingSearchText);
    }
  }, [existingSearchText]);

  const handleSearch = async (searchedValue) => {
    try {
      if (searchedValue !== null && searchedValue.length > 2) {
        searchedValue = encodeURIComponent(searchedValue);
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BASE_URL}autosuggestions/suggest_combined/?address=${searchedValue}&limit=20&metro_index=metros&property_index=addresses_new&zip_index=zipcodes`
        );

        const res = response.data;
        if (showOnlyMetro) {
          let filterData = res[0]?.doc_type === "metro";
          setData(setSearchOptions(filterData ? res : []));
        } else if (showBothMetroandZip) {
          let filterData = res[0]?.doc_type !== "address";
          setData(setSearchOptions(filterData ? res : []));
        } else if (showOnlyProperty) {
          let filterData = res[0]?.doc_type === "address";
          setData(setSearchOptions(filterData ? res : []));
        } else {
          setData(setSearchOptions(res ? res : []));
        }
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function handleSelect(value, option) {
    if (!isReportSearch) {
      setSearchedResult(value);
      setSearchedText(value);
    } else {
      const type = option?.propType === "metro" ? "cbsaCode" : "zipCode";
      if (type === "cbsaCode") {
        navigate(
          `/report/${encodeURIComponent(option?.value)}/${encodeURIComponent(
            option?.propType
          )}/${encodeURIComponent(option?.cbsaCode)}`
        );
      } else {
        navigate(
          `/report/${encodeURIComponent(option?.value)}/${encodeURIComponent(
            option?.propType
          )}/${encodeURIComponent(option?.zipCode)}`
        );
      }
      HideModal?.(false);
      setSearchedText(null);
      setData([]);
    }
    if (option?.propType === "metro" && typeof setMetroData === "function") {
      setMetroData(option);
      typeof setZipCodeData === "function" &&  setZipCodeData(null);
      typeof setPropertyData === "function" &&  setPropertyData(null);
    } else if (
      option.propType === "zip_code" &&
      typeof setZipCodeData === "function"
    ) {
      setZipCodeData(option); 
      typeof setMetroData === "function" && setMetroData(null);
      typeof setPropertyData === "function" &&  setPropertyData(null);
    } else if (option.propType === "address" && typeof setPropertyData === "function") { 
      setPropertyData(option); 
      typeof setMetroData === "function" && setMetroData(null);
      typeof setZipCodeData === "function" &&  setZipCodeData(null);
    }
  }
  function debounce(func, delay = 600) {
    return (args) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        func(args);
      }, delay);
    };
  }
  const triggerSearch = debounce(handleSearch);
  return (
    <AutoComplete
      className="dashboard-search-input certain-category-search-dropdown"
      autoFocus={true}
      style={{ width: "100%" }}
      options={data && data}
      onSearch={(searchedValue) => {
        setSearchedText(searchedValue);
        triggerSearch(searchedValue);
      }}
      onSelect={handleSelect}
      value={searchedText}
    >
      <Input.Search ref={inputRef} placeholder={placeholderText} enterButton />
    </AutoComplete>
  );
}

export default PropertyResults;
