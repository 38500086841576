import React from "react";
import { InfoWindow } from "@react-google-maps/api";
import { Card } from "antd";
import { Link } from "react-router-dom";

function HouseInfoWindow({ house, onClose, isForecast = false }) {
  return (
    <InfoWindow
      position={{ lat: house.lat, lng: house.lon }}
      onCloseClick={onClose}
    >
      <Card
        title={
          <Link to="#" style={{ color: "black", cursor: "pointer" }}>
            {house?.infoWindow?.content?.title}
          </Link>
        }
        style={{
          width: 300,
          border: "none",
          backgroundColor: isForecast
            ? house?.forecast_color
            : house?.pic0_color,
        }}
      >
        <p>
          Forecast:{" "}
          {isForecast
            ? house?.infoWindow?.content?.forecast
            : house?.infoWindow?.content?.pic0}{" "}
          %
        </p>
        <p> Beds: {house?.infoWindow?.content?.beds}</p>
        <p> Baths: {house?.infoWindow?.content?.baths}</p>
        <p>Sq Ft: {house.infoWindow?.content?.sqft}</p>
        <p>Year Built: {house?.infoWindow?.content?.yearBuilt}</p>
      </Card>
    </InfoWindow>
  );
}

export default HouseInfoWindow;
