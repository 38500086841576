import { useQuery } from "react-query";
import { fetchZipCodeHouses } from "./api";

export function useHouses(filters, options = {}) {
  return useQuery(
    ["housesNearBy", filters],
    () => fetchZipCodeHouses(filters),
    {
      staleTime: 60000 * 60,
      ...options,
    }
  );
}
