import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Table,
  Select,
  Space,
  Modal,
  DatePicker,
  Divider,
  Button,
  message,
} from "antd";
import moment from "moment";
import CustomCard from "../../blocks/Card/Card";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { useAggregateReport } from "./useAggregateReport";
import ReportLineChart from "./ReportLineChart";
import OrdersByUserTable from "./OrdersByUserTable";
import OrdersByCompanyTable from "./OrdersByCompanyTable";
import axiosInstance from "../../config/axios/axios.config";

const COLORS = ["#82ca9d", "#ff6961"];

const getPieChart = (data) => {
  let totalSuccessCount = 0;
  let totalFailureCount = 0;

  data.forEach((item) => {
    totalSuccessCount += item.success_count;
    totalFailureCount += item.failure_count;
  });
  const pieData = [
    { name: "Success", value: totalSuccessCount },
    { name: "Failure", value: totalFailureCount },
  ];

  return pieData;
};

const getAggreatedSourceData = (data) => {
  const aggregatedData = data.reduce((acc, item) => {
    if (!acc[item.source]) {
      acc[item.source] = {
        source: item.source,
        call_count: 0,
        success_count: 0,
        failure_count: 0,
      };
    }

    acc[item.source].call_count += item.call_count;
    acc[item.source].success_count += item.success_count;
    acc[item.source].failure_count += item.failure_count;

    return acc;
  }, {});
  return Object.values(aggregatedData);
};

const getOrderByUserData = (data) => {
  const aggregatedData = {};

  data.forEach((item) => {
    const key = `${item.username}|${item.company}|${item.source}|${item.product}`;
    if (!aggregatedData[key]) {
      // Initialize if not already present
      aggregatedData[key] = {
        username: item.username,
        company: item.company,
        source: item.source,
        product: item.product,
        call_count: 0,
        success_count: 0,
        failure_count: 0,
      };
    }

    // Accumulate totals
    aggregatedData[key].call_count += item.call_count;
    aggregatedData[key].success_count += item.success_count;
    aggregatedData[key].failure_count += item.failure_count;
  });

  const aggregatedArray = Object.values(aggregatedData);
  return aggregatedArray;
};

const getAggregatedByCompanyData = (data) => {
  const aggregatedData = {};

  data.forEach((item) => {
    const key = `${item.company}-${item.source}-${item.product}`;
    if (!aggregatedData[key]) {
      // Initialize if not already present
      aggregatedData[key] = {
        username: item.username,
        company: item.company,
        source: item.source,
        product: item.product,
        call_count: 0,
        success_count: 0,
        failure_count: 0,
      };
    }

    // Accumulate totals
    aggregatedData[key].call_count += item.call_count;
    aggregatedData[key].success_count += item.success_count;
    aggregatedData[key].failure_count += item.failure_count;
  });

  const aggregatedArray = Object.values(aggregatedData);
  return aggregatedArray;
};

function formatNumberWithCommas(x) {
  try {
    if (x === 0) return "0";
    if (!x) return "-";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (err) {
    return "-";
  }
}

const { Option } = Select;
const { RangePicker } = DatePicker;

const EnhancedOverallOrdersCardWithFilters = () => {
  const lastMonthStart = moment().subtract(1, "months").startOf("month");
  const lastMonthEnd = moment();

  const [timeFrame, setTimeFrame] = useState([lastMonthStart, lastMonthEnd]);
  const [sourceType, setSourceType] = useState("all");
  const [company, setCompany] = useState("all");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { data, isLoading, isError } = useAggregateReport({
    start_date: timeFrame[0],
    end_date: timeFrame[1],
  });

  const columns = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Orders",
      dataIndex: "call_count",
      key: "call_count",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Success Count",
      dataIndex: "success_count",
      key: "success_count",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Failure Count",
      dataIndex: "failure_count",
      key: "failure_count",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Success Rate (%)",
      key: "success_rate",
      render: (text, record) => {
        const rate =
          record.call_count > 0
            ? ((record.success_count / record.call_count) * 100).toFixed(2)
            : 0;
        return `${rate}%`;
      },
    },
    {
      title: "Failure Rate (%)",
      key: "failure_rate",
      render: (text, record) => {
        const rate =
          record.call_count > 0
            ? ((record.failure_count / record.call_count) * 100).toFixed(2)
            : 0;
        return `${rate}%`;
      },
    },
  ];

  useEffect(() => {
    console.log("Filter changed");
  }, [timeFrame, sourceType]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error...</div>;
  }
  let filteredData = [...data];
  if (sourceType !== "all" && company !== "all") {
    filteredData = filteredData?.filter(
      (item) => item.source === sourceType && item.company === company
    );
  } else if (sourceType !== "all") {
    filteredData = filteredData?.filter((item) => item.source === sourceType);
  } else if (company !== "all") {
    filteredData = filteredData?.filter((item) => item.company === company);
  }
  let orderByUserData = getOrderByUserData(filteredData);
  let pieData = getPieChart(filteredData);
  let aggregatedArrayData = getAggreatedSourceData(filteredData);
  const sourceNames = [...new Set(data.map((item) => item.source))];
  const uniqueCompanies = Array.from(new Set(data.map((item) => item.company)));

  const handleDateRangeChange = (dates) => {
    setTimeFrame(dates);
    setSourceType("all");
    setCompany("all");
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await axiosInstance.get("/reports/aggregated-report/");
      setIsRefreshing(false);
      let today = moment();
      let newRange = [timeFrame[0], today];
      setTimeFrame(newRange);
      message.success("Data refreshed successfully");
    } catch (error) {
      setIsRefreshing(false);
    }
  };

  const confirmAndRefresh = () => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to refresh?",
      onOk() {
        handleRefresh();
      },
      // onCancel() {}, // Optional: if you want to do something when the user cancels
    });
  };

  const Title = () => {
    return (
      <div>
        <span>Overall Orders Success/Failure</span>
        <Button
          onClick={confirmAndRefresh}
          type="primary"
          style={{ float: "right" }}
          loading={isRefreshing}
        >
          Refresh
        </Button>
      </div>
    );
  };
  return (
    <>
      <CustomCard>
        <Card title={<Title />} bordered={false}>
          <Row>
            <Col span={24}>
              <Space style={{ marginBottom: 16 }}>
                <RangePicker
                  defaultValue={[lastMonthStart, lastMonthEnd]}
                  value={timeFrame}
                  onChange={handleDateRangeChange}
                />
                <Select
                  defaultValue="all"
                  style={{ width: 200 }}
                  onChange={setSourceType}
                >
                  <Option value="all">All Sources</Option>
                  {sourceNames?.map((source) => (
                    <Option key={source} value={source}>
                      {source}
                    </Option>
                  ))}
                </Select>
                <Select
                  defaultValue="all"
                  style={{ width: 200 }}
                  onChange={setCompany}
                >
                  <Option value="all">All Companies</Option>
                  {uniqueCompanies?.map((company) => (
                    <Option key={company} value={company}>
                      {company?.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Space>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col span={12}>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    dataKey="value"
                    startAngle={360}
                    endAngle={0}
                    data={pieData}
                    outerRadius={80}
                    fill="#8884d8"
                    label={(entry) => `${entry.name} (${entry.value})`}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col span={12}>
              <Table
                dataSource={aggregatedArrayData}
                columns={columns}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
        <Divider style={{ margin: "16px 0" }} />
        <ReportLineChart data={aggregatedArrayData} />
        <Divider style={{ margin: "16px 0" }} />
        <OrdersByCompanyTable data={getAggregatedByCompanyData(filteredData)} />
        <Divider style={{ margin: "16px 0" }} />
        <OrdersByUserTable data={orderByUserData} />
      </CustomCard>
    </>
  );
};

export default EnhancedOverallOrdersCardWithFilters;
