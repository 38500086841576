import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, hasAcceptedTerms } from "../helpers/utility";
import { Modal, Checkbox, Button, Skeleton, message, Collapse } from "antd";
import { useTermsConditionsContent } from "./useTermsConditionsContent";
import { clear, setItem } from "../helpers/localStorage";
import axiosInstance from "../config/axios/axios.config";
import { useQueryClient } from 'react-query';

const { Panel } = Collapse;

const PrivateRoute = () => {
  const location = useLocation();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");
  const [accepted, setAccepted] = useState(false);
  const { data: termsData, isLoading: isTermsConditionsLoading, isError: isErrorTermsConditions } = useTermsConditionsContent();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let username = "";
  if (isAuthenticated()) {
    let userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    username = userFromLocalStorage?.first_name ? userFromLocalStorage.first_name + " " + userFromLocalStorage?.last_name : userFromLocalStorage?.email;
  }
  useEffect(() => {
    const invalidateFetchTermsContentQuery = () => {
      // Invalidate the query with key 'myQueryKey'
      queryClient.invalidateQueries('fetchTermsConditionsContent');
    };

    const checkTermsAcceptance = async () => {
      try {
        if (isAuthenticated() && !hasAcceptedTerms()) {
          const user = JSON.parse(localStorage.getItem("user"));
          const response = await axiosInstance.get(`accounts/accept_terms_and_conditions/`);
          if (response?.status === 200 && response?.data?.accepted === true) {
            user.acceptedTerms = true;
            user.currentTerms = response?.data?.terms;
            setItem("user", JSON.stringify(user));
            setShowTermsModal(false);
          } else {
            setShowTermsModal(true);
          }
        }
        else if (isAuthenticated() && hasAcceptedTerms()) {
          if (termsData && termsData.terms) {
            const user = JSON.parse(localStorage.getItem("user"));
            if (!(user?.currentTerms?.version === termsData?.terms?.version)) {
              setRedirectPath(location.pathname);
              user.currentTerms = null;
              user.acceptedTerms = false;
              setItem("user", JSON.stringify(user));
              setAccepted(false);
              setShowTermsModal(true);
            }
            setItem("user", JSON.stringify(user));
          }
        }
      } catch (error) {
        console.error("Error checking terms acceptance:", error);
        setShowTermsModal(false);
      }
    };

    const isTermsActive = async () => {
      try {
        let res = await axiosInstance.get("accounts/accept_terms_active/");
        if (res.status === 200 && res?.data?.active === true) {
          invalidateFetchTermsContentQuery();
          checkTermsAcceptance();
        } else {
          setShowTermsModal(false);
        }
      } catch (error) {
        setShowTermsModal(false);
      }
    }

    isTermsActive();
    setRedirectPath(location.pathname);
  }, [location.pathname, termsData, queryClient]);

  const handleAcceptTerms = async () => {
    try {
      const response = await axiosInstance.post("accounts/accept_terms_and_conditions/", {
        accepted: true,
        terms: termsData.terms.id,
        consent_method: "web/checkbox",
      });

      if (response.status === 201) {
        message.success("Terms and conditions accepted. You can now continue.");
        const user = JSON.parse(localStorage.getItem("user"));
        user.acceptedTerms = true;
        user.currentTerms = termsData.terms;
        setItem("user", JSON.stringify(user));
        setRedirectPath("");
        setShowTermsModal(false);
      } else {
        message.error("Failed to accept terms and conditions. Please try again later.");
        setShowTermsModal(true);
      }
    } catch (error) {
      console.error("Error accepting terms and conditions:", error);
      message.error("An error occurred while accepting terms and conditions. Please try again later.");
      setShowTermsModal(false);
    }
  };

  const handleLogout = () => {
    setAccepted(false);
    setShowTermsModal(false);
    clear();
    navigate({ pathname: "/" });
  };

  if (isTermsConditionsLoading || !termsData) {
    return <Skeleton active />;
  }

  if (isErrorTermsConditions) {
    return null;
  }

  return isAuthenticated() ? (
    <>
      <Outlet />
      {showTermsModal && (
        <div className="overlay">
          <Modal
            title={<span style={{ fontWeight: "bold", color: "#000000" }}>Welcome!</span>}
            open={true}
            footer={[
              <Button key="logout" onClick={handleLogout} type="secondary">
                Logout
              </Button>,
              <Button
                key="accept"
                onClick={handleAcceptTerms}
                type="primary"
                disabled={!accepted}
              >
                Agree & Continue
              </Button>
            ]}
            closable={false}
          >
            <p>We have updated our legal terms and conditions. Please read the terms carefully before continuing.</p>
            <Collapse accordion>
              <Panel header="General Terms" key="1">
                <div style={{ maxHeight: "200px", overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: termsData.terms?.general_terms }} />
              </Panel>
              <Panel header="Privacy Policy" key="2">
                <div style={{ maxHeight: "200px", overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: termsData.terms?.privacy_policy }} />
              </Panel>
            </Collapse>
            <Checkbox
              onChange={(e) => setAccepted(e.target.checked)}
              checked={accepted}
              size="large"
              style={{ marginTop: "14px" }}
            >
              I, {username}, have read and accept the terms and conditions.
            </Checkbox>
          </Modal>
        </div>
      )}
    </>
  ) : (
    <Navigate to={`/?redirect=${redirectPath}`} />
  );
};

export default PrivateRoute;
