import { Row, Col, Result, Button } from "antd";
import house from "../../assets/images/house.png";

function Page500() {
  return (
    <Row
      className="welcome-card-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Col span={24}>
        <Result
          className="error-result"
          // status="500"
          icon={
            <>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                }}
                src={house}
                alt="500"
              />
              {/* <img src={error500} height="25%" width="25%" /> */}
            </>
          }
          subTitle="Ouch! You have discovered a problem in our site. We have received your
        error and are working frantically to fix it! We will be in touch as soon
        as we know what is causing this error. We are very sorry for the
        inconvenience"
          extra={
            <Button type="primary">
              <a href={window.location.origin}>Back Home </a>
            </Button>
          }
        />
      </Col>
    </Row>
  );
}

export default Page500;
