import CustomCard from "../../blocks/Card/Card";
import { Skeleton } from "antd";

const Loading = ({title}) => {
    return (
        <CustomCard title={title} className="title-2">
             <Skeleton />
        </CustomCard>
    )
}

export default Loading