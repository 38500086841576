import React from 'react'
import { useLocation, useParams } from "react-router-dom";
import BarChartTrend from '../../assets/images/barchart-trend.png';
import "./finalValuation.css";
import CustomCard from '../../blocks/Card/Card';
import { useFinalValuation } from "./useFinalValuation";
import { Loading, ErrorBoundary } from "../shared";
import { isMobileOnly } from 'react-device-detect';
import FinalValuationPieChart from './FinalValuationPieChart';

function formatNumberWithCommas(x) {
    try {
        if (!x) return "-";
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch {
        return x;
    }
}

function FinalValuation() {
    const params = useParams();
    const location = useLocation();

    let searchParams = new URLSearchParams(location.search);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useFinalValuation(filters, { enabled: params.reportName !== null });

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    if (isLoading) {
        return <Loading title="Comparables" />
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    let method_valuation = data?.method_valuation;
    method_valuation?.sort((a, b) => a.value - b.value);

    return (
        <ErrorBoundary>
            <CustomCard title="Final Valuation" className="title-2">
                <div className="final-valuation">
                    <div className="final-valuation-box">
                        {!isMobileOnly && <img alt="barchartTrendIcon" src={BarChartTrend} className="barchart-trend" />}
                        <div className="final-valuation-text">
                            <div className="final-valuation-text-top">
                                <span className="final-valuation-text-top-1">Final Valuation:</span>
                                <span className="final-valuation-text-top-2 valpro-avm">
                                    {data?.valpro_avm ? `$${formatNumberWithCommas(data?.valpro_avm)}` : "-"}
                                </span>
                            </div>
                            <span className="final-valuation-text-bottom">ValPro AVM is a blend of five proprietary
                                valuation
                                models and <strong>AI</strong> based adjustments
                                developed by Weiss Analytics, Inc.</span>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <FinalValuationPieChart piechartData={method_valuation} />
                </div>
            </CustomCard >
        </ErrorBoundary>
    )
}

export default FinalValuation