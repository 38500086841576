import { Fragment, useState, useEffect } from "react";
import { Empty, Radio, Skeleton, Typography } from "antd";
import { useMyUsage } from "./useMyUsage";
import MyUsageList from "./MyUsageList";
import "./MyUsage.css";
import { isMobileOnly } from "react-device-detect";

const { Title } = Typography;

function MyUsage() {
  // Retrieve currentTab from localStorage or set it to "property" if not found
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem("currentTab") || "property"
  );
  const { data, isLoading, isError } = useMyUsage();

  // Save currentTab to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("currentTab", currentTab);
  }, [currentTab]);

  if (isLoading) {
    return <Skeleton active />;
  }

  if (isError) {
    return <Empty description="Data unavailable" />;
  }

  return (
    <Fragment>
      <Radio.Group
        value={currentTab} // Use value instead of defaultValue to control the selected tab
        buttonStyle="solid"
        className="reports-usage-radio-button"
        onChange={(e) => setCurrentTab(e.target.value)}
        style={{ width: "100%", marginBottom: "16px" }}
      >
        <Radio.Button
          value="property"
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title level={5}>Property {isMobileOnly ? " " : "Reports"}</Title>
        </Radio.Button>
        <Radio.Button
          value="metro"
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title level={5}>Metro Reports</Title>
        </Radio.Button>
        <Radio.Button
          value="zip"
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title level={5}>ZIP Reports</Title>
        </Radio.Button>
        <Radio.Button
          value="avv"
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title level={5}>AVV Reports</Title>
        </Radio.Button>
      </Radio.Group>
      <MyUsageList data={data[currentTab]} reportType={currentTab} />
    </Fragment>
  );
}

export default MyUsage;
