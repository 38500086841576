export const setItem = (key, value, isObject = false) => {
  const data = isObject ? JSON.stringify(value) : value;
  localStorage.setItem(key, data);
};

export const getItem = (key, isObject = false) => {
  const value = localStorage.getItem(key);
  if (["access_token", "user"].includes(key) && (!value || value === "undefined" || value === "null")) {
    localStorage.clear();
  }
  const data = isObject ? JSON.parse(value) : value;
  return data;
};

export const clear = () => {
  localStorage.clear();
};
