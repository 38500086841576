import React from "react";
import { Card } from "antd";
import "./Card.css";

const CustomCard = React.forwardRef((props, ref) => {
  let classes = "default-card";
  if (props.className) {
    classes = classes.concat(" ").concat(props.className);
  }

  return (
    <Card bordered={false} {...props} className={classes} ref={ref}>
      {props.children}
    </Card>
  );
});

export default CustomCard;
