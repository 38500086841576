import { Col, Form, Radio, Row, Space } from "antd";
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { SketchPicker } from "react-color";
import "./ThemeChange.css";
import FontPicker from "font-picker-react";

function ThemeChange() {
  const contextData = useContext(ThemeContext);
  const [color, setColor] = useState(contextData.theme.primaryColor);
  const [font, setThemeFont] = useState(contextData.font.fontFamily);
  const setThemeTypeRef = useRef(contextData.setThemeType);
  const setFontRef = useRef(contextData.setFont);
  
  useEffect(() => {
    setThemeTypeRef.current({
      primaryColor: color.primaryColor || color,
      errorColor: color,
      warningColor: color,
      successColor: color,
      infoColor: color,
    });
    setFontRef.current({
      fontFamily: font,
    });
  }, [color, font]);

  const onRadioChange = (e) => {
    setColor(e.target.value);
  };

  const onColorChange = (nextColor) => {
    const mergedNextColor = { ...color, ...nextColor };
    setColor(mergedNextColor);
  };

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Radio.Group onChange={onRadioChange}>
            <Space>
              <Radio.Button
                className="red white-text"
                value="red"
              ></Radio.Button>
              <Radio.Button
                className="blue white-text"
                value="blue"
              ></Radio.Button>
              <Radio.Button
                className="green white-text"
                value="green"
              ></Radio.Button>
              <Radio.Button
                className="orange white-text"
                value="orange"
              ></Radio.Button>
              <Radio.Button
                className="crimson white-text"
                value="crimson"
              ></Radio.Button>
              <Radio.Button
                className="black white-text"
                value="black"
              ></Radio.Button>
            </Space>
          </Radio.Group>
        </Col>
        <Col span={12}>
          <Form layout="vertical">
            <Form.Item name="color" label="Pick Theme">
              <SketchPicker
                presetColors={[]}
                color={color}
                onChange={({ hex }) => {
                  onColorChange({
                    primaryColor: hex,
                  });
                }}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <p style={{ fontFamily: font }}>Select the font to apply</p>
          <FontPicker
            apiKey="AIzaSyBGw4glako2LCwspGSqrnnGdiiZApgy9vM"
            activeFontFamily={font}
            onChange={(nextFont) => setThemeFont(nextFont.family)}
            filter={(font) => !font.family.toLowerCase().startsWith("m")}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
export default ThemeChange;
