import GoogleMapReact from "google-map-react";
import React, { useEffect, useRef } from "react";
import "./Map.css";
import { isMobileOnly } from "react-device-detect";

const Map = ({ defaultKeys, zipCode, type, address, tabChange, mapIds }) => {
  let key = process.env.REACT_APP_MAP_KEY;
  const mapRef = useRef(null);
  const infoWindowRef = useRef(null);

  let isZipcode = type === "zipcode";

  useEffect(() => {
    console.log(mapRef.current);
  }, []);

  return (
    <div className="custom-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key }}
        defaultCenter={defaultKeys?.center}
        defaultZoom={15}
        center={defaultKeys?.center}
        zoom={15}
        yesIWantToUseGoogleMapApiInternals={true}
        options={{
          streetViewControl: true,
          mapTypeControl: isMobileOnly ? false : true,
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapIds?.map(
            (item) =>
              new maps.KmlLayer(
                `${
                  process.env.REACT_APP_BASE_URL
                }widgets/downloadkml/${zipCode}/${item}/?${
                  tabChange ? "forecast=1" : "forecast=0"
                }`,
                {
                  map: mapRef.current,
                  preserveViewport: !isZipcode,
                }
              )
          );
          var marker = new maps.Marker({
            position: defaultKeys.center,
            map: mapRef.current,
          });

          var infowindow = new maps.InfoWindow({
            content: address,
          });
          infoWindowRef.current = infowindow;

          maps.event.addListener(marker, "click", function () {
            if (infoWindowRef.current) {
              infoWindowRef.current.close();
            }
            infowindow.open(mapRef.current, marker);
            infoWindowRef.current = infowindow;
          });
        }}
      />
    </div>
  );
};
export default Map;
