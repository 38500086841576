
const ListingAddress = (props) => {
    const record = props.cell._cell.row.data;
    const cellValue = props?.cell?._cell?.value || "-";
    return (
        <ul style={{ listStyle: "none" }}>
            <li>{cellValue}</li>
            <li>
                {" "}
                {record?.City} {record?.State},
            </li>
            <li>{record?.Zip}</li>
        </ul>
    )
}

export default ListingAddress;