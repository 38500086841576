import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Vimeo from "@u-wave/react-vimeo";
import CustomCard from "../../blocks/Card/Card";
import { useBirdsEyeView } from "./useBirdsEyeView";
import { Loading, ErrorBoundary } from "../shared";

function BirdsEyeView() {
    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [elementHeight, setElementHeight] = useState(652);
    const elementRef = useRef(null);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }


    const { data, isLoading, isError } = useBirdsEyeView(filters, { enabled: params.reportName !== null });

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                let newHeight = elementRef.current.clientHeight;

                if (newHeight < 652) {
                    newHeight = 652;
                }
                setElementHeight(newHeight);
                window.parent.postMessage({ birdsEyeViewHeight: newHeight }, "*");
            }
        };
        if (elementHeight < 652) {
            setElementHeight(652);
        }
        updateHeight();
        window.addEventListener('resize', updateHeight);
    
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);
    
    if (elementHeight > 0) {
        window.parent.postMessage({ birdsEyeViewHeight: elementHeight || 652 }, "*");
    }

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }

    if (isLoading) {
        return <Loading title="Dynamic ZIP Code Map" />
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    return (
        <ErrorBoundary>
            <CustomCard
                ref={elementRef}
                title={
                    <>
                        <span>Dynamic ZIP Code Map</span>
                        {/* {dynamicMapInfo()} */}
                    </>
                }
                className="title-2 mb-2"
                style={{overflow: "hidden"}}
            >
                <Vimeo
                    video={data && data?.video}
                    controls={true}
                    responsive={true}
                    keyboard={true}
                    pip={true}
                    style={{ width: "100%", height: "100%" }}
                />
            </CustomCard>
        </ErrorBoundary>
    );
}

export default BirdsEyeView;
