import axiosInstance from "../../config/axios/axios.config";

export async function fetchAvvReports() {
  try {
    const response = await axiosInstance.get("widgets/avv/list/")
    return response.data;
  } catch (error) {
    if (error?.flag === "WidgetError") {
      return null;
    }
    throw new Error(`Failed fetch data. fetchAvvReports with error: ${error}`);
  }
}

