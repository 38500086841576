import { Row, Col, Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
function Page404() {
  const navigate = useNavigate();
  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Col>
        <Result
          status="404"
          title="404"
          subTitle={"Sorry, the page you visited does not exist."}
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </Col>
    </Row>
  );
}

export default Page404;
