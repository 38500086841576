import React, { useState } from 'react';
import { Form, Skeleton, Collapse, Input, Button, Row, Col, Card, Typography, message, Modal, Checkbox } from 'antd';
import { useMutation } from 'react-query';
import { USER, ACCESS_TOKEN, REFRESH_TOKEN } from '../../../constants/defaultKeys';
import Logo from '../../../assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { setItem } from '../../../helpers/localStorage';
import { register } from '../../../services/auth.services';
import { useTermsConditionsContent } from '../../../routes/useTermsConditionsContent';

const { Title } = Typography;
const { Panel } = Collapse;

const PhoneNumberInput = (props) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    // Format the input with hyphens
    if (inputValue.length > 3 && inputValue.length <= 6) {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`;
    } else if (inputValue.length > 6) {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
    }
    setValue(inputValue);
    // If you're using Form.Item, you might want to call props.onChange here to ensure Form knows about the change.
    if (props.onChange) {
      props.onChange(inputValue);
    }
  };

  return <Input {...props} value={value} onChange={handleChange} placeholder="123-456-7890" />;
};

const Signup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { data: termsData, isLoading: isTermsConditionsLoading, isError: isErrorTermsConditions } = useTermsConditionsContent();

  const onSuccess = (data) => {
    setItem(ACCESS_TOKEN, data?.data?.payload?.access_token);
    setItem(REFRESH_TOKEN, data?.data?.payload?.refresh_token);
    setItem(USER, JSON.stringify(data?.data?.payload));
    setIsLoading(false);
    navigate({ pathname: "/search" });
    message.success("You have successfully registered");
  };

  const onError = (error) => {
    setIsLoading(false);
    message.error(error.response.data.message);
  };

  const { mutate } = useMutation(register, {
    onError,
    onSuccess,
  });

  const onFinish = (values) => {
    if (!termsAccepted) {
      message.error('You must accept the terms and conditions to register.');
      return;
    }
    setIsLoading(true);
    mutate(values);
  };

  const showTermsModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  if (isTermsConditionsLoading || !termsData) {
    return <Skeleton active />;
  }

  if (isErrorTermsConditions) {
    return null;
  }

  return (
    <Row type="flex" justify="center" align="middle" className="login">
      <Col
        lg={{ span: 8, offset: 8 }}
        md={{ span: 12, offset: 6 }}
        sm={{ span: 23 }}
        xs={{ span: 23 }}
        style={{ marginLeft: 0 }}
      >
        <Card
          bordered={false}
          style={{ padding: "20px 20px 10px 20px", borderRadius: "6px" }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={Logo} style={{ width: "250px", height: "auto" }} alt="logo" />

            <Title level={4} type="secondary" style={{ marginTop: "25px" }}>
              Subscribe
            </Title>
          </div>
          <Form name="signup" onFinish={onFinish}>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name",
                    },
                  ]}
                >
                  <Input autoFocus={true} size="large" placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="phone_number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number",
                    },
                    {
                      pattern: new RegExp(/^(?:\+1)?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
                      message: "Please enter a valid US phone number",
                    },
                  ]}
                >
                  <PhoneNumberInput size="large" />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
              <Form.Item
                  name="street"
                  rules={[
                    {
                      required: true,
                      message: "Please input your street address",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Street Address" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please input your city",
                    },
                  ]}
                >
                  <Input size="large" placeholder="City" />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "Please input your state",
                    },
                  ]}
                >
                  <Input size="large" placeholder="State" />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  name="zipcode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your zip code",
                    },
                  ]}
                >
                  <Input size="large" placeholder="ZIP Code" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  name="confirm_password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The new passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" type="password" placeholder="Confirm Password" />
                </Form.Item>
              </Col>
            </Row>
            <Checkbox name="terms" valuePropName="checked" rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('You must accept the terms and conditions to proceed.')), },]} checked={termsAccepted} onChange={handleTermsChange}>
              I have read and agree to the <Button type="link" onClick={showTermsModal} style={{ padding: 0, height: 'auto', lineHeight: 'inherit' }}>
                Terms and Conditions
              </Button>
            </Checkbox>
            <Form.Item style={{ marginTop: "10px" }}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                className="login-form-button"
                loading={isLoading}
              >
                Sign Up
              </Button>
            </Form.Item>
          </Form>
          <Modal title="Terms and Conditions" open={isModalVisible} onOk={handleOk} onCancel={handleOk}>
            {/* Your terms and conditions text goes here. */}
            <Collapse accordion>
              <Panel header="General Terms" key="1">
                <div style={{ maxHeight: "200px", overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: termsData?.terms?.general_terms }} />
              </Panel>
              <Panel header="Privacy Policy" key="2">
                <div style={{ maxHeight: "200px", overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: termsData?.terms?.privacy_policy }} />
              </Panel>
            </Collapse>
          </Modal>
          <div>Already have an account?
            <Link to="/" className="footer-links" style={{ marginLeft: "4px" }}>
              Log In
            </Link>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Signup;
