import BrowserDashboard from "./BrowserDashboard";
import MobileDashboard from "./MobileDashboard";
import { isMobile } from "react-device-detect";
import { Fragment } from "react";

function Dashboard() {
  return (
    <Fragment>{isMobile ? <MobileDashboard /> : <BrowserDashboard />}</Fragment>
  );
}

export default Dashboard;
