import axiosInstance from "../../config/axios/axios.config";

export async function fetchIndexToolsData(filters) {
  const { code, reportName, propType, queryParams, token } = filters;
  if (token) {
    queryParams.append("token", token)
  }
  let queryString = "";
  if (queryParams) {
    queryString = queryParams.toString()
  }
  let url = `widgets/${encodeURIComponent(reportName)}/${encodeURIComponent(propType)}/${encodeURIComponent(code)}/indextools/?${queryString}`
  try {
    const response = await axiosInstance.get(url)
    return response.data;
  } catch (error) {
    if (error?.flag === "WidgetError") {
      return null;
    }
    throw new Error(`Failed fetch data. fetchIndexToolsData with error: ${error}`);
  }
}

export async function fetchAdditionalData(filters) {
  const { code, report_address, prop_type, customer_id, cbsa_code } = filters;
  let url = "widgets/additional/indexdata/"
  let postData = {
    code: code,
    report_address: report_address,
    prop_type: prop_type,
    customer_id: customer_id, 
    cbsa_code: cbsa_code
  }
  try {
    const response = await axiosInstance.post(url, postData)
    return response.data;
  } catch (error) {
    if (error?.flag === "WidgetError") {
      return null;
    }
    throw new Error(`Failed fetch data. fetchAdditionalData with error: ${error}`);
  }
}