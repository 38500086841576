import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Skeleton, Empty } from "antd";
import CustomCard from "../../blocks/Card/Card";
import { useSummary } from "./useSummary";
import PropertySummary from "./PropertySummary";
import MetroSummary from "./MetroSummary";
import ZipSummary from "./ZipSummary";
import "../../components/Report/Report.css";
import "../../components/Report/Metro/Metro.css";
import { ErrorBoundary } from "../shared";


function Summary() {

    const params = useParams();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);

    if (location?.pathname?.includes("report")) {
        searchParams = new URLSearchParams({
            ...(searchParams ? searchParams : {}),
            isWebsite: true
        });
    }
    const filters = { ...params, queryParams: searchParams }
    const { data, isLoading, isError } = useSummary(filters, { enabled: params.reportName !== null });
    
    let summaryText = params?.propType === "address" ? "Property Summary" : params?.propType === "metro" ? "Metro Summary" : params?.propType === "zip_code" ? "Zip Code Summary" : "Summary";

    if (isLoading) {
        return (
            <CustomCard title={summaryText} className="title-2">
                <Skeleton />
            </CustomCard>
        )
    }

    if (isError) {
        window.parent.postMessage("NO DATA", "*");
        return null
    }

    if (!isLoading && !isError && (!data || data === null)) {
        window.parent.postMessage("NO DATA", "*");
        return null;
    }

    
    if (params?.propType === "address") {
        return <ErrorBoundary><PropertySummary data={data?.address} /></ErrorBoundary>
    } else if (params?.propType === "metro") {
        return <ErrorBoundary><MetroSummary data={data?.metro} /></ErrorBoundary>
    } else if (params?.propType === "zip_code") {
        return <ErrorBoundary><ZipSummary data={data?.zip} /></ErrorBoundary>
    } else {
        return (
            <CustomCard title="Summary" className="title-2">
                <Empty description="Data Unavailable" />
            </CustomCard>
        )
    }

}

export default Summary;
