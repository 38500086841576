import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CountUp from "react-countup";

const Title = (value) => {
  return (
    <>
      <span>Adjusted Value: </span>
      <CountUp end={value} separator="," prefix="$" />
    </>
  );
};
const ValueAdjustmentModal = ({
  weightedAverage,
  visible,
  onClose,
  adjustedValues,
  setAdjustedValues,
  adjustedValue,
}) => {
  const [form] = Form.useForm();
  const [updatedValue, setUpdatedValue] = useState(
    weightedAverage || 0 + adjustedValue
  );
  const [initialValue, setInitialValue] = useState(0);

  const updateInitialValue = useCallback(() => {
    let totalInitialValue = adjustedValues?.reduce((acc, adjustment) => {
      if (adjustment?.type && adjustment?.amount) {
        const amount =
          adjustment?.type === "positive"
            ? adjustment?.amount
            : -adjustment?.amount;
        return parseInt(acc) + parseInt(amount || 0);
      }
      return acc;
    }, 0);
    setInitialValue(parseInt(totalInitialValue) || 0);
  }, [adjustedValues]);

  useEffect(() => {
    updateInitialValue();
  }, [updateInitialValue]);

  const calculateTotal = (values) => {
    const total = values?.adjustments?.reduce((acc, adjustment) => {
      if (adjustment?.type && adjustment?.amount) {
        const amount =
          adjustment?.type === "positive"
            ? adjustment?.amount
            : -adjustment?.amount;
        return parseInt(acc) + parseInt(amount || 0);
      }
      return acc;
    }, 0);
    setInitialValue(0);
    setUpdatedValue((parseInt(total) || 0) + (parseInt(weightedAverage) || 0));
  };

  const onFinish = (values) => {
    setAdjustedValues(values?.adjustments);
    onClose(false);
  };

  return (
    <Modal
      title={Title((parseInt(updatedValue) || 0) + initialValue)}
      open={visible}
      onCancel={() => onClose(false)}
      cancelText="Cancel"
      footer={null}
    >
      <Form
        form={form}
        name="value_adjustments"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={() => calculateTotal(form.getFieldsValue())}
      >
        <Form.List
          name="adjustments"
          initialValue={adjustedValues} // Start with one empty row
          rules={[
            {
              validator: async (_, adjustments) => {
                if (!adjustments || adjustments.length < 1) {
                  return Promise.reject(
                    new Error("At least one adjustment is required")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "description"]}
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <Input placeholder="Description" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                    rules={[{ required: true, message: "Type is required" }]}
                  >
                    <Select placeholder="Select type">
                      <Select.Option value="positive">Positive</Select.Option>
                      <Select.Option value="negative">Negative</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "amount"]}
                    rules={[{ required: true, message: "Amount is required" }]}
                  >
                    <Input placeholder="Amount" type="number" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Adjustment
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          style={{
            display: "flex",
            justifyContent: "end",
            justifyItems: "center",
          }}
        >
          {/* add cancel button */}
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "12px" }}
          >
            Apply Adjustments
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ValueAdjustmentModal;
