import { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Divider,
  Statistic,
  Tooltip,
  Card,
  Space,
  Checkbox,
} from "antd";
import CompFormModal from "./CompFormModal";
import { InfoCircleOutlined, EditOutlined } from "@ant-design/icons";
import { formatDate } from "../../../helpers/utility";

const { Paragraph } = Typography;

const infoText =
  "This is the value this comp implies the subject home has. This is calculated by adjusting this comp's prior sale to the present using its Weiss Index and then adjusting for the comp's differences to the subject home";

const GridView = ({ data, setValData, subject_comp }) => {
  const [editList, setEditList] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  let sortedData = data.sort((a, b) => {
    return a.weight > b.weight ? -1 : 1;
  });
  const openModal = (data) => {
    setEditList(data);
    setFormVisible(true);
  };

  function onChange(e, val) {
    data.filter((item) => item.WRRID === val.WRRID)[0].contributor = e.target
      .checked
      ? 1
      : 0;
    setValData([...data]);
  }
  return (
    <Row gutter={[12, 12]}>
      {sortedData?.map((item, ind) => (
        <Col span={8} key={item?.WRRID}>
          <Card
            title={item?.address}
            className="comp-grid-cards"
            extra={
              <Space>
                <span onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    size="large"
                    className="comp-grid-checkbox"
                    checked={item?.contributor}
                    onChange={(e) => onChange(e, item)}
                  />
                </span>
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => openModal(item)}
                />
              </Space>
            }
          >
            <Row>
              <Col span={12}>
                <Paragraph className="implied-and-last-sale-text">
                  IMPLIED VALUE{" "}
                  <Tooltip title={infoText}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Paragraph>
                <Paragraph className="implied-and-last-sale-value">
                  ${" "}
                  {item?.implyingValue && item?.implyingValue !== 0
                    ? item?.implyingValue
                    : "-"}
                </Paragraph>
              </Col>
              <Col span={12}>
                <Paragraph className="implied-and-last-sale-text">
                  LAST SALE | {formatDate(item?.TransferDate)}
                </Paragraph>
                <Paragraph className="implied-and-last-sale-value">
                  ${" "}
                  {item?.TransferValue && item?.TransferValue !== 0
                    ? item?.TransferValue
                    : "-"}
                </Paragraph>
              </Col>
            </Row>

            <Divider />
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Statistic
                  title="SIMILARITY"
                  value={
                    item?.weight && item?.weight !== 0
                      ? `${Math.floor(item?.weight * 100)}%`
                      : "-"
                  }
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="BEDS"
                  value={item?.beds && item?.beds !== 0 ? item?.beds : "-"}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="BATHS"
                  value={item?.baths && item?.baths !== 0 ? item?.baths : "-"}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="SQFT"
                  value={item?.size && item?.size !== 0 ? item?.size : "-"}
                />
              </Col>
              <Col span={8}>
                <Paragraph className="implied-and-last-sale-text">
                  YR EST
                </Paragraph>
                <span className="implied-and-last-sale-value comp-age">
                  {item?.age && item?.age !== 0 ? item?.age : "-"}
                </span>
              </Col>
              <Col span={8}>
                <Statistic title="DISTANCE" value={item?.distance} />
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
      {formVisible && (
        <CompFormModal
          setIsVisible={setFormVisible}
          isVisible={formVisible}
          editList={editList}
          setValData={setValData}
          data={sortedData}
          subject_comp={subject_comp}
        />
      )}
    </Row>
  );
};

export default GridView;
