import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Row, Skeleton, Typography } from "antd";
import CustomCard from "../../blocks/Card/Card";
import { isBrowser } from "react-device-detect";
import bed from "../../assets/images/bed.png";
import area from "../../assets/images/area.png";
import calendar from "../../assets/images/calendar.png";
import shower from "../../assets/images/shower.png";
import pool from "../../assets/images/pool.png";
import sqft from "../../assets/images/sqft.webp";
import "../../components/Report/Report.css";
import "../../components/Report/Property/Property.css";
import { useHouseMeasures } from "./useHouseMeasures";
import { isMobileOnly } from "react-device-detect";
import { ErrorBoundary } from "../shared";

const { Title } = Typography;
const ACRE = 43560;

function HouseMeasures() {
  const params = useParams();
  const location = useLocation();
  const [elementHeight, setElementHeight] = useState(0);
  const elementRef = useRef(null);

  let searchParams = new URLSearchParams(location.search);

  if (location?.pathname?.includes("report")) {
    searchParams = new URLSearchParams({
      ...(searchParams ? searchParams : {}),
      isWebsite: true,
    });
  }
  const filters = { ...params, queryParams: searchParams };
  const { data, isLoading, isError } = useHouseMeasures(filters, {
    enabled: params.reportName !== null,
  });

  useEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        const newHeight = elementRef.current.clientHeight + 34;
        setElementHeight(newHeight);
        window.parent.postMessage({ houseMeasuresHeight: newHeight }, "*");
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [data, elementHeight, elementRef]);

  if (isLoading) {
    window.parent.postMessage("Data Loading", "*");
    return (
      <CustomCard title="House Measures" className="title-2">
        <Skeleton />
      </CustomCard>
    );
  }

  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }
  if (!isLoading && !isError && (!data || data === null)) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }
  let lotSize = data?.lot_size;
  let lotLabel = "LOT";

  if (lotSize >= ACRE) {
    lotSize = (lotSize / 43560).toFixed(2);
    lotLabel = "LOT(ACRE)";
  } else {
    lotSize = `${lotSize}`;
  }
  return (
    <ErrorBoundary>
      <CustomCard
        ref={elementRef}
        title={<span className="mobile-title-font">House Measures</span>}
        className="title-2"
      >
        <Row
          gutter={isMobileOnly ? [32, 32] : [16, 16]}
          type="flex"
          align="middle"
          className="house-measures-container"
        >
          <Col
            lg={isBrowser ? { span: 8 } : { span: 14 }}
            md={{ span: 8 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <img src={calendar} alt="calendar" width="24px" height="auto" />
            <Title level={isMobileOnly ? 5 : 4}>{data?.age}</Title>
            <small>YR BUILT</small>
          </Col>
          <Col
            lg={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <img src={sqft} alt="sqft" width="28px" height="auto" />
            <Title level={isMobileOnly ? 5 : 4}>{data?.size}</Title>
            <small>SQFT</small>
          </Col>
          <Col
            lg={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <img src={area} alt="area" width="24px" height="auto" />
            <Title level={isMobileOnly ? 5 : 4}>{lotSize}</Title>
            <small>{lotLabel}</small>
          </Col>
          <Col
            lg={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <img src={bed} alt="bed" width="24px" height="auto" />
            <Title level={isMobileOnly ? 5 : 4}>{data?.beds}</Title>
            <small>BEDS</small>
          </Col>
          <Col
            lg={isBrowser ? { span: 8 } : { span: 14 }}
            md={{ span: 8 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <img src={shower} alt="shower" width="24px" height="auto" />
            <Title level={isMobileOnly ? 5 : 4}>{data?.baths}</Title>
            <small>BATHS</small>
          </Col>
          <Col
            lg={isBrowser ? { span: 8 } : { span: 14 }}
            md={{ span: 8 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <img src={pool} alt="shower" width="28px" height="auto" />
            <Title level={isMobileOnly ? 5 : 4}>
              {data?.has_pool ? "Yes" : "No"}
            </Title>
            <small>POOL</small>
          </Col>
        </Row>
      </CustomCard>
    </ErrorBoundary>
  );
}

export default HouseMeasures;
