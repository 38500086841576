import React, { useState, useEffect, useCallback } from 'react';
import { Upload, Button, Typography, Row, Col, Card, message, Progress, Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axiosInstance from '../../config/axios/axios.config';
import axios from 'axios';
import PropertyResults from "../../components/Toolbar/PropertyResults"
import { useNavigate, useParams } from "react-router-dom";

const { Dragger } = Upload;
const { Title } = Typography;


const ImageUpload = (props) => {
    const params = useParams();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchedResult, setSearchedResult] = useState(params?.reportName || null);
    const [fileCount, setFileCount] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const checkAvvAccess = useCallback(async () => {
        try {
            const response = await axiosInstance.post('/widgets/user/report_limit/', {address: params?.reportName});
            // const response = await axiosInstance.get('/widgets/user/report_limit/');
            let canAccess = response?.data?.can_access
            if (!canAccess) {
                message.error('You do not have access to this AVV report.');
                navigate("/");  
            }
        } catch (error) {
            message.error('Error checking AVV report access. Please try again.');
            navigate("/error-route"); // Redirecting to an error route
        }
    }, [params?.reportName, navigate]); 

    useEffect(() => {
        checkAvvAccess();
    }, [checkAvvAccess]);

    const showFileListModal = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };


    const onFileChange = ({ fileList }) => {
        console.log("fileList: ", fileList);
        setFiles(fileList);
        setFileCount(fileList.length);
    };


    const onSave = async () => {
        setLoading(true);

        try {
            const formattedFiles = files.map(file => {
                let type = file.type;

                // Manually set the MIME type for .HEIC files if the browser doesn't provide one
                if (file.name.toLowerCase().endsWith('.heic') && !type) {
                    type = 'image/heic';
                }

                return { name: file.name, type: type };
            });

            const presignedUrls = await axiosInstance.post('widgets/avv/get_presigned_url/', { files: formattedFiles });

            const s3Urls = await Promise.all(presignedUrls?.data?.presignedUrls?.map(async (url, index) => {
                const headers = {};

                if (formattedFiles[index].type) {
                    headers['Content-Type'] = formattedFiles[index].type;
                }

                await axios.put(url, files[index].originFileObj, {
                    headers: headers,
                });

                return url.split('?')[0];
            }));

            const formData = new FormData();
            formData.append('address', searchedResult);
            formData.append('image_urls', JSON.stringify(s3Urls));
            await axiosInstance.post('widgets/avv/create/', formData);

            message.success('Images uploaded successfully.');
            setSearchedResult(null);
            setFiles([]);
            setFileCount(0);
            navigate({ pathname: "/search" });

        } catch (error) {
            console.log("Error uploading: ", error);
            message.error('Error uploading images. Please try again.');
            setSearchedResult(null);
            setFiles([]);
            setFileCount(0);
        } finally {
            setLoading(false);
            setFileCount(0);
        }
    };

    const onCancel = () => {
        setSearchedResult(null);
        setFiles([]);
    };

    const handleBeforeUpload = (file) => {
        if (!searchedResult) {
            message.warning('Please enter an address before uploading images.');
            return false;
        }
        return false; // To prevent auto-upload
    };

    return (
        <Card
            title={<Title level={4}>Automated Vision Valuation</Title>}
            style={{ marginTop: 70, width: "90%", marginLeft: "5%", marginRight: "5%" }}
        >
            <Row>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <PropertyResults
                        setSearchedResult={setSearchedResult}
                        placeholderText="Search for Property"
                        showOnlyProperty={true}
                        existingSearchText = {params?.reportName}
                    />
                </Col>
                <Col span={24} style={{ marginBottom: 20, display: "flex", justifyContent: "center", alignContent: "center" }}>
                    {/* <span>{fileCount} files selected</span> */}
                    {fileCount > 0 && <Button onClick={showFileListModal}>{fileCount} Files</Button>}
                </Col>
                <Col span={24}>
                    <Dragger
                        multiple
                        disabled={!searchedResult}
                        beforeUpload={handleBeforeUpload}
                        onChange={onFileChange}
                        fileList={files}
                        showUploadList={false}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            {searchedResult ? 'Address: ' + searchedResult : 'Please enter an address before uploading images.'}
                        </p>
                    </Dragger>
                    <Modal
                        title="Uploaded Files"
                        open={isModalVisible}
                        onCancel={handleModalClose}
                        footer={null}  // this will hide the default footer, you can customize it if needed
                    >
                        <ul>
                            {files.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </Modal>
                </Col>
                {loading && (
                    <Progress
                        type="line"
                        percent={100}
                        status="active"
                        showInfo={false}
                        strokeWidth={25}
                        style={{ fontSize: '24px', marginTop: 20 }}
                    />
                )}
                <Col span={24} style={{ display: "flex", justifyContent: "flex-end", gap: 10, alignItems: "center", height: "80px" }}>
                    <Button
                        onClick={onCancel}
                        size='large'
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        disabled={!searchedResult || loading}
                        onClick={onSave}
                        size='large'
                    >
                        RUN AVV
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export default ImageUpload;
