import React from "react";
import { Tooltip } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getColorForValue } from "../shared";

export const getSliderMarks = (yearsBack = 3) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentYear = new Date().getFullYear() + 1;
  const startYear = currentYear - yearsBack;
  let marks = {};

  for (let i = 0; i < yearsBack * 12; i++) {
    const year = startYear + Math.floor(i / 12);
    if (i % 12 === 0) {
      marks[i] = (
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
          {year.toString()}
        </span>
      ); // Render the year with custom styling
    } else {
      marks[i] = months[i % 12];
    }
  }

  return marks;
};

export const getHouseColor = (value = 0) => {
  let startColor, endColor;

  if (value < 0) {
    startColor = { r: 255, g: 165, b: 0 }; // Orange
    endColor = { r: 255, g: 255, b: 0 }; // Yellow
  } else {
    startColor = { r: 255, g: 255, b: 0 }; // Yellow
    endColor = { r: 0, g: 128, b: 0 }; // Dark Green
  }

  const ratio = (value + 1) / 2; // Normalize the value to be between 0 and 1

  const r = Math.round(startColor.r + ratio * (endColor.r - startColor.r));
  const g = Math.round(startColor.g + ratio * (endColor.g - startColor.g));
  const b = Math.round(startColor.b + ratio * (endColor.b - startColor.b));
  return `rgb(${r}, ${g}, ${b})`;
};

export const HouseCircle = ({ color, houseDetail, to, mapContainerRef }) => {
  const popoverProps = {
    getPopupContainer: () => mapContainerRef.current,
    overlayStyle: {
      zIndex: 9999,
    },
  };

  let TitleData = () => {
    return (
      <div style={{ color: "#434343", fontWeight: "bolder" }}>
        <p>{houseDetail}</p>
        <Link to={to}>View House Report</Link>
      </div>
    );
  };
  return (
    <Tooltip title={<TitleData />} color="#f5f5f5" {...popoverProps}>
      <Link to={null}>
        <EnvironmentFilled
          style={{ fontSize: "20px", color: getColorForValue(color) }}
        />
      </Link>
    </Tooltip>
  );
};
