import { useState } from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Card,
  Statistic,
  Form,
  InputNumber,
  Modal,
  Skeleton,
  message,
} from "antd";
import {
  ExpandOutlined,
  CompressOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ListView from "./ListView";
import GridView from "./GridView";
import CompModal from "./CompModal";
import OwnValutionModal from "./OwnValutionModal";
import ValueAdjustmentModal from "./ValueAdjustmentModal";
import { isMobileOnly } from "react-device-detect";
import axiosInstance from "../../../config/axios/axios.config";

function mergeObjects(firstObject, secondObject) {
  let mergedData = firstObject.map((item) => {
    let newItem = secondObject.find((element) => element.WRRID === item.WRRID);
    if (newItem) {
      return { ...newItem, contributor: item.contributor };
    }
    return item;
  });
  return mergedData;
}

const Comp = ({
  WRRID,
  data,
  setValData,
  compFullWidth,
  customCompVal,
  setCustomCompVal,
  setCompFullWidth,
  weightedAverage,
  setAdjustedValue,
  subject_comp,
  setSubjectComp,
  setPropertyUpdateLoading,
  properUpdateLoading,
  setAdjustedValues,
  adjustedValues,
  adjustedValue,
}) => {
  const [compModalVisible, setCompModalVisible] = useState(false);
  const [ownValModalVisible, setOwnValModalVisible] = useState(false);
  const [valueAdjModalVisible, setValueAdjModalVisible] = useState(false);
  const [isUpdatePropertyModalVisible, setIsUpdatePropertyModalVisible] =
    useState(false);
  const [form] = Form.useForm();

  const customCompModel = () => {
    setCompModalVisible(true);
  };

  const ownValutionModel = () => {
    setOwnValModalVisible(true);
  };

  const openValueAdjustmentModal = () => {
    setValueAdjModalVisible(true);
  };

  const showPropertyModal = () => {
    setIsUpdatePropertyModalVisible(true);

    form.setFieldsValue({
      age:
        subject_comp?.age && subject_comp?.age !== 0 ? subject_comp?.age : null,
      size:
        subject_comp?.size && subject_comp?.size !== 0
          ? subject_comp?.size
          : null,
      beds:
        subject_comp?.beds && subject_comp?.beds !== 0
          ? subject_comp?.beds
          : null,
      baths:
        subject_comp?.baths && subject_comp?.baths !== 0
          ? subject_comp?.baths
          : null,
      Lot:
        subject_comp?.Lot && subject_comp?.Lot !== 0 ? subject_comp?.Lot : null,
    });
  };

  const handleUpdatePropertyCancel = () => {
    setIsUpdatePropertyModalVisible(false);
  };

  const onPropertyUpdateFinish = (values) => {
    if (values?.age === 0 || values?.age === null || isNaN(values?.age)) {
      message.error("Please provide a valid year built");
      return;
    }
    if (values?.size === 0 || values?.size === null || isNaN(values?.size)) {
      message.error("Please provide a valid size");
      return;
    }
    if (values?.beds === 0 || values?.beds === null || isNaN(values?.beds)) {
      message.error("Please provide a valid number of beds");
      return;
    }
    if (values?.baths === 0 || values?.baths === null || isNaN(values?.baths)) {
      message.error("Please provide a valid number of baths");
      return;
    }
    if (values?.Lot === 0 || values?.Lot === null || isNaN(values?.Lot)) {
      message.error("Please provide a valid lot size");
      return;
    }

    values = { ...values, subjectID: subject_comp?.WRRID };
    try {
      setPropertyUpdateLoading(true);
      axiosInstance
        .post("/widgets/update_subject_house/", values)
        .then((response) => {
          let mergedData = mergeObjects(data, response?.data?.val_data);
          setValData(mergedData);
          setSubjectComp(response?.data?.subject_comp);
          setPropertyUpdateLoading(false);
          setIsUpdatePropertyModalVisible(false);
        })
        .catch((error) => {
          console.log("Response error: ", error);
          setPropertyUpdateLoading(false);
          setIsUpdatePropertyModalVisible(false);
          message.error("Error updating property");
        });
    } catch (error) {
      console.log("Error: ", error);
      setPropertyUpdateLoading(false);
      setIsUpdatePropertyModalVisible(false);
      message.error("Error updating property");
    }
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24} xs={24} style={{ textAlign: "center", marginTop: "20px" }}>
        <Row gutter={[3, 12]}>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 5 }}
            style={{ textAlign: "center" }}
          >
            <Button
              style={{ minWidth: 120 }}
              type="primary"
              size="small"
              onClick={customCompModel}
            >
              Custom Comp
            </Button>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 6 }}
            style={{ textAlign: "center" }}
          >
            <Button
              style={{ minWidth: 120 }}
              size="small"
              onClick={ownValutionModel}
            >
              Own Valuation
            </Button>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 6 }}
            style={{ textAlign: "center" }}
          >
            <Button
              style={{ minWidth: 130 }}
              type="primary"
              size="small"
              onClick={openValueAdjustmentModal}
            >
              Value Adjustment
            </Button>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 6 }}
            style={{ textAlign: "center" }}
          >
            <Button
              style={{ minWidth: 120 }}
              size="small"
              onClick={showPropertyModal}
            >
              Update Property
            </Button>
          </Col>
          {!isMobileOnly && (
            <Col span={1} style={{ textAlign: "right" }}>
              <span
                onClick={() => setCompFullWidth(!compFullWidth)}
                style={{
                  cursor: "pointer",
                }}
              >
                {compFullWidth ? <CompressOutlined /> : <ExpandOutlined />}
              </span>
            </Col>
          )}
          {customCompVal && (
            <Col span={22} offset={1}>
              <Card
                className="custom-comp-card"
                extra={
                  <CloseOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => setCustomCompVal(null)}
                  />
                }
                title={customCompVal?.type_of_valution?.toUpperCase()}
              >
                <Statistic
                  title="USER VALUATION"
                  value={customCompVal?.sale_price}
                />
              </Card>
            </Col>
          )}
        </Row>
        <Divider orientation="center">
          Or, select from our list of comps:
        </Divider>
      </Col>
      <Col span={24} className="comp comp-stats">
        {compFullWidth ? (
          <GridView
            data={data}
            setValData={setValData}
            subject_comp={subject_comp}
          />
        ) : (
          <ListView
            data={data}
            setValData={setValData}
            subject_comp={subject_comp}
          />
        )}
      </Col>
      {compModalVisible && (
        <CompModal
          WRRID={WRRID}
          data={data}
          setValData={setValData}
          compModalVisible={compModalVisible}
          setCompModalVisible={setCompModalVisible}
        />
      )}
      {ownValModalVisible && (
        <OwnValutionModal
          setCustomCompVal={setCustomCompVal}
          ownValModalVisible={ownValModalVisible}
          setOwnValModalVisible={setOwnValModalVisible}
        />
      )}
      {valueAdjModalVisible && (
        <ValueAdjustmentModal
          visible={valueAdjModalVisible}
          onClose={setValueAdjModalVisible}
          weightedAverage={weightedAverage}
          setAdjustedValue={setAdjustedValue}
          adjustedValues={adjustedValues}
          setAdjustedValues={setAdjustedValues}
          adjustedValue={adjustedValue}
        />
      )}
      <Modal
        title="Edit Property"
        open={isUpdatePropertyModalVisible}
        onCancel={handleUpdatePropertyCancel}
        footer={null}
      >
        <Skeleton loading={properUpdateLoading} active>
          <Form form={form} onFinish={onPropertyUpdateFinish} layout="vertical">
            <Form.Item
              label="Year Built"
              name="age"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value === 0 &&
                      form.getFieldsValue().every((field) => field === 0)
                    ) {
                      return Promise.reject(
                        "At least one field must be non-zero."
                      );
                    }
                    return Promise.resolve();
                  },
                  message: "Please provide a non-zero value",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Size (Sq Ft)"
              name="size"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value === 0 &&
                      form.getFieldsValue().every((field) => field === 0)
                    ) {
                      return Promise.reject(
                        "At least one field must be non-zero."
                      );
                    }
                    return Promise.resolve();
                  },
                  message: "Please provide a non-zero value",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Beds"
              name="beds"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value === 0 &&
                      form.getFieldsValue().every((field) => field === 0)
                    ) {
                      return Promise.reject(
                        "At least one field must be non-zero."
                      );
                    }
                    return Promise.resolve();
                  },
                  message: "Please provide a non-zero value",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Baths"
              name="baths"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value === 0 &&
                      form.getFieldsValue().every((field) => field === 0)
                    ) {
                      return Promise.reject(
                        "At least one field must be non-zero."
                      );
                    }
                    return Promise.resolve();
                  },
                  message: "Please provide a non-zero value",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Lot"
              name="Lot"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value === 0 &&
                      form.getFieldsValue().every((field) => field === 0)
                    ) {
                      return Promise.reject(
                        "At least one field must be non-zero."
                      );
                    }
                    return Promise.resolve();
                  },
                  message: "Please provide a non-zero value",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Skeleton>
      </Modal>
    </Row>
  );
};

export default Comp;
