import React, { useState, useRef, useEffect } from "react";
import CustomCard from "../../blocks/Card/Card";
import { Table } from "antd";

function getProperSummary(data) {
    let dataSource = [
        {
            key: "1",
            category: "Property",
            one_year: data?.forecast,
            last_year: data?.oneYearChange,
            ten_year: data?.tenYearChange,
        },
        {
            key: "2",
            category: "ZIP",
            one_year: data?.zipforcast,
            last_year: data?.zip_one_year_change,
            ten_year: data?.zip_ten_year_change,
        },
        {
            key: "3",
            category: "Metro",
            one_year: data?.metroforcast,
            last_year: data?.metro_one_year_change,
            ten_year: data?.metro_ten_year_change,
        },
    ];

    const columns = [
        {
            title: "",
            dataIndex: "category",
            key: "category",
            responsive: ['xs', 'sm'],
            width: "25%",
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Last Ten Years</span>,
            dataIndex: "ten_year",
            key: "ten_year",
            render: (value) => `${value !== "-" ? value + ' %' : value}`,
            responsive: ['xs', 'sm'],
            width: "25%",
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>Last One Year</span>,
            dataIndex: "last_year",
            key: "last_year",
            render: (value) => `${value !== "-" ? value + ' %' : value}`,
            responsive: ['xs', 'sm'],
            width: "25%",
            className: 'centered-column',
        },
        {
            title: <span style={{ textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>One Year Forecast</span>,
            dataIndex: "one_year",
            key: "one_year",
            render: (value) => `${value !== "-" ? value + ' %' : value}`,
            responsive: ['xs', 'sm'],
            width: "25%",
            className: 'centered-column',
        },
    ];

    if (data?.forecast === "-" && data?.oneYearChange === "-" && data?.tenYearChange === "-") {
        dataSource = dataSource.filter((item) => item.category !== "Property");
    } else if (data?.zipforcast === "-" && data?.zip_one_year_change === "-" && data?.zip_ten_year_change === "-") {
        dataSource = dataSource.filter((item) => item.category !== "ZIP");
    } else if (data?.metroforcast === "-" && data?.metro_one_year_change === "-" && data?.metro_ten_year_change === "-") {
        dataSource = dataSource.filter((item) => item.category !== "Metro");
    }
    return { dataSource, columns }
}

function PropertySummary({ data }) {
    const { dataSource, columns } = getProperSummary(data);
    const [elementHeight, setElementHeight] = useState(375);
    const elementRef = useRef(null);


    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                const newHeight = elementRef.current.clientHeight + 42;
                setElementHeight(newHeight);
                window.parent.postMessage({ propertySummaryHeight: newHeight }, "*");
            } 
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [data, elementHeight, elementRef]);

    return (
        <CustomCard
            title={<span className="mobile-title-font">Property Summary</span>}
            className="title-2 responsive-table-property-summary"
            ref={elementRef}
        >
            <Table dataSource={dataSource} columns={columns} pagination={false} />
        </CustomCard>
    );
}

export default PropertySummary;
