import React from 'react';
import { Col, Dropdown, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const HamburgerMenu = ({menu}) => {

  return (
    <Col>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type="primary" icon={<MenuOutlined />} />
      </Dropdown>
    </Col>
  );
};

export default HamburgerMenu;
