import { getItem, clear } from "./localStorage";
import { ACCESS_TOKEN, USER } from "../constants/defaultKeys";
import { useLocation } from "react-router-dom";
import { message, notification } from "antd";
export const token = "3234332D486F6D654D6164652D6C6164647573";

export const isAuthenticated = () => {
  let isTokenAvailable = false;
  const access_token = getItem(ACCESS_TOKEN);
  if (access_token) {
    isTokenAvailable = true;
  }
  return isTokenAvailable;
};

export const hasAcceptedTerms = () => {
  const user = getItem(USER, true);
  return user && user.acceptedTerms === true;
};

export const getTokenIfNotExpired = () => {
  const token = getItem(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      clear();
      return null;
    }
  }
  return token;
};

export const useQuery = () => {
  const search = useLocation().search;
  return new URLSearchParams(search);
};

export const getMessageFromResponse = (response) => {
  const message = response && response.data && response.data.message;

  return message || "Something went wrong, Please try after sometime";
};

export function formatDate(date) {
  const format_date = date.toString().split("");
  let year = "";
  let month = "";

  for (let i = 0; i < 4; i++) {
    year += format_date[i];
  }

  for (let i = 4; i < 6; i++) {
    month += format_date[i];
  }

  return month + "/" + year;
}
export const openNotificationWithIcon = (type, message, MsgWidth = "400px") => {
  notification[type]({
    // message: "Notification Title",
    description: message,
    style: { width: MsgWidth },
  });
};

export async function wait(n) {
  await new Promise(resolve => setTimeout(resolve, n));
}

export async function printReport(data) {
    let reprotHtml = data
    if (data?.result_code === 2) {
      message.warn("Data Unavailable")
      return;
    }
    const printWindow = window.open("", "PrintWindow", "height=1000,width=1000");
    if (reprotHtml?.includes("window.print()")) {
        reprotHtml = reprotHtml.replace("window.print()", "");
    }
    printWindow.document.write(reprotHtml)
    printWindow.document.close();
    printWindow.focus();
    printWindow.addEventListener("afterprint", function(event) {
      printWindow.close();
    });
    await wait(300)
    if (printWindow.document.readyState === "complete") {
      printWindow.print()
    } else {
      await wait(1500)
      if (printWindow.document.readyState === "complete") {
        printWindow.print()
      } else {
        await wait(2000)
        printWindow.print()
      }
    }
}

export const processMetroIdexData = (data, reportName) => {
    let newData = [];
    let arrayLen = data?.indexes?.length;
    if (
      !(
        data?.p1_metro_index?.length === 0 &&
        data?.p2_metro_index?.length === 0
      )
    ) {
      for (let i = 0; i < arrayLen; i++) {
        newData.push({
          xAxis: data?.indexes[i],
          [`${reportName}_(RSFR)`]: data?.p1_metro_index[i],
          [`${reportName}_(Condos)`]: data?.p2_metro_index[i],
        });
      }
    }
    return newData
}


export const processPropertyIndexData = (data) => {
    let newData = [];
    let arrayLen = data?.index_data?.indexes.length;
    if (
      !(
        data?.index_data?.metro_index.length === 0 &&
        data?.index_data?.zip_index.length === 0 &&
        data?.index_data?.property_index.length === 0
      )
    ) {
      for (let i = 0; i < arrayLen; i++) {
        newData.push({
          xAxis: data?.index_data?.indexes[i],
          [`${data?.index_data?.metro_name}`]:
            data?.index_data?.metro_index[i],
          [`${data?.index_data?.zip_name}`]:
            data?.index_data?.zip_index[i],
          [`${data?.index_data?.property_name}`]:
            data?.index_data?.property_index[i],
        });
      }
  }
  return newData
}