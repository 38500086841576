import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isBrowser, isMobileOnly, isTablet } from "react-device-detect";
import { Col, Row, Input, Modal, Menu } from "antd";
import { SearchOutlined, SettingOutlined } from "@ant-design/icons";
import Logo from "../../assets/images/logo.png";
import weiss from "../../assets/images/weiss.jpeg";
import PropertyResults from "./PropertyResults";
import UserAvatar from "./UserAvatar";
import ThemeChange from "../Dashboard/ThemeChange";
import { USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";

function Toolbar() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [themeModal, setThemeModal] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const userDetails = JSON.parse(getItem(USER));
  let isAuthenticated = userDetails?.access_token && userDetails?.user_id ? true : false;

  function renderSearchPropertyModal() {
    setIsModalVisible(true);
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      setIsModalVisible(false);
      setSelectedMenuItem("");
    }
  };
  const themeCancel = () => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      setThemeModal(false);
    }
  };
  
  const themeOk = () => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      setThemeModal(false);
    }
  };

  let logoLink = isAuthenticated ? "/search" : "/";
  let dashboardLink = isAuthenticated ? "/dashboard" : "/";
  let myReportsLink = isAuthenticated ? "/myreports" : "/";
  return (
    <Row justify="space-between" align="middle">
      <Col
        onClick={() => {
          navigate(logoLink);
          setSelectedMenuItem("");
          themeCancel();
          handleCancel();
        }}
      >
        {isMobileOnly ? (
          <img
            src={weiss}
            alt="logo"
            style={{
              width: "48px",
              height: "auto",
              cursor: "pointer",
            }}
          />
        ) : (
          <img
            src={Logo}
            alt="logo"
            style={{
              width: "200px",
              height: "auto",
              cursor: "pointer",
            }}
          />
        )}
      </Col>
      <Col
      >
        <Row align="middle">
          <Col>
            <div
              onClick={() => {
                renderSearchPropertyModal();
                themeCancel();
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                cursor: "pointer",
                border: "1px solid #e4e4e4",
                borderRadius: "60px",
                background: "#f4f5f8",
              }}
            >
              {!isMobileOnly ? (
                <>
                  <Input
                    style={{
                      width: "150px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      padding: 0,
                    }}
                    placeholder="Search For Reports..."
                  />
                  <SearchOutlined
                    style={{
                      fontSize: "20px",
                    }}
                  />
                </>
              ) : (
                <>
                  <SearchOutlined
                    style={{
                      fontSize: "19px"
                    }}
                  />
                </>
              )}
            </div>
          </Col>
          <Col>
            {isMobileOnly || isTablet ? (
              <UserAvatar />
            ) : (
              <Menu
                onClick={(e) => setSelectedMenuItem(e.key)}
                mode="horizontal"
                selectedKeys={[selectedMenuItem]}
                style={{ minWidth: "235px" }}
              >
                {isAuthenticated && (<Menu.Item
                  onClick={() => {
                    handleCancel();
                    themeCancel();
                    navigate(myReportsLink);
                  }}
                  key="my-reports"
                >
                  My Reports
                </Menu.Item>)}
                <Menu.Item
                  onClick={() => {
                    handleCancel();
                    themeCancel();
                    navigate(dashboardLink);
                  }}
                  key="dashboard"
                >
                  {isAuthenticated ? <SettingOutlined style={{ color: "black" }} /> : 'Login'}
                </Menu.Item>
              </Menu>
            )}
          </Col>
        </Row>
      </Col>

      {isModalVisible && (
        <Modal
          title="Search for Properties / Metro / Zipcode."
          width={isBrowser ? "50%" : isMobileOnly ? "100%" : "70%"}
          closable={false}
          footer={false}
          open={true}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <PropertyResults
            HideModal={setIsModalVisible}
            isReportSearch={true}
          />
        </Modal>
      )}
      {themeModal && (
        <Modal
          title="Choose Color"
          closable={true}
          footer={false}
          open={true}
          onOk={themeOk}
          onCancel={themeCancel}
        >
          <ThemeChange />
        </Modal>
      )}
    </Row>
  );
}

export default Toolbar;
