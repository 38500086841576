import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import email from "../../assets/images/email.png";
import address from "../../assets/images/address.png";

const ListingAgentOffice = (props) => {
    const record = props.cell._cell.row.data;
    const value = props?.cell?._cell?.value || "-";
    return (
      <ul style={{ listStyle: "none" }}>
        <li>
          <UserOutlined />
          {value}
        </li>
        <li>
          <img src={email} height="14px" width="14px" alt="email" />{" "}
          {record?.OfficeEmail ? record?.OfficeEmail : "-"}
        </li>
        <li>
          <PhoneOutlined /> {record?.OfficePhone ? record?.OfficePhone : "-"}
        </li>
        <li>
          <img src={address} height="14px" width="14px" alt="address" />{" "}
          {record?.OfficeAddress ? record?.OfficeAddress : "-"}
        </li>
      </ul>
    );
}

export default ListingAgentOffice;