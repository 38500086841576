import { useState } from "react";
import Logo from "../../../assets/images/logo.png";
import { useMutation } from "react-query";
import { Form, Input, Button, Row, Col, Card, Typography, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { resetPassword } from "../../../services/auth.services";
import "./ForgotPassword.css";

const { Title, Paragraph } = Typography;

const ForgotPassword = () => {
  const [sendResetPasswordEmail, setSendResetPasswordEmail] = useState(true);
  const [username, setUsername] = useState("");
  let formValues = null;
  console.log(formValues)
  const onError = (error) => {
    message.error(error.response.data.message);
  };
  const onSuccess = (data) => {
    setSendResetPasswordEmail(true);
  };

  const { mutate, isLoading, isSuccess } = useMutation(resetPassword, {
    onError,
    onSuccess,
  });
  const onFinish = (values) => {
    try {
      formValues = values;
      setUsername(values.username);
      mutate(values);
      console.log("Form values: ", values)
    }
    catch (error) {
      console.log("Error in reset password: ", error);
    }
  };

  return (
    <Row type="flex" justify="center" align="middle" className="login">
      <Col
        lg={{ span: 8, offset: 8 }}
        md={{ span: 12, offset: 6 }}
        sm={{ span: 20, offset: 2 }}
        xs={{ span: 20, offset: 2 }}
        style={{ marginLeft: 0 }}
      >
        <Card
          bordered={false}
          style={{ padding: "20px 20px 10px 20px", borderRadius: "6px" }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={Logo} style={{ width: "250px", height: "auto" }} alt="logo" />

            <Title level={4} type="secondary" style={{ marginTop: "25px" }}>
              Password Reset
            </Title>
          </div>
          {isSuccess && sendResetPasswordEmail ? (
            <>
              <Paragraph>
                We've sent a reset password email to this{" "}
                {username}. Please click the reset
                password link in the email to set your new password.
              </Paragraph>
              <Paragraph>Didn't receive the email yet?</Paragraph>
              <Paragraph>
                Please check your spam folder, or{" "}
                <span
                  className="footer-nav-links"
                  onClick={() => setSendResetPasswordEmail((prev) => !prev)}
                >
                  Try again
                </span>
              </Paragraph>
            </>
          ) : (
            <>
              <Form
                name="login"
                onFinish={onFinish}
                className="forgot-password-form"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                  help="We'll email you the link to reset your password."
                  style={{ marginBottom: "24px" }}
                >
                  <Input
                    autoFocus={true}
                    size="large"
                    type="email"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button"
                    loading={isLoading}
                  >
                    {isLoading ? "Sending you an email..." : "Reset password"}
                  </Button>
                </Form.Item>
              </Form>
              <div style={{ textAlign: "center" }}>
                <Link to="/" className="footer-links">
                  Back to login
                </Link>
              </div>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default ForgotPassword;
