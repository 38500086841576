import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { MapContainer, TileLayer, Polygon, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Skeleton, Button } from "antd";
import { filledIcon, emptyIcon, emptySatelliteIcon } from "./icons";

const ValproMap = React.memo(({ data, curHouseKey, setValData }) => {
  const [polygonCoords, setPolygonCoords] = useState([]);
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);
  const [drawingMode, setDrawingMode] = useState(null);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const mapRef = useRef(null);
  const [center, setCenter] = useState([]);

  const isPointInPolygon = useMemo(() => (point, polygon) => {
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0], yi = polygon[i][1];
      const xj = polygon[j][0], yj = polygon[j][1];
      const intersect =
        ((yi > point[1]) !== (yj > point[1])) &&
        (point[0] < (((xj - xi) * (point[1] - yi)) / (yj - yi) + xi));
      if (intersect) inside = !inside;
    }
    return inside;
  }, []);

  const updateSelectedHouses = useMemo(() => () => {
    const selected = data.filter((house) => {
      const point = [house.lat, house.lng];
      return isPointInPolygon(point, polygonCoords);
    });
    selected?.forEach((house) => { 
      house.contributor = 1;
    });
    setValData((prevData) => prevData.map((house) =>
      selected.find((selHouse) => selHouse.WRRID === house.WRRID)
        ? { ...house, contributor: 1 }
        : house
    ));
  }, [data, polygonCoords, setValData, isPointInPolygon]);

  useEffect(() => {
    if (curHouseKey && curHouseKey?.lat && curHouseKey?.lng) {
      setCenter([curHouseKey?.lat, curHouseKey?.lng]);
    }
  }, [curHouseKey]);

  const handleMouseDown = useCallback((e) => {
    const { lat, lng } = e.latlng;
    setPolygonCoords([[lat, lng]]);
    setIsDrawingPolygon(drawingMode === "polygon");
    mapRef.current?.dragging?.disable();
  }, [drawingMode]);

  const handleMouseMove = useCallback((e) => {
    if (isDrawingPolygon) {
      const { lat, lng } = e.latlng;
      setPolygonCoords((coords) => [...coords, [lat, lng]]);
    }
  }, [isDrawingPolygon]);

  const handleMouseUp = useCallback(() => {
    setIsDrawingPolygon(false);
    mapRef.current?.dragging?.enable();
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.addEventListener("mousedown", handleMouseDown);
      map.addEventListener("mousemove", handleMouseMove);
      map.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (map) {
        map.removeEventListener("mousedown", handleMouseDown);
        map.removeEventListener("mousemove", handleMouseMove);
        map.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  // ignore the warning
  /* eslint-disable */
  useEffect(() => {
    updateSelectedHouses();
  }, [polygonCoords]);
/* eslint-enable */
  const handleSatelliteViewToggle = useCallback(() => {
    setIsSatelliteView((prev) => !prev);
  }, []);

  const handleDrawingModeChange = useCallback((mode) => {
    setDrawingMode(mode);
    if (mode === null) {
      setPolygonCoords([]);
      setValData((prevData) => prevData.map((house) => ({
        ...house,
        contributor: 0
      })));
      mapRef.current?.dragging?.enable();
    }
  }, [setValData]);

  const handleMarkerClick = useCallback((house) => {
    setValData((prevData) => prevData.map((item) =>
      item.WRRID === house.WRRID
        ? { ...item, contributor: item.contributor === 1 ? 0 : 1 }
        : item
    ));
  }, [setValData]);

  const markers = useMemo(() =>
    data.map((house) => (
      <Marker
        key={house.WRRID}
        position={[house.lat, house.lng]}
        icon={
          house.contributor
            ? filledIcon
            : isSatelliteView
            ? emptySatelliteIcon
            : emptyIcon
        }
        eventHandlers={{
          click: () => handleMarkerClick(house),
        }}
      >
        <Popup>
          <div>
            <h3>{house.name}</h3>
            <p>{house.address}</p>
          </div>
        </Popup>
      </Marker>
    )),
    [data, isSatelliteView, handleMarkerClick]
  );

  const mapContainer = useMemo(() => (
    <MapContainer
      center={center}
      zoom={14}
      scrollWheelZoom={true}
      ref={mapRef}
      style={{ height: "500px" }}
    >
      {isSatelliteView ? (
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
      ) : (
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
      )}
      <Polygon positions={polygonCoords} />
      {markers}
    </MapContainer>
  ), [center, isSatelliteView, polygonCoords, markers]);

  if (!center || center?.length === 0) {
    return <Skeleton active />;
  }

  return (
    <div>
      <div>
        <Button
          type="primary"
          size="small"
          onClick={() => handleDrawingModeChange("polygon")}
          style={{ minWidth: "100px" }}
        >
          Draw Polygon
        </Button>
        <Button
          onClick={handleSatelliteViewToggle}
          size="small"
          style={{ minWidth: "100px" }}
        >
          {isSatelliteView ? "Default View" : "Satellite View"}
        </Button>
        <Button
          size="small"
          type="danger"
          onClick={() => handleDrawingModeChange(null)}
          style={{ minWidth: "100px" }}
        >
          Clear
        </Button>
      </div>
      {mapContainer}
    </div>
  );
});

export default ValproMap;