import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Divider,
  Statistic,
  Tooltip,
  Collapse,
  Checkbox,
} from "antd";
import {
  EditOutlined,
  InfoCircleOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import CompFormModal from "./CompFormModal";
import { formatDate } from "../../../helpers/utility";

const { Panel } = Collapse;
const { Paragraph } = Typography;

const infoText =
  "This is the value this comp implies the subject home has. This is calculated by adjusting this comp's prior sale to the present using its Weiss Index and then adjusting for the comp's differences to the subject home";

const ListView = ({ data, setValData, subject_comp }) => {
  const [editList, setEditList] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  let sortedData = data.sort((a, b) => {
    return a.weight > b.weight ? -1 : 1;
  });
  function onChange(e, val) {
    data.filter((item) => item.WRRID === val.WRRID)[0].contributor = e.target
      .checked
      ? 1
      : 0;
    setValData([...data]);
  }

  const openModal = (data) => {
    setEditList(data);
    setFormVisible(true);
  };

  return (
    <Fragment>
      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {sortedData?.map((item, ind) => (
          <Panel
            header={
              <Row style={{ width: "85%" }} gutter={[6, 6]}>
                <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  {item?.address}
                </Col>
                <Col
                  xs={{ span: 12 }}
                  md={{ span: 16 }}
                  lg={{ span: 16 }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {item?.beds && item?.beds !== 0 ? item?.beds : "-"}{" "}
                    bedrooms,
                    {item?.baths && item?.baths !== 0 ? item?.baths : "-"}{" "}
                    bathrooms,
                    {item?.size && item?.size !== 0 ? item?.size : "-"} sq ft,
                    built in {item?.age && item?.age !== 0 ? item?.age : "-"}.
                  </span>
                </Col>
              </Row>
            }
            key={item?.WRRID}
            extra={
              <span onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checked={item?.contributor}
                  onChange={(e) => onChange(e, item)}
                />
              </span>
            }
          >
            <Row>
              <Col span={24} style={{ textAlign: "right", cursor: "pointer" }}>
                <EditOutlined
                  style={{ color: "#97c23c" }}
                  onClick={() => openModal(item)}
                />
              </Col>
              <Col span={12}>
                <Paragraph className="implied-and-last-sale-text">
                  IMPLIED VALUE{" "}
                  <Tooltip title={infoText}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Paragraph>
                <Paragraph className="implied-and-last-sale-value">
                  {item?.implyingValue && item?.implyingValue !== 0
                    ? `$${Math.round(item?.implyingValue)}`
                    : "-"}
                </Paragraph>
              </Col>
              <Col span={12}>
                <Paragraph className="implied-and-last-sale-text">
                  LAST SALE |{" "}
                  {item?.TransferDate && item?.TransferDate !== 0
                    ? formatDate(item?.TransferDate)
                    : "-"}
                </Paragraph>
                <Paragraph className="implied-and-last-sale-value">
                  ${" "}
                  {item?.TransferValue && item?.TransferValue !== 0
                    ? item?.TransferValue
                    : "-"}
                </Paragraph>
              </Col>
            </Row>

            <Divider />
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Statistic
                  title="Relative Weight"
                  value={
                    item?.weight && item?.weight !== 0
                      ? `${Math.floor(item?.weight * 100)}%`
                      : "-"
                  }
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="BEDS"
                  value={item?.beds && item?.beds !== 0 ? item?.beds : "-"}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="BATHS"
                  value={item?.baths && item?.baths !== 0 ? item?.baths : "-"}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="SQFT"
                  value={item?.size && item?.size !== 0 ? item?.size : "-"}
                />
              </Col>
              <Col span={8}>
                <Paragraph className="implied-and-last-sale-text">
                  YR EST
                </Paragraph>
                <span className="implied-and-last-sale-value comp-age">
                  {item?.age && item?.age !== 0 ? item?.age : "-"}
                </span>
              </Col>
              <Col span={8}>
                <Statistic title="DISTANCE" value={item?.distance} />
              </Col>
            </Row>
          </Panel>
        ))}
      </Collapse>
      {formVisible && (
        <CompFormModal
          setIsVisible={setFormVisible}
          isVisible={formVisible}
          editList={editList}
          setValData={setValData}
          data={sortedData}
          subject_comp={subject_comp}
        />
      )}
    </Fragment>
  );
};

export default ListView;
