import { Fragment } from "react";
import { isBrowser, isMobileOnly } from "react-device-detect";
import { Col, Row, PageHeader, Space } from "antd";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import "./Metro.css";
import {
  Summary,
  PageTitle,
  Segmentation,
  IndexTools,
  BubbleWatch,
  Listings,
  DynamicMap,
  // BestWorstSegment
} from "../../../Widgets";
import { useSearchHistoryUpdate } from "../../../hooks/useSearchHistoryUpdate";
import "../Report.css";

const Metro = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("tab");

  const filters = { ...params, queryParams: searchParams };
  useSearchHistoryUpdate(filters, { enabled: params.reportName !== null });

  return (
    <Row gutter={[16, 16]}>
      <Col
        className="page-title-row"
        span={24}
        style={{ position: "sticky", top: 60, zIndex: 1000 }}
      >
        <div className="page-title">
          <PageTitle />
        </div>
      </Col>
      {searchTerm !== "listings" && (
        <>
          <Col
            lg={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 12 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Summary />
              {/* <BestWorstSegment segment="best" />
                <BestWorstSegment segment="worst" /> */}
            </Space>
          </Col>

          {isBrowser ? (
            <Col span={16}>
              <IndexTools />
            </Col>
          ) : (
            <>
              {/* <Col
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={isMobileOnly && { span: 22, offset: 1 }}
                >
                  <BestWorstSegment segment="best" />
                </Col>
                <Col
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={isMobileOnly && { span: 22, offset: 1 }}
                >
                  <BestWorstSegment segment="worst" />
                </Col> */}

              <Col lg={24} xs={isMobileOnly && { span: 22, offset: 1 }}>
                <IndexTools />
              </Col>
            </>
          )}

          <Col
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <Segmentation />
          </Col>
          {false && (
            <Col
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={isMobileOnly && { span: 22, offset: 1 }}
            >
              <BubbleWatch />
            </Col>
          )}
          <Col
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={isMobileOnly && { span: 22, offset: 1 }}
          >
            <DynamicMap />
          </Col>
        </>
      )}
      {searchTerm === "listings" && (
        <>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className="mb-2"
          >
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => navigate(pathname)}
              title="Back"
            />
            <Listings />
          </Col>
        </>
      )}
    </Row>
  );
};

export default Metro;
