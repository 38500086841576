import { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";
import {
  Divider,
  Modal,
  Form,
  InputNumber,
  DatePicker,
  Button,
  Typography,
} from "antd";
import { openNotificationWithIcon } from "../../../helpers/utility";
import PropertyResults from "../../../components/Toolbar/PropertyResults"
import axiosInstance from "../../../config/axios/axios.config";

const { Paragraph } = Typography;
const CompModal = ({
  WRRID,
  compModalVisible,
  setCompModalVisible,
  data,
  setValData,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchedResult, setSearchedResult] = useState(null);

  const handleCancel = () => {
    setCompModalVisible(false);
  };

  const onFinish = async (values) => {
    let subjectId = WRRID;
    let address = searchedResult
    let saleAmount = values?.sale_price;
    let saleDate = moment(values?.sale_date).format("YYYYMMDD")
    let zipcode = "00000"
    if (!address) {
      openNotificationWithIcon("error", "Please select a property!");
      return;
    }
    try {
      setLoading(true);
      let url = `widgets/${address}/address/${zipcode}/usercomp/?full_address=${address}&sale_amount=${saleAmount}&sale_date=${saleDate}&subject_id=${subjectId}`
      let updatedHouseFromApi = await axiosInstance.get(url)
      let customData = updatedHouseFromApi?.data || {}
      if (customData) {
        customData.updatedTime = new Date();
        setValData(
          [
            ...data.filter(item => item.WRRID !== customData?.WRRID),
            { ...customData, contributor: 1 }
          ]
        )
      }
      setLoading(false);
      setCompModalVisible(false);
    }
    catch (error) {
      console.log(error);
      setLoading(false);
      setCompModalVisible(false);
    }
  };

  return (
    <Modal
      title="What do you want to pick?"
      footer={false}
      width={800}
      onCancel={handleCancel}
      open={compModalVisible}
    >
      <PropertyResults
        setSearchedResult={setSearchedResult}
        isReportSearch={false}
        showOnlyProperty={true}
        placeholderText="Search for property...."
      />
      {isMobileOnly ? (
        <Paragraph>
          Before we add the comp to your valuation, you must add a last sale
          date and price.
        </Paragraph>
      ) : (
        <Divider orientation="center">
          Before we add the comp to your valuation, you must add a last sale
          date and price.
        </Divider>
      )}

      <Form name="Add Comp" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Date Of Sale"
          name="sale_date"
          rules={[
            {
              required: true,
              message: "Please choose date!",
            },
          ]}
        >
          <DatePicker
            style={{ minWidth: "150px" }}
            disabledDate={(current) =>
              current.isAfter(moment().subtract(1, "day"))
            }
          />
        </Form.Item>
        <Form.Item
          label="Sale Price"
          name="sale_price"
          rules={[
            {
              required: true,
              message: "Please input your sale price!",
            },
          ]}
        >
          <InputNumber
            style={{ minWidth: "150px" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? "Adding Comp..." : " Add Comp"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CompModal;
