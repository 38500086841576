import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import { isMobileOnly } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Divider, Row, Skeleton, message } from "antd";
import Comp from "./comp/Comp";
import Map from "./maps/GoogleMap";
import ValproMap from "./maps/ValproMap";
import "./ValPro.css";

import { ErrorBoundary } from "../shared";
import { useValpro } from "./useValpro";
import ValproHeader from "./ValproHeader";
import CustomCard from "../../blocks/Card/Card";
import { valProInfo } from "../../constants/defaultKeys";
import { showInfo } from "../shared";
import axiosInstance from "../../config/axios/axios.config";

const ValPro = forwardRef((props, ref) => {
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useState(
    new URLSearchParams(
      location.search ||
        (location?.pathname?.includes("report")
          ? { isWebsite: true, ...location.search }
          : {})
    )
  );
  const [startDownloading, setStartDownloading] = useState(false);
  const [valData, setValData] = useState([]);
  const [customCompVal, setCustomCompVal] = useState(null);
  const [compFullWidth, setCompFullWidth] = useState(false);
  const [selectedComp, setSelectedComp] = useState(0);
  const [weightedAverage, setWeightedAverage] = useState(0);
  const [adjustedValues, setAdjustedValues] = useState([{}]);
  const [subjectComp, setSubjectComp] = useState(null);
  const [properUpdateLoading, setPropertyUpdateLoading] = useState(false);
  const [adjustedValue, setAdjustedValue] = useState(0);
  const elementRef = useRef(null);
  const selectRef = useRef(null);
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);

  const filters = useMemo(
    () => ({ ...params, queryParams: searchParams }),
    [params, searchParams]
  );
  const { data, isLoading, isError } = useValpro(filters, {
    enabled: params.reportName !== null,
  });

  const scrollToDiv = () => {
    selectRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleValPalData = () => {
    if (startDownloading) {
      message.warning("Please wait while the report is being generated");
      return;
    }
    let valValue =
      (parseInt(weightedAverage) || 0) + (parseInt(adjustedValue) || 0);

    if (
      !valValue ||
      valValue === 0 ||
      isNaN(valValue || selectedComp === 0 || !subjectComp)
    ) {
      message.warning("Please select one comparable to proceed");
      scrollToDiv();
      return;
    }
    try {
      let downloadUrl = `/widgets/downloadreportpdf/${encodeURIComponent(
        params?.reportName
      )}/valpal/${encodeURIComponent(params?.code)}/`;
      setStartDownloading(true);
      message.loading("ValPal report is being generated", 12);
      axiosInstance
        .post(downloadUrl, {
          subject_house: subjectComp,
          valpal_value:
            (parseInt(weightedAverage) || 0) + (parseInt(adjustedValue) || 0),
          comporables: valData?.filter((item) => item.contributor === 1),
          report_address: params?.reportName,
          code: params?.code,
        })
        .then((response) => {
          console.log("Response", response);
          const url = response?.data?.url;
          const a = document.createElement("a");
          a.href = url;
          a.download = `${response?.data?.report_name}`;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setStartDownloading(false);
        })
        .catch((error) => {
          console.log("Error", error);
          setStartDownloading(false);
        });
    } catch (e) {
      console.log("Error", e);
      setStartDownloading(false);
    }
  };

  const processValData = useCallback((data) => {
    if (data) {
      let updatedTime = new Date();
      let val_data = data?.val_data?.map((item) => {
        item.updatedTime = updatedTime;
        if (!item.age || item.age === 0) item.age = "-";
        if (!item.size || item.size === 0) item.size = "-";
        if (!item.beds || item.beds === 0) item.beds = "-";
        if (!item.baths || item.baths === 0) item.baths = "-";
        if (!item.Lot || item.Lot === 0) item.Lot = "-";
        return item;
      });
      let targetValue = data?.summary?.ValPro || 0;
      val_data?.sort((a, b) => {
        const diffA = Math.abs(a.implyingValue - targetValue);
        const diffB = Math.abs(b.implyingValue - targetValue);
        return diffA - diffB;
      });
      let selectedItems = val_data?.slice(0, 3);
      selectedItems?.forEach((item) => {
        item.contributor = 1;
        item.updatedTime = new Date();
      });

      setValData(val_data);
      let sumWeightedValues = 0;
      let totalWeight = 0;

      val_data?.forEach((item) => {
        if (item.contributor === 1) {
          let weightedValue = item.implyingValue * item.weight;
          sumWeightedValues += weightedValue;
          totalWeight += item.weight;
        }
      });
      let average = sumWeightedValues / totalWeight || 0;
      let valProValue = data?.summary?.ValPro;
      let valueToAdjust = [];
      let foxyDifference = 0;
      if (average && average !== 0) {
        let difference = valProValue - average;
        valueToAdjust.push({
          amount: difference?.toFixed(0),
          description: "AI Adjustment",
          type: difference >= 0 ? "positive" : "negative",
        });
        if (data?.has_avv) {
          let foxyValpro =
            parseInt(
              data?.foxy_valpro && data?.foxy_valpro !== "-"
                ? data?.foxy_valpro
                : 0
            ) || 0;
          if (data?.foxy_valpro && data?.foxy_valpro !== "-") {
            foxyDifference = foxyValpro - data?.summary?.ValPro;
            valueToAdjust.push({
              amount: foxyDifference?.toFixed(0),
              description: "AVV Adjustment",
              type: foxyDifference >= 0 ? "positive" : "negative",
            });
          }
        }
        let totalDifference = difference + (data?.has_avv ? foxyDifference : 0);
        setAdjustedValues(valueToAdjust);
        setAdjustedValue(totalDifference);
        setSelectedComp(selectedItems?.length || 0);
      }
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleValPalData: handleValPalData,
  }));

  useEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        const newHeight = elementRef.current.clientHeight + 152;
        window.parent.postMessage({ valproHeight: newHeight }, "*");
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    processValData(data);
  }, [data, processValData]);

  useEffect(() => {
    if (valData) {
      let count = 0;
      let sumWeightedValues = 0;
      let totalWeight = 0;

      valData?.forEach((item) => {
        if (item.contributor === 1) {
          count++;
          let weightedValue = item.implyingValue * item.weight;
          sumWeightedValues += weightedValue;
          totalWeight += item.weight;
        }
      });
      if (customCompVal) {
        count++;
        sumWeightedValues += customCompVal.sale_price;
        totalWeight += customCompVal.weight || 1;
      }
      setSelectedComp(count);
      let average = sumWeightedValues / totalWeight || 0;
      setWeightedAverage(average?.toFixed() || 0);
      if (count === 0) {
        setAdjustedValues((prevAdjustedValues) =>
          prevAdjustedValues.filter(
            (item) =>
              item?.description !== "AI Adjustment" &&
              item?.description !== "AVV Adjustment"
          )
        );
      }
    }
  }, [valData, customCompVal]);

  useEffect(() => {
    setSubjectComp(data?.subject_comp);
  }, [data]);

  useEffect(() => {
    let adjusted = adjustedValues.reduce((total, item) => {
      return (
        total +
        (item?.type === "positive"
          ? parseInt(item?.amount) || 0
          : -parseInt(item?.amount) || 0)
      );
    }, 0);
    setAdjustedValue(adjusted);
  }, [adjustedValues]);

  if (isError) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  if (!isLoading && !isError && (!data || data === null)) {
    window.parent.postMessage("NO DATA", "*");
    return null;
  }

  return (
    <ErrorBoundary>
      <CustomCard
        ref={elementRef}
        title={
          <div className="valpal-header">
            <div>
              <span>ValPal</span>
              {!showInfo(searchParams?.get("showInfo")) && valProInfo()}
            </div>
            <div className="valpro-header-edit">
              <span className="mobile-font">{params?.reportName}</span>
            </div>
          </div>
        }
        className="title-2"
      >
        {showInfo(searchParams?.get("showInfo")) && (
          <Row gutter={isMobileOnly ? [12, 12] : [36, 36]}>
            <Col span={24}>
              <p>
                ValPal will determine the value of the subject home based on the
                comps you select and the condition adjustments using the robust
                estimation method.
              </p>
            </Col>
          </Row>
        )}
        <Row ref={selectRef}>
          <ValproHeader
            subject_comp={subjectComp}
            selectedComp={selectedComp}
            weightedAverage={weightedAverage}
            properUpdateLoading={properUpdateLoading}
            adjustedValue={adjustedValue}
            adjustedValues={adjustedValues}
          />
          {isMobileOnly && <Divider />}
          {!compFullWidth && (
            <Col
              xl={{ span: 14 }}
              lg={{ span: 16 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              style={{ height: "540px", width: "100%" }}
            >
              {valData && user?.is_staff ? (
                <ValproMap
                  data={valData}
                  curHouseKey={subjectComp && subjectComp}
                  setValData={setValData}
                />
              ) : (
                <Map
                  data={valData}
                  curHouseKey={subjectComp && subjectComp}
                  setValData={setValData}
                />
              )}
            </Col>
          )}
          <Col
            xl={{ span: compFullWidth ? 24 : 10 }}
            lg={{ span: compFullWidth ? 24 : 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={isMobileOnly && 24}
          >
            <Comp
              WRRID={subjectComp?.WRRID}
              data={valData}
              setValData={setValData}
              compFullWidth={compFullWidth}
              customCompVal={customCompVal}
              setCompFullWidth={setCompFullWidth}
              setCustomCompVal={setCustomCompVal}
              weightedAverage={weightedAverage}
              subject_comp={subjectComp}
              setSubjectComp={setSubjectComp}
              setPropertyUpdateLoading={setPropertyUpdateLoading}
              properUpdateLoading={properUpdateLoading}
              setAdjustedValues={setAdjustedValues}
              adjustedValues={adjustedValues}
              adjustedValue={adjustedValue}
            />
          </Col>
        </Row>
      </CustomCard>
    </ErrorBoundary>
  );
});

export default ValPro;
